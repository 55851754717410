@import "~theme/btn/btn";
@import "~theme/clearfix";
@import "~theme/colors";
@import "~theme/type";
@import "~theme/img-url";

@mixin screen {
  @media only screen {
    @content; } }


.TeamReport {
  @include screen {
    background: $color-grey-heather;
    padding-bottom: .5in;
    padding-top: .5in;
    min-width: 11in; }

  &__pdfLink {
    display: none;

    @include screen {
      @include btn(primary, solid, $color-pink);
      display: block;
      margin-bottom: .5in;
      margin-left: auto;
      margin-right: auto;
      width: 50rem; } }

  &__h {
    @include font(24pt, 1);
    color: $color-pink;
    font-family: $font-primary-new;
    font-weight: $font-w-bold;

    &_sm {
      @include font(14pt, 16pt); }
    &_lg {
      @include font(55pt, 1); }
    &_huge {
      @include font(63pt, 1); } }

  &__spacer {
    height: 16pt;
    display: block; }

  &__p,
  &__li {
    @include font(14pt, 16pt);
    color: $color-black;
    font-family: $font-secondary-new;
    font-weight: $font-w-light;

    strong {
      font-weight: $font-w-medium; } }

  &__p_lg {
    @include font(18pt, 21pt); }

  &__p_sm,
  &__ul_sm &__li {
    @include font(12pt, 14pt); }

  &__p_pink {
    color: $color-pink; }

  &__li {
    padding-left: .5em; }

  &__ul {
    list-style: outside;
    padding-left: 2em; }

  &__p + &__p,
  &__p + &__ul,
  &__ul + &__p,
  &__ul + &__ul,
  &__li + &__li,
  &__spacer + &__p,
  &__spacer + &__ul {
    margin-top: 16pt; }

  &__p + &__p_sm,
  &__p + &__ul_sm,
  &__ul + &__p_sm,
  &__ul + &__ul_sm,
  &__ul_sm &__li + &__li,
  &__spacer + &__p_sm,
  &__spacer + &__ul_sm {
    margin-top: 14pt; }

  &__logo {
    width: 116.25pt; }

  &__quote {
    &Text,
    &Author {
      @include font(21pt, 28pt);
      color: $color-pink;
      font-family: $font-secondary-new;
      font-weight: $font-w-light; }

    &Text {
      &:before {
        content: '\201c'; }
      &:after {
        content: '\201d'; } }

    &Author {
      margin-top: 10pt;

      &:before {
        content: '--'; } } }

  &__content {
    @include screen {
      & + & {
        margin-top: .5in; } } }

  &__cover {
    &Bg {
      background: no-repeat center / contain url($url-cover);
      height: 74%;
      opacity: .8;
      width: 100%;

      &Team {
        background: no-repeat 50% 80% / 105% url($url-report-cover);
        height: 100%;
        opacity: .4;
        width: 100%; } }

    &TextBlock {
      position: absolute;
      top: 6.6in;

      &Team {
        position: absolute;
        top: 2.5in; }

      h1 + p {
        margin-top: 12pt; } }

    &Logo {
      bottom: 0;
      position: absolute; }

    &Image {
      text-align: center;
      height: 170pt;
      overflow: hidden; } }

  &__dominant {
    &_who {
      width: 365pt;
      margin-top: -70pt; }

    &_why {
      width: 390pt;
      margin-top: -50pt; }

    &_how {
      width: 500pt;
      margin-top: 85pt; } }

  &__intro {
    &TextBlock {
      margin-top: 42pt; } }

  &__summary {
    &TextBlock {
      align-items: center;
      display: flex;
      height: 100%; }

    &Text {
      @include font(18pt, 28pt);
      font-family: $font-secondary-new;
      font-weight: $font-w-light;
      text-align: justify; }

    &Footer {
      bottom: 0;
      margin-left: 35pt;
      position: absolute;

      * {
        margin-top: 0pt; }

      &Logo {
        width: 73.25pt; }

      &Heading {
        font-size: 20pt;

        &Team {
          font-size: 12pt;
          margin-top: 10pt; } }

      &Details {
        font-family: $font-primary-new;
        font-size: 14pt;
        font-weight: $font-w-regular; } } }

  &__memberList {
    &Dimension {
      @include font(21pt, 1);
      font-family: $font-primary-new;
      font-weight: $font-w-bold;
      padding-bottom: 16pt;
      text-align: left;
      width: 22%;

      &_who {
        color: $color-driver-who; }
      &_why {
        color: $color-driver-why; }
      &_how {
        color: $color-driver-how; } }

    &Name,
    &Driver {
      @include font(10pt, 18pt);
      font-family: $font-secondary-new; }

    &Name {
      color: $color-black;
      font-weight: $font-w-medium;

      &.leader::after {
        content: ' (L)';
        font-weight: $font-w-regular;
        opacity: .5; } }

    &Driver {
      font-weight: $font-w-regular;

      &.dominant {
        &:after {
          content: '*'; } }

      &_who.dominant {
        color: $color-driver-who; }
      &_why.dominant {
        color: $color-driver-why; }
      &_how.dominant {
        color: $color-driver-how; } } }

  &__glance {
    &Intro {
      margin-top: 36pt;
      width: 29pc; }

    &Content {
      display: flex;
      justify-content: space-between;
      margin-top: 36pt; }

    &MemberList {
      width: 29pc; }

    &Margin {
      border-left: 1pt solid;
      color: $color-pink;
      display: flex;
      flex-direction: column;
      font-family: $font-primary-new;
      justify-content: flex-end;
      padding-left: 23pt;
      width: 10pc;

      &Heading {
        @include font(14pt, 14pt);
        font-weight: $font-w-bold; }

      &P {
        @include font(10pt, 12pt);
        font-weight: $font-w-semi-bold;
        margin-top: 18pt; } } }

  &__glanceContinued {
    &MemberList {
      width: 30pc; } }

  &__dynamicsIntro {
    &TextBlock {
      float: left;
      margin-top: 36pt;
      width: 17pc; }

    &Margin {
      border-left: 1pt solid $color-pink;
      float: right;
      margin-top: 36pt;
      padding-left: 23pt;
      width: 20pc;

      &Heading {
        @include font(24pt, 30pt);
        border-bottom: 2pt solid $color-pink;
        border-top: 2pt solid $color-pink;
        color: $color-pink;
        font-family: $font-primary-new;
        font-weight: $font-w-semi-bold;
        padding-bottom: 2pt; }

      &List {
        counter-reset: dynamics;
        margin-top: 16pt;

        dt {
          @include font(14pt, 28pt);
          color: $color-pink;
          font-family: $font-primary-new;
          font-weight: $font-w-medium;

          &:before {
            counter-increment: dynamics;
            content: counter(dynamics) ". "; } }

        dd + dt {
          margin-top: 16pt; }

        dd {
          @include font(12pt, 14pt);
          color: $color-black;
          font-family: $font-secondary-new;
          font-weight: $font-w-light; } } } }

  &__teamDynamics {
    &Intro {
      margin-top: 24pt; }

    &Dynamics {
      counter-reset: team-dynamics;
      margin-top: 36pt; }

    &Dynamic {
      & + & {
        margin-top: 28pt; } }

    &Number {
      display: inline-block;
      padding-right: 20pt;

      &:before {
        @include font(32pt, 45pt);
        color: $color-white;
        content: counter(team-dynamics);
        counter-increment: team-dynamics;
        display: inline-block;
        font-family: $font-primary-new;
        font-weight: $font-w-semi-bold;
        position: absolute;
        text-align: center;
        width: 42pt; }

      .fa {
        color: $color-pink;
        font-size: 48pt; } }

    &Container {
      border-left: 1pt solid $color-pink;
      display: inline-block;
      padding-left: 21pt;
      vertical-align: top;
      width: 82%; }

    &Title {
      @include font(14pt, 16pt);
      color: $color-pink;
      font-family: $font-primary-new;
      font-weight: $font-w-medium; }

    &Status {
      @include font(12pt, 14pt);
      font-weight: $font-w-regular;
      margin-left: 12pt;

      &.loweffort {
        color: $color-green-bright; }

      &.moderateeffort {
        color: $color-yellow-calm-dark; }

      &.higheffort {
        color: $color-red; } }

    &Description {
      @include font(12pt, 14pt);
      color: $color-black;
      font-family: $font-secondary-new;
      font-weight: $font-w-light;
      margin-top: 6pt; }

    &Notes {
      @include font(12pt, 14pt);
      font-family: $font-secondary-new;
      font-weight: $font-w-light;
      margin-top: 6pt;
      padding-left: 84pt; } }

  &__teamDrivers {
    &Intro {
      margin-top: 36pt; }

    &Heading {
      margin-top: 36pt;
      @include font(14pt, 16pt);
      color: $color-pink;
      font-family: $font-primary-new;
      font-weight: $font-w-medium; }

    &Drivers {
      display: flex;
      justify-content: space-around;
      margin-top: 24pt; }

    &Driver {
      width: 33%;

      &Title {
        @include font(21pt, 24pt);
        font-family: $font-primary-new;
        font-weight: $font-w-semi-bold;
        text-align: center;
        margin-bottom: 14pt; }

      &_who &Title {
        color: $color-driver-who; }
      &_why &Title {
        color: $color-driver-why; }
      &_how &Title {
        color: $color-driver-how; }

      &Icon {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100pt; } }

    &Description {
      margin-top: 28pt; } }

  &__drivingPurpose {
    &IntroBlock {
      float: left;
      margin-top: 36pt;
      width: 17pc; }

    &IntroQuote {
      // to avoid wrapping
      margin-right: -2pt; }

    &Description {
      margin-top: 20pt; }

    &DriversBlock {
      border-left: 1pt solid $color-pink;
      float: right;
      margin-top: 36pt;
      padding-left: 23pt;
      width: 20pc;
      height: calc(100% - 146pt); }

    &Driver {
      &Heading {
        @include font(14pt, 28pt);
        font-family: $font-primary-new;
        font-weight: $font-w-medium;

        &_who {
          color: $color-driver-who; }
        &_why {
          color: $color-driver-why; }
        &_how {
          color: $color-driver-how; } }

      &Description + &Heading {
        margin-top: 12pt; } } }

  &__ourCulture {
    &MainBlock {
      float: left;
      margin-top: 24pt;
      width: 27pc; }

    &Donut {
      margin-top: 36pt;

      // .rd3-piechart-arc
      //   .rd3-piechart-label
      //     font-family: $font-primary-new
      //     font-size: 18pt
      //     font-weight: $font-w-medium
      //     shape-rendering: crispEdges

      //   .rd3-piechart-sublabel
      //     font-family: $font-primary-new
      //     font-size: 12pt
      //     font-style: italic
      //     font-weight: $font-w-medium
      //     shape-rendering: crispEdges

      path,
      text {
        &[fill="Impact"] {
          fill: $color-driver-who; }
        &[fill="Values"] {
          fill: $color-driver-why; }
        &[fill="Craft"] {
          fill: $color-driver-how; } } }

    &Margin {
      border-left: 1pt solid;
      color: $color-pink;
      display: flex;
      flex-direction: column;
      float: right;
      font-family: $font-primary-new;
      margin-top: 24pt;
      padding-left: 23pt;
      width: 11pc;

      &Heading {
        @include font(14pt, 14pt);
        font-weight: $font-w-bold; }

      &Aside {
        @include font(10pt, 12pt);
        font-weight: $font-w-semi-bold;
        margin-top: 18pt; } } }

  &__teamCultureProfileElement {
    * + & {
      margin-top: 36pt; }

    &Heading {
      align-items: center;
      display: flex;

      // .rd3-chart
      //   display: block

      path {
        &[fill="impact"] {
          fill: $color-driver-who; }
        &[fill="values"] {
          fill: $color-driver-why; }
        &[fill="craft"] {
          fill: $color-driver-how; }
        &[fill="unused"] {
          fill: $color-grey-heather; } } }

    &Name {
      @include font(24pt, 1);
      font-family: $font-primary-new;
      font-weight: $font-w-medium;
      margin-left: 12pt;

      &_impact {
        color: $color-driver-who; }
      &_values {
        color: $color-driver-why; }
      &_craft {
        color: $color-driver-how; } }

    &Blocks {
      display: flex;
      justify-content: space-between;
      margin-top: 18pt; }

    &Block {
      width: 17pc;

      & + & {
        border-left: 1pt solid $color-pink;
        padding-left: 23pt;
        width: 20pc; }

      &Heading {
        font-style: italic;
        margin-bottom: 18pt; }

      &List {}
      &Item {} } }

  &__teamDriver {
    * + & {
      margin-top: 24pt; }

    &Header {
      margin-top: 18pt;
      display: flex;

      &Left {}

      &Right {
        border-left: 1pt solid;
        flex-grow: 1;
        margin-left: 21pt;
        padding-left: 20pt; }

      &_who &Right {
        border-color: $color-driver-who; }
      &_why &Right {
        border-color: $color-driver-why; }
      &_how &Right {
        border-color: $color-driver-how; }

      &Icon {
        width: 48pt; }

      &Heading {
        font-family: $font-primary-new;
        font-weight: $font-w-medium;

        &Dimension {
          @include font(21pt, 21pt);
          display: block; }

        &Driver {
          @include font(14pt, 16pt);
          display: block;
          font-style: italic; } }

      &_who &Heading {
        color: $color-driver-who; }
      &_why &Heading {
        color: $color-driver-why; }
      &_how &Heading {
        color: $color-driver-how; }

      &Description {
        margin-top: 12pt; } }

    &Section {
      display: flex;
      justify-content: space-between;
      margin-top: 36pt;

      &Left {
        width: 17pc; }

      &Right {
        border-left: 1pt solid;
        padding-left: 23pt;
        width: 20pc; }

      &Heading {
        @include font(14pt, 14pt);
        font-family: $font-primary-new;
        font-weight: $font-w-semi-bold;
        margin-bottom: 14pt; } }

    &_who &SectionHeading {
      color: $color-driver-who; }
    &_why &SectionHeading {
      color: $color-driver-why; }
    &_how &SectionHeading {
      color: $color-driver-how; }

    &_who &SectionRight {
      border-color: $color-driver-who; }
    &_why &SectionRight {
      border-color: $color-driver-why; }
    &_how &SectionRight {
      border-color: $color-driver-how; } }

  &__meAndMyTeam {
    &Overview {
      margin-top: 36pt; }

    &DriversContainer {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      margin-top: 48pt;
      width: 38pc; }

    &Drivers {
      width: 50%;

      &Header {
        text-align: center; } }

    &DriverContainer {
      border-left: 1pt solid;

      &_who {
        border-color: $color-driver-who; }
      &_why {
        border-color: $color-driver-why; }
      &_how {
        border-color: $color-driver-how; } }

    &DriverContainer &Driver {
      margin-left: auto; }

    &Driver {
      @include clearfix;
      margin-top: 28pt;
      width: 184pt;

      &_who {
        color: $color-driver-who; }
      &_why {
        color: $color-driver-why; }
      &_how {
        color: $color-driver-how; }

      &Icon {
        float: left;
        margin-right: 15pt;
        width: 48pt; }

      &Name {
        @include font(21pt, 24pt);
        font-family: $font-primary-new;
        font-weight: $font-w-medium;
        padding-top: 3pt; } } }

  &__meAndTeamDriver {
    &Header {
      display: flex;
      margin-top: 48pt;

      &DriverContainer {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 50%;

        & + & {
          border-left: 1pt solid; } } }

    &_who &HeaderDriverContainer {
      border-color: $color-driver-who; }
    &_why &HeaderDriverContainer {
      border-color: $color-driver-why; }
    &_how &HeaderDriverContainer {
      border-color: $color-driver-how; }

    &Heading {
      @include font(14pt, 16pt);
      font-family: $font-primary-new;
      font-weight: $font-w-medium; }

    &Driver {
      @include font(24pt, 36pt);
      font-family: $font-primary-new;
      font-weight: $font-w-medium; }

    &_who &Heading,
    &_who &Driver {
      color: $color-driver-who; }
    &_why &Heading,
    &_why &Driver {
      color: $color-driver-why; }
    &_how &Heading,
    &_how &Driver {
      color: $color-driver-how; }

    &Icon {
      margin-top: 9pt;
      width: 10pc; }

    &Section {
      margin-top: 48pt; }

    &Content {
      margin-top: 14pt; } }

  &__legal {
    &Block {
      @include font(10pt, 12pt);
      bottom: 0;
      color: $color-pink;
      font-family: $font-primary-new;
      left: 0;
      position: absolute;
      width: 15pc; }

    &Heading {
      font-weight: $font-w-extra-bold;
      text-transform: uppercase; }

    &P {
      font-weight: $font-w-semi-bold;
      margin-top: 8pt; } }

  &__error {
    align-items: center;
    display: flex;
    flex-flow: column;
    font-family: $font-primary-new;
    justify-content: center;
    min-height: 100vh;
    padding-bottom: 30vh;

    h1 {
      @include font($font-s-2, $line-height-2); }
    p {
      @include font($font-s-1, $line-height-1); } }

  &__firstPage {
    padding: .75in .45in; }

  &__donut {
    &LabelContainer text {
      font-family: $font-primary-new;
      font-weight: $font-w-medium;

      &[fill="Impact"] {
        fill: $color-driver-who; }
      &[fill="Values"] {
        fill: $color-driver-why; }
      &[fill="Craft"] {
        fill: $color-driver-how; } }

    &Sublabel {
      font-style: italic;
      font-size: 12pt; }

    &Label {
      font-size: 18pt; } } }

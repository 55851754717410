@import "~theme/responsive/responsive";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";


$btn__border: rem-size(1px);
$btn__borderRadius: rem-size(2px);
$btn__height_primary: 8rem;
$btn__height_secondary: 6rem;
$btn__height_compact: 6rem;
$btn__fontSize_primary: $font-s-xs-sm;
$btn__fontSize_secondary: $font-s-xs;
$btn__padding_primary: 8rem;
$btn__padding_secondary: 6rem;
$btn__padding_compact: 4rem;
$btn__fontSize_tertiary: 2.5rem;
$btn__weight_bold: $font-w-medium;

@mixin inline-block {
  display: inline-block; }

// $size: primary | secondary | compact
// $style: solid | white | outlined
// $color: any sass color
@mixin btn($size, $style, $color) {
  @include btn__base;

  @if $size == 'primary' {
    @include btn__size_primary; }
  @else if $size == 'secondary' {
    @include btn__size_secondary; }
  @else if $size == 'compact' {
    @include btn__size_compact; }

  @if $style == 'solid' {
    @include btn__style_solid($color); }
  @else if $style == 'white' {
    @include btn__style_white($color); }
  @else if $style == 'outlined' {
    @include btn__style_outlined($color); } }

@mixin btn__base {
  border-radius: $btn__borderRadius;
  border: $btn__border solid transparent;
  cursor: pointer;
  display: inline-block;
  font-family: $font-primary-new;
  font-weight: $font-w-regular;
  letter-spacing: .1rem;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  transition: opacity .2s;

  &:disabled,
  &.disabled {
    @include btn_disabled; }

  &.noclick {
    @include btn_noclick; } }

@mixin btn-secondary-hover {
  border-color: $color-primary;
  color: $color-primary;
  background: $color-primary-50; }

@mixin btn-secondary {
  @include font(rem-size(16px), rem-size(24px));
  border-radius: 4px;
  padding: 8px 20px;
  color: $color-grey-90;
  font-weight: $font-w-semi-bold;
  border: 1px solid $color-grey-60;
  background: $color-white;
  transition: border-color .2s, outline .2s, color .2s, background .2s;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  i {
    font-size: 1.1em;
    line-height: inherit; }
  i:first-child {
    margin-right: 5px; }
  i:last-child {
    margin-left: 5px; }

  &:hover {
    @include btn-secondary-hover; }

  &:focus:not(:focus-visible) {
    border-color: $color-border-new;
    color: $color-text-grey;
    background: $color-white;
    box-shadow: none; }
  &:focus,
  &.focus-visible:focus:not(:focus-visible) {
    @include btn-secondary-hover;
    box-shadow: 0 0 0 2px $color-focus; }

  &:active,
  &.active {
    @include btn-secondary-hover;
    box-shadow: 0 4px 6px rgba($color-black, .1); }

  &:disabled,
  &.disabled {
    border-color: $color-border-new;
    color: $color-border-new;
    &.blue {
      border-color: $color-border-new;
      color: $color-border-new; } }

  &.blue {
    border-color: $color-primary;
    color: $color-primary;
    &:focus:not(:focus-visible) {
      box-shadow: none; }
    &:focus,
    &.focus-visible:focus:not(:focus-visible) {
      @include btn-secondary-hover;
      box-shadow: 0 0 0 2px $color-focus; } }

  &.small {
    @include font(12px, 14px);
    padding: 6px 12px; }

  &.btn-inline-block {
    @include inline-block; } }

@mixin btn-small {
  @include font(12px, 14px);
  min-height: 24px;
  border-radius: 24px;
  padding: 4px 12px 4px 12px;
  border: none;
  font-weight: $font-w-semi-bold;
  background: $color-grey-100;
  color: $color-grey-90;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  &.transparent {
    background: none; }

  &.pink {
    background: $color-secondary-50;
    color: $color-secondary-500;
    border: 1px solid $color-secondary-500; }

  &:hover {
    color: $color-primary; }

  &:focus:not(:focus-visible) {
    box-shadow: none; }
  &:focus,
  &.focus-visible:focus:not(:focus-visible) {
    color: $color-primary;
    box-shadow: 0 0 0 2px $color-focus; } }

@mixin btn-tertiary-new {
  @include font(rem-size(16px), rem-size(24px));
  border-radius: 4px;
  padding: 9px 21px;
  color: $color-text-grey;
  font-weight: $font-w-semi-bold;
  border: none;
  background: none;
  transition: border-color .2s, outline .2s, color .2s, background .2s;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  i {
    font-size: 1.1em;
    line-height: inherit; }
  i:first-child {
    margin-right: 5px; }
  i:last-child {
    margin-left: 5px; }

  &:hover {
    color: $color-primary-500; }

  &:focus:not(:focus-visible) {
    color: $color-text-grey;
    box-shadow: none; }
  &:focus,
  &.focus-visible:focus:not(:focus-visible) {
    color: $color-primary-500;
    box-shadow: 0 0 0 2px $color-focus; }

  &:active,
  &.active {
    color: $color-primary-600; }

  &:disabled,
  &.disabled {
    color: $color-border-new; } }

@mixin btn-primary {
  @include font(rem-size(16px), rem-size(24px));
  border-radius: 4px;
  padding: 9px 21px;
  color: $color-white;
  font-weight: $font-w-semi-bold;
  border: none;
  background: $color-primary-500;
  transition: border-color .2s, outline .2s, color .2s, background .2s;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  i {
    font-size: 1.1em;
    line-height: inherit; }
  i:first-child {
    margin-right: 5px; }
  i:last-child {
    margin-left: 5px; }

  &:hover {
    background: $color-primary-400; }

  &:focus:not(:focus-visible) {
    box-shadow: none; }
  &:focus,
  &.focus-visible:focus:not(:focus-visible) {
    box-shadow: 0 0 0 2px $color-focus; }

  &:active,
  &.active {
    background: $color-primary-600;
    box-shadow: 0 4px 6px rgba($color-black, .1); }

  &:disabled,
  &.disabled {
    background: $color-border-new; }

  &.pink {
    background: $color-secondary-500;
    &:hover {
      background: $color-secondary-400; }
    &:active,
    &.active {
      background: $color-secondary-600; } }

  &.btn-inline-block {
    @include inline-block; } }

@mixin btn-primary-destructive {
  background: $color-error-new; }

@mixin btn-secondary-destructive {
  border-color: $color-error-new;
  color: $color-error-new; }

@mixin btn__size($height, $font-size, $padding) {
  font-size: $font-size;
  height: $height;
  line-height: $height - 2 * $btn__border;
  padding: 0 ($padding - $btn__border); }

@mixin btn__size_primary {
  @include btn__size($btn__height_primary, $btn__fontSize_primary, $btn__padding_primary); }

@mixin btn__size_secondary {
  @include btn__size($btn__height_secondary, $btn__fontSize_secondary, $btn__padding_secondary); }

@mixin btn__size_compact {
  @include btn__size($btn__height_compact, $btn__fontSize_secondary, $btn__padding_compact); }

@mixin btn-tertiary {
  @include font($btn__fontSize_tertiary, normal);
  background: none;
  border: none;
  text-decoration: underline;
  outline: none;

  &:disabled {
    text-decoration: none; } }

@mixin btn_disabled {
  cursor: not-allowed;
  opacity: 0.5; }

@mixin btn_noclick {
  cursor: default; }

@mixin btn_bold {
  font-weight: $btn__weight_bold; }

@mixin btn__enabled {
  &:not(:disabled):not(.noclick):not(.disabled) {
    @content; } }

@mixin btn__style_solid($color, $dark: false) {
  background: $color;
  color: $color-white;

  @include btn__enabled {
    &:focus {
      outline: 3px solid #{$color}; }
    &:hover,
    &:focus {
      @if $dark {
        background: lighten($color, 15%); }
      @else {
        background: darken($color, 15%); } }
    &:active {
      @if $dark {
        background: lighten($color, 20%); }
      @else {
        background: darken($color, 15%); } } } }

@mixin btn__style_white($color) {
  background: $color-white;
  color: $color;

  @include btn__enabled {
    &:focus {
      outline: 3px solid #{$color}; }
    &:hover,
    &:focus {
      background: darken($color-white, 10%); }
    &:active {
      background: darken($color-white, 15%); } } }

@mixin btn__style_outlined($color) {
  background: rgba($color, 0);
  border-color: $color;
  color: $color;

  @include btn__enabled {
    &:focus {
      outline: 3px solid #{$color}; }
    &:hover,
    &:focus {
      background: rgba($color, .2); }
    &:active {
      background: rgba($color, .3); } } }

@mixin btn-icon {
  @include btn__base;
  background: none;
  border: none;
  color: $color-grey-90;
  transition: .3s;
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $color-primary; }
  &:focus:not(:focus-visible) {
    box-shadow: none; }
  &:focus,
  &.focus-visible:focus:not(:focus-visible) {
    color: $color-primary;
    box-shadow: 0 0 0 2px $color-focus; }
  &:active {
    color: $color-primary-600; }

  * {
    font-size: inherit; } }

@mixin btn-empty {
  i {
    position: relative; }
  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: auto;
    visibility: hidden; } }

@mixin btn__icon {
  margin-right: .3em; }

@mixin btn__icon_after {
  margin-left: .3em;
  margin-right: 0; }

@mixin btn-breadcrumbs {
  @include btnLink__base;
  @include btnLink__color;
  text-transform: none;
  display: inline-flex;
  gap: 16px;
  align-items: center;
  border-bottom: 2px solid transparent;

  &:focus,
  &:hover {
    text-decoration: none;
    color: $color-primary;
    border-color: $color-primary; } }

@mixin btnLink($size, $color: $color-grey-albatross) {
  @include btnLink__base;

  @if $size == 'primary' {
    @include btnLink__size_primary; }
  @else if $size == 'secondary' {
    @include btnLink__size_secondary; }
  @else if $size == 'compact' {
    @include btnLink__size_compact; }

  @include btnLink__color($color); }

@mixin btnLink__base {
  cursor: pointer;
  display: inline-block;
  font-family: $font-primary-new;
  font-weight: $font-w-regular;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  background: none;
  border: none;
  transition: opacity .2s;

  &:focus {
    @include btnLink__underline; }

  &:disabled,
  &.disabled {
    @include btn_disabled; }

  &.noclick {
    @include btn_noclick; } }

@mixin btnLink__color($color: $color-grey-albatross) {
  color: $color;

  &:hover,
  &:focus {
    color: darken($color, 5%); }
  &:active {
    color: darken($color, 10%); } }

@mixin btnLink__color_dark($color: $color-black) {
  color: $color;

  @include btn__enabled {
    &:hover,
    &:focus {
      color: lighten($color, 5%); }
    &:active {
      color: lighten($color, 10%); } } }

@mixin btnLink__size($height, $font-size) {
  @include font($font-size, $height);
  height: $height; }

@mixin btnLink__size_primary {
  @include btnLink__size($btn__height_primary, $btn__fontSize_primary); }

@mixin btnLink__size_secondary {
  @include btnLink__size($btn__height_secondary, $btn__fontSize_secondary); }

@mixin btnLink__size_compact {
  @include btnLink__size($btn__height_compact, $btn__fontSize_secondary); }

@mixin btnLink__underline {
  text-decoration: underline; }

@mixin btnLink__hover {
  @include btn__enabled {
    &:hover,
    &:focus {
      background: rgba($color-black, .025); } } }

@mixin btn__xs {
  line-height: normal;
  height: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 4rem; }

@mixin btnDownload {
  @include btn__base;
  @include font($font-s-xs, $line-height-xs);
  color: $color-driver-who;
  font-weight: $font-w-semi-bold;
  text-align: left;
  border: 1px solid $color-border;
  background: $color-white;
  display: inline-flex;
  align-items: center;
  padding-right: 3rem;
  transition: background .3s;

  &:hover {
    background: $color-grey-light; } }

@mixin btn-radio {
  @include font(rem-size(16px), rem-size(25.6px));
  font-weight: $font-w-regular;
  color: $color-grey-90;
  background: $color-white;
  border: 1px solid $color-border;
  border-radius: 8px;
  padding: rem-size(16px);
  display: flex;
  align-items: flex-start;
  text-align: left;

  &:before {
    content: '';
    display: block;
    width: rem-size(16px);
    height: rem-size(16px);
    flex: 0 0 auto;
    background: $color-white;
    border: 1px solid $color-border;
    border-radius: 50%;
    box-shadow: inset 0px 2px 4px rgba($color-black, 0.06);
    margin-right: rem-size(12px);
    margin-top: .2em; }

  &.active {
    color: $color-primary;
    border-color: $color-primary;
    background: $color-primary-50;
    font-weight: $font-w-bold;

    &:before {
      border-width: 0;
      box-shadow: inset 0px 0px 0px 4px $color-primary; } } }

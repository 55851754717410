@import "~theme/colors";
@import "~theme/responsive/responsive";
@import "~theme/scale";
@import "~theme/type";

.VideoModal {
  &__content {
    background: $color-white;
    border-radius: 3px;
    color: $color-blue-purple-light;
    left: 50%;
    outline: none;
    overflow: hidden;
    padding: 3rem;
    position: absolute;
    text-align: center;
    transform: translate(-50%,-50%);
    transition: transform .3s, opacity .3s;
    top: 50%;
    width: 800px;

    &Landscape {
      margin: auto;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%; }

    @include screen-xs {
      width: 100%;
      height: 100%; } }

  &__overlay {
    align-items: center;
    background: rgba($color-primary, .6);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: background .3s;
    z-index: 9999;

    &Mobile {
      width: 100%;
      height: 100%; } }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &Container {
      position: relative;
      padding-top: 56.25%;

      &Landscape {
        padding-top: 35.25%; } }

    .player {
      background: white; } }

  &__exit {
    @include font($font-s-1, $line-height-sm);
    color: $color-grey-heather;
    padding: 0;
    position: absolute;
    top: 5rem;
    right: 5rem;
    background-color: transparent; } }

// Grid system styles

@import "~theme/responsive/responsive";
@import "./grid";


.container {
  @include container; }

.container-fluid {
  @include container-fluid; }

.container-1040 {
  @include container;
  max-width: calc(1040px + 10rem);

  @include screen-sm-minus {
    max-width: calc(1040px + 5rem); } }

.row {
  @include row; }

.card {
  @include card;
  &.card-color-grey-50 {
    @include card-color($color-grey-50); }
  &.card-color-primary-50 {
    @include card-color($color-primary-50); } }

.card-shadow {
  @include card-shadow; }

.card-heading {
  @include card-heading; }

.card,
.card-shadow {
  &.padding-32 {
    padding: 32px; } }

.divider {
  @include divider; }

.tip.tippy-box {
  @include tip;

  * {
    font-weight: inherit; }

  .tippy-arrow {
    color: $color-grey-90; } }

// Common styles for small and large grid columns
@include make-grid-columns;

// Extra small grid
@include make-grid(xs);

// Small grid
@include screen-sm-plus {
  @include make-grid(sm); }

// Medium grid
@include screen-md-plus {
  @include make-grid(md); }

// Large grid
@include screen-lg {
  @include make-grid(lg); }

@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";

.ConversationGoals {
  background: $color-white;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba($color-black, .1);

  h3 {
    @include font($font-s-0, $line-height-0);
    font-weight: $font-w-semi-bold;
    color: $color-primary; }

  &__goals {
    @include font($font-s-sm, $line-height-sm);
    color: $color-grey-dark-med;
    font-weight: $font-w-regular;

    ul, li {
      font-weight: inherit; }
    ul {
      list-style-type: disc;
      list-style-position: outside;
      padding-left: 3rem; } }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid $color-border;

    .label {
      @include label;
      @include font($font-s-sm, $line-height-sm);
      color: darken($color-grey-dark-med, 10%); } }

  &__progress {
    max-width: 8rem;
    max-height: 8rem;
    position: relative;

    span {
      font-size: $font-s-sm;
      font-weight: $font-w-regular;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $color-grey-dark-med; } }

  &__donut {
    path {
      fill: rgba($color-primary, .1);

      &.filled {
        fill: $color-success; }
      &.hidden {
        fill: none; } } }

  &__dates {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $color-border;
    padding-top: 3rem;
    margin-top: 3rem;

    .label {
      @include label;
      @include font($font-s-sm, $line-height-sm);
      color: darken($color-grey-dark-med, 10%); } }

  &__date {
    @include font($font-s-sm, $line-height-sm);
    color: $color-grey-dark-med;
    font-weight: $font-w-regular;

    &:first-child {
      text-align: left; }

    &:last-child {
      text-align: right; } } }

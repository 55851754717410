@import "~theme/responsive/responsive";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/fonts";
@import "~theme/common-form";
@import "~theme/img-url";

$progress-w: 120rem;

.Assessment.page > .container {
  padding-top: $Header__h; }

.Assessment {
  &__container {
    background: $color-grey-light;
    overflow: hidden;

    section {
      text-align: center;
      width: 100%;
      font-weight: $font-w-regular; } }

  *[tabIndex="-1"] {
    outline: none; }

  &__loadingOverlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: table;
    background: rgba($color-grey-med, .6);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all .5s;

    &.visible {
      opacity: 1;
      visibility: visible; }

    span {
      display: table-cell;
      vertical-align: middle;
      color: $color-header-blue; } }

  &__languageDropdown {
    max-width: 60rem;
    margin: auto;
    margin-bottom: 3rem;

    &>a {
      float: unset; }

    &_label > span {
      @include label;
      display: block;
      margin-bottom: 1rem; } }

  &__intro_content_layout {
    display: grid;
    grid-template-columns: 40rem 1fr;
    grid-column-gap: 2rem;
    text-align: start;
    padding-top: 2rem;

    @include screen-md-minus {
      grid-template-columns: 1fr; }

    p {
      margin-bottom: 4rem; } }

  &__title {
    @include title-blue;
    @include font($font-s-3, $line-height-3); }

  &__text_bold {
    font-weight: 700; }

  &__text_bold {
    font-weight: 700; }

  &__content {
    margin: 2rem 0;
    position: relative;

    @include screen-sm-plus {
      width: 60%;
      margin: auto; } }

  &__animationContainer {
    display: block;
    position: relative;
    font-weight: $font-w-regular;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    h2 {
      @include font($font-s-3, $line-height-3); }

    &-enter {
      opacity: 0;
      transition: opacity .3s; }

    &-enter-active {
      opacity: 1;
      position: absolute; }

    &-exit {
      position: absolute;
      opacity: 1;
      transition: opacity .3s; }

    &-exit-active {
      opacity: 0; } }

  &__img {
    height: 40rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 3rem auto;

    &_aaron_intro {
      background-image: url($url-aaron);
      margin: 0; }

    &_peer_network {
      background-image: url($url-peer-network);
      margin-bottom: -2rem; }

    &_aaron2 {
      background-image: url($url-aaron2);
      margin-bottom: -2rem;
      margin-top: 5rem; } }

  &__itemContainer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 50vh;
    margin: 8rem 0; }

  &__itemPlaceholder {
    visibility: hidden;
    min-height: 50vh;
    margin: 8rem 0; }

  &__itemRadio {
    max-width: 120rem;
    margin: auto;
    padding: 8rem 0; }

  &__itemOption {
    @include screen-xs {
      p {
        position: fixed;
        left: 0;
        right: 0;
        margin: 0 26px; } } }

  &__dropdown {
    @include select;
    max-width: 100rem;
    margin: auto; }

  &__question {
    @include font($font-s-0, $line-height-0);
    text-align: center;
    font-weight: $font-w-semi-bold;

    @include screen-sm-plus {
      @include font($font-s-0, $line-height-0); }

    &Slider {
      font-weight: $font-w-regular; } }

  &__answer {
    @include font($font-s-0, $line-height-0);
    margin-top: 5rem; }

  &__option {
    display: block;

    &.report {
      display: inline-block;

      button {
        margin: 0 2rem;
        min-width: 20rem;
        width: auto;

        &:focus {
          outline-color: $color-primary; } }

      &.selected button {
        outline-color: $color-primary;
        color: $color-primary; } }

    input {
      position: absolute;
      opacity: 0;

      &:hover ~ div {
        outline-color: $color-primary; }
      &:focus ~ div {
        outline: 2px solid $color-primary; } }

    > div,
    > button {
      outline: 1px solid $color-border;
      border: none;
      background: $color-white;
      border-radius: 3px;
      cursor: pointer;
      padding: 2.5rem;
      position: relative;
      font-weight: $font-w-medium;
      margin: 0 1px; }

    &.selected {
      > div,
      > button {
        outline: 2px solid $color-header-blue;
        color: $color-header-blue;
        font-weight: $font-w-semi-bold;

        .radioMark {
          color: $color-header-blue; } } }

    * + & {
      margin-top: 3rem; }

    @include screen-xs {
      margin-left: 1px;
      margin-right: 1px; } }

  &__option.selected & {
    &__radioMark {
      color: $color-header-blue; } }

  &__optionHandle {
    color: $color-greyish;
    font-size: 4rem;
    line-height: 1;
    margin-top: -0.5em;
    position: absolute;
    right: 2rem;
    top: 50%;

    &:hover {
      color: $color-white; } }

  &__optionText {
    &.filled input {
      outline: 2px solid $color-header-blue; }

    &.error input {
      outline: 2px solid $color-red; }

    input {
      @include input;
      @include font($font-s-0, $line-height-0);
      outline: 1px solid $color-border;
      border: none;
      background: $color-white;
      border-radius: 3px;
      padding: 2.5rem;
      position: relative;
      font-weight: $font-w-medium;
      margin-top: 2rem;

      &:focus,
      &:active {
        outline: 2px solid $color-header-blue; } }

    &Infos {
      @include font($font-s-sm, $line-height-sm);
      color: $color-grey-dark;
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;

      &_error {
        color: $color-red; } } }

  &__optionPlaceholder {
    padding: 2.5rem;
    margin: 2.5rem 0;
    border: (1rem / 3) solid transparent; }

  &__radioLabel {
    line-height: 1.5;
    margin-right: 6rem;
    font-weight: inherit; }

  &__radioMark {
    top: calc(50% - 2rem);
    font-size: 4rem;
    line-height: 1;
    position: absolute;
    right: 3rem;
    color: $color-border; }

  &__order {
    min-height: 50rem; }

  &__orderLabel {
    font-weight: $font-w-bold; }

  &__err {
    margin-bottom: -5rem;
    margin-top: 5rem;
    text-align: center;

    span {
      @include font($font-s-sm, $line-height-sm);
      font-weight: $font-w-semi-bold;
      background: mix($color-red, $color-white, 50%);
      border: 2px solid$color-red;
      border-radius: 3px;
      padding: 1rem 2rem;
      position: relative;

      &:after,
      &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute; }

      &:after {
        border-bottom-color: mix($color-red, $color-white, 50%);
        border-width: 2rem;
        margin-left: -2rem; }

      &:before {
        border-bottom-color: $color-red;
        border-width: calc(2rem + 3px);
        margin-left: calc(-2rem - 3px); } } }

  &__nav {
    margin-top: 10rem;
    text-align: center;

    .btn {
      @include screen-max(370px);
      min-width: 21rem !important; }

    &_intro {
      margin-top: 7rem; } }

  &__exit {
    @include font($font-s-0, $line-height-0);
    opacity: .7;
    text-align: center;

    &._q {
      margin-top: 3vh;
      margin-bottom: 3vh; }
    a {
      color: $color-header-blue; } }

  &__intersitialOptions {
    display: flex;
    text-align: center;

    p {
      flex-basis: 50%; } }

  &__intersitialNav {
    margin-top: 10rem;
    display: flex;
    justify-content: space-around;

    .btn {
      @include screen-max(370px);
      min-width: 21rem !important; } }

  &__intersitialDriver {
    img {
      display: inline-block;
      width: 25%; }

    div:first-of-type {
      display: inline-block;
      width: 75%; }

    &Modal {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      z-index: 1;

      &Content {
        background: white;
        max-width: 800px;
        width: 90%;
        padding: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include screen-sm-minus {
          img {
            display: block;
            margin: auto;
            width: 35%;
            margin: auto; }

          div:first-of-type {
            display: block;
            width: 100%; } } }

      &Buttons {
        text-align: center;
        width: 100%;
        margin: 5rem 0 0;

        @include screen-xs {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;

          button {
            width: 90%; }

          button:last-of-type {
            margin-bottom: 4rem; } }

        button {
          margin: 0 5rem; } } } }

  &__reports {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      @include font($font-s-0, $line-height-2);
      font-weight: $font-w-semi-bold;
      transition: outline-color .3s, color .3s;
      outline: 1px solid $color-border;
      border: none;
      color: $color-grey-albatross;
      height: $line-height-2;
      padding-left: 3rem;
      padding-right: 3rem;
      margin: auto;

      &:focus {
        outline: 2px solid $color-header-blue; } }

    button {
      display: inline-block;
      margin: 0 2px;
      width: calc(50% - 4px); }

    &Input {
      margin: auto;
      margin-top: 5rem;
      border-top: 1px solid $color-border; } }

  &__row {
    display: flex;
    justify-content: space-between; }

  &__icons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    gap: 2rem;
    margin: 0 1rem;

    @include screen-xs {
      grid-template-columns: 40rem;
      justify-content: center; } }

  &__iconOption {
    input {
      position: absolute;
      opacity: 0;

      &:hover ~ div {
        outline-color: $color-primary; }
      &:focus ~ div {
        outline: 2px solid $color-primary; } }

    div {
      min-width: 15rem;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      align-items: center;
      background: $color-white;
      padding: 2.5rem;
      outline: 1px solid $color-border;
      border-radius: 3px;
      cursor: pointer;
      margin: auto; }

    p {
      @include font($font-s-sm, $line-height-sm);
      margin-top: 5rem;
      font-weight: $font-w-regular; }

    img {
      width: 85%;
      min-height: 1px; //IE11

      @include screen-xs {
        width: 40%; } }

    @include screen-xs {
      &:first-child {
        margin-left: 1px; }

      &:last-child {
        margin-right: 2px; } }

    &.selected div {
      outline-color: $color-header-blue;
      outline-width: 2px;

      p {
        color: $color-header-blue;
        font-weight: $font-w-semi-bold; } } }

  &__opening {
    display: flex;
    align-items: center;

    img {
      padding-right: 20rem; }

    &Content {
      text-align: left; } }

  &__label {
    text-transform: uppercase; }

  &__purposeTitle {
    width: 60%;
    margin-top: 3rem; }

  &__drivers {
    margin: 0 -2rem; }

  &__dimension {
    display: inline-block;
    vertical-align: middle;
    margin: 0 2rem;
    position: relative;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 2rem;

    @include screen-max(767px) {
      border-radius: 7px; }

    &.who {
      border: 2px solid rgba($color-header-blue, .2); }
    &.why {
      border: 2px solid rgba($color-driver-why, .2); }
    &.how {
      border: 2px solid rgba($color-driver-how, .2); }
    &.who, &.why, &.how {
      @include screen-sm-plus {
        &:after {
          content: '';
          border-bottom: 4px solid $color-grey-light;
          height: 2px;
          position: absolute;
          left: 0;
          padding: 0 50%;
          bottom: -4px; } } }

    p {
      font-weight: $font-w-semi-bold !important; }

    img {
      width: 20rem;
      filter: saturate(0);
      opacity: .5;

      @include screen-sm {
        width: 15rem; } }

    &.active img {
      opacity: 1;
      filter: none; } }

  &__desc {
    padding: 2rem;
    border-radius: 10px;
    &.who {
      border: 2px solid rgba($color-header-blue, .2); }
    &.why {
      border: 2px solid rgba($color-driver-why, .2); }
    &.how {
      border: 2px solid rgba($color-driver-how, .2); }

    &.who, &.why, &.how {
      @include screen-xs {
        border: none; } } }


  &__purposeStatement {
    .row {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      text-align: left; }

    .question {
      text-align: left; }

    h3 {
      @include font($font-s-2, $line-height-3);
      font-weight: $font-w-extra-bold;
      padding-right: 5rem;
      text-align: left;

      @include screen-min(1400px) {
        @include font($font-s-3, $line-height-4); } }

    h5 {
      @include font($font-s-1, $line-height-2);
      font-weight: $font-w-bold;
      text-align: right; }

    .who {
      color: $color-driver-who; }
    .why {
      color: $color-driver-why; }
    .how {
      color: $color-driver-how; } }

  &__org {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-areas: "title title" "logo content";
    grid-gap: 6rem;

    @include screen-sm-minus {
      grid-template-columns: 1fr;
      grid-template-areas: "title" "logo" "content"; }

    &Title {
      grid-area: title; }

    &Logo {
      grid-area: logo;
      display: block;
      max-width: 100%;

      @include screen-sm-minus {
        max-height: 15rem;
        margin-left: auto;
        margin-right: auto; } }

    &Content {
      grid-area: content;
      text-align: left;

      &Item__title {
        font-weight: bold; }

      &Item__text {
        margin-bottom: 3rem; } } }

  &__roleStats {
    display: flex;
    flex-flow: row wrap;

    @include screen-xs {
      flex-flow: column; }

    .chart {
      flex: 1 1 30%;
      padding-right: 5rem;
      display: flex;
      align-items: center;

      @include screen-xs {
        padding: 0; } }

    &>div {
      flex: 1 1 70%; }

    &Item {
      text-align: left;
      * {
        display: inline-block;
        vertical-align: middle; }

      p {
        @include font($font-s-1, $line-height-2);
        font-weight: $font-w-bold; } }

    &Logo {
      width: 5rem;
      height: 5rem;
      background: url($url-logo-pink-m) center / contain no-repeat;
      margin-right: 2rem; }

    .caption {
      @include font($font-s-sm, $line-height-sm);
      margin-top: 3rem;
      color: $color-grey-dark-med;
      font-weight: $font-w-regular;
      text-align: left;

      &:before {
        content: '*'; } } }

  &__video {
    position: relative;
    cursor: pointer;
    max-width: 90rem;
    position: relative;
    margin: auto;
    margin-top: 5rem;

    @include screen-min(1400px) {
      max-width: $progress-w; }

    img {
      border: 1px solid $color-border;
      width: 100%; }

    &Header {
      margin: auto;
      max-width: $progress-w;

      img {
        width: 15%;
        display: inline-block;
        vertical-align: middle; }

      h3 {
        font-weight: $font-w-semi-bold;
        display: inline-block;
        vertical-align: middle;
        width: 85%; } } }

  &__videoArrow {
    color: $color-pink-bright;
    font-size: 16rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%); }

  &__videoMask {
    position: absolute;
    transition: background .5s;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:hover {
      background: rgba($color-black, .05);
      transition: background .5s; } }

  &__modal {
    max-width: 70rem;

    p {
      text-align: center;
      margin-bottom: 4rem; }

    &Btns {
      display: flex;
      justify-content: space-between; } } }

@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-form";

.Video {
  margin: auto;
  position: relative;
  min-height: 40rem;
  max-height: 150rem;
  height: 100%;
  background: $color-black; //$color-primary
  transition: min-height .5s;

  @include screen-xs {
    max-height: 100rem; }

  &:hover &__btns,
  &:focus &__btns,
  &:focus-within &__btns,
  &:hover &__settings button.btn-video,
  &:focus &__settings button.btn-video,
  &:focus-within &__settings button.btn-video {
    opacity: 1; }

  @include screen-xs {
    height: 30vh;
    width: 100%; }

  &__participant {
    @include font($font-s-sm, $line-height-sm);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 55;
    background: black;
    border-bottom-left-radius: 8px;
    color: $color-white;
    padding: .5rem 1rem;

    &.local {
      transform: scale(-1, 1);
      right: 0;
      left: auto; } }

  &__quality {
    margin-right: .5rem;
    display: inline-block;
    width: 1.2em;

    svg {
      path {
        fill: white; }
      &.low path {
        fill: $color-error; }
      &.medium path {
        fill: $color-yellow-calm-light; }
      &.low path,
      &.medium path {
        &[opacity] {
          fill: white; } } } }

  &__placeholder {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-size: cover;
    background-position: 60% 50%;
    background-repeat: no-repeat;
    z-index: 10; }

  &__info {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    pointer-events: none;
    text-align: center;
    padding: 2rem 3rem;
    text-align: center;
    transform: translateY(-50%);

    span {
      @include label;
      color: $color-white; }

    &.fa-circle-notch {
      position: absolute;
      left: 50%;
      top: 50%;
      font-size: 1.5em; }

    &.error {
      color: $color-error; } }

  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }

  &__btns {
    position: absolute;
    bottom: 4rem;
    left: 2rem;
    right: 2rem;
    text-align: center;
    z-index: 9;
    opacity: 0;
    transition: opacity .3s;

    &.visible {
      opacity: 1; } }

  &__settings {
    &.visible button.btn-video {
      opacity: 1; }

    button.btn-video {
      position: absolute;
      top: 2rem;
      left: 2rem;
      margin: 0;
      z-index: 998;
      opacity: 0;
      transition: opacity .3s; } }

  &__time {
    @include font($font-s-sm, $line-height-sm);
    font-weight: $font-w-semi-bold;
    position: absolute;
    top: 2rem;
    left: 2rem;
    min-width: 10rem;
    text-align: center;
    color: $color-white;
    padding: 1rem .5rem;
    border-radius: 3px;
    background: rgba($color-black, .5); }

  .btn-call,
  .btn-end,
  .btn-video {
    @include font(4rem, 10rem);
    width: 10rem;
    height: 10rem;
    color: $color-white;
    text-align: center;
    border-radius: 50%;
    background: none;
    margin: 0 2rem;
    padding: 0;
    border: 0;
    z-index: 9;
    cursor: pointer;
    transition: all .3s;

    &:focus:not(:focus-visible) {
      box-shadow: none; }
    &:focus,
    &.focus-visible:focus:not(:focus-visible) {
      box-shadow: 0 0 0 2px $color-focus; }

    @include screen-xs {
      @include font(3rem, 8rem);
      width: 8rem;
      height: 8rem; } }

  .btn-call,
  .btn-end {
    @include font(5rem, 12rem);
    width: 12rem;
    height: 12rem;

    @include screen-xs {
      @include font(4rem, 10rem);
      width: 10rem;
      height: 10rem; }

    &:focus:not(:focus-visible) {
      box-shadow: none; }
    &:focus,
    &.focus-visible:focus:not(:focus-visible) {
      box-shadow: 0 0 0 2px $color-focus; }

    i {
      transform: translateY(.5rem) rotateZ(-135deg); } }

  .btn-call {
    color: $color-success;
    background: $color-white;

    &:focus,
    &:hover {
      background: $color-success;
      color: $color-white; } }

  .btn-end {
    background: $color-red;

    &:focus,
    &:hover {
      background: mix($color-black, $color-white, 7%);
      color: $color-red; } }

  .btn-video {
    color: $color-grey-90;
    background: $color-white;

    &:focus,
    &:hover,
    &.active {
      background: mix($color-primary, $color-white, 10%);
      color: $color-primary; }

    &.disabled {
      opacity: .5; } }

  &__remote {
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    position: relative; }

  &__local {
    position: absolute;
    right: 2rem;
    top: 2rem;
    z-index: 9;
    width: 30%;
    transform: scale(-1, 1);

    video {
      border-radius: 8px;
      position: static; }

    &.fullSize {
      top: 0;
      left: 0;
      width: 100%;
      position: absolute;
      height: 100%;
      max-width: unset;
      min-height: unset;
      margin: auto;

      video {
        position: absolute;
        border-radius: 0; } }

    &.audioOnly {
      display: none; } }

  &__disabled {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: $color-grey-heather-dark;
    box-shadow: 0 5px 10px 0 rgba($color-black, .2); }

  &__error {
    width: 70rem;
    margin: auto;
    position: relative;
    border: 1px solid $color-pink;
    border-radius: 2px;
    background: rgba($color-pink-redish, .4);

    @include screen-xs {
      width: 100%; }

    &Title {
      color: $color-pink;
      text-transform: uppercase;
      font-size: $font-s-sm;
      position: relative;
      font-weight: $font-w-semi-bold;

      i {
        padding-right: 1rem;
        position: absolute;
        left: 2rem;
        font-size: $font-s-0; } }

    &Message,
    &Title {
      padding: 3rem 8rem; }

    &Description {
      padding: 0 8rem;
      margin: 1.5rem 0;

      &:first-of-type {
        margin-top: 0; }

      &:last-of-type {
        margin-bottom: 4rem; } }

    &Message {
      font-weight: $font-w-semi-bold; }

    &Camera {
      position: absolute;
      font-size: 20rem;
      margin: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $color-grey-dark-med; } }

  &__waiting {
    text-align: center;

    img {
      margin-bottom: 2rem; }

    p {
      margin-left: auto;
      margin-right: auto; } }

  &__notification &__btns {
    position: static;
    opacity: 1; }

  &__notification {
    position: absolute;
    padding: 5rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color-primary, .8);
    color: $color-white;
    z-index: 99;
    display: flex;
    flex-flow: column;

    @include screen-xs {
      padding: 3rem; }

    & > *:first-child {
      margin-top: auto; }
    & > *:last-child {
      margin-bottom: auto; }
    & > *:only-child {
      margin: auto 0; }

    &Row {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;

      &-full {
        flex: 1 1; } }

    &Actions {
      text-align: center;

      p {
        margin-top: 2rem; } }

    &Close {
      button {
        margin: auto;
        display: block;
        margin-top: 5rem; } }

    &.info {
      background-color: rgba($color-primary, .8); }

    &.error {
      background: rgba($color-secondary, .9); }

    &Icon {
      font-size: 5rem;
      padding-right: 5rem; }

    &Title {
      font-weight: $font-w-semi-bold;
      font-size: $font-s-1;

      @include screen-xs {
        @include font($font-s-sm, $line-height-xs); } }

    p {
      font-size: $font-s-sm;

      @include screen-xs {
        @include font($font-s-xs-sm, $line-height-xs); } }

    &.sticky {
      position: static;
      margin-bottom: 2rem; } } }

@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/clearfix";

@mixin table {
  table {
    width: 100%;
    background: $color-grey-light;
    border-radius: 3px;
    overflow: hidden;
    position: relative;

    thead {
      @include screen-xs {
        display: none; }

      th {
        @include label;
        text-align: left;
        padding: 2rem;

        &.invisible {
          visibility: hidden;
          font-size: 3px; } } }

    tbody {
      tr {
        cursor: pointer;
        transition: background .3s;

        @include screen-xs {
          display: flex;
          flex-flow: row wrap;
          border-top: 1px solid $color-border; }

        &:hover {
          background: $color-border; } }

      td {
        @include font($font-s-sm, $line-height-sm);
        border-top: 1px solid $color-border;
        padding: 2rem;

        @include screen-xs {
          display: flex;
          flex: 1 1;
          border: none;

          &:before {
            @include label;
            content: attr(data-th);
            margin-right: 2rem;
            color: $color-black; } } } } } }

@mixin colored-table {
  background: $color-white;
  border: 1px solid $color-border;

  .row {
    display: flex;
    margin: 0;

    &:not(:first-child) {
      border-top: 1px solid $color-border; }

    &.disabled {
      pointer-events: none; }

    &>div {
      @include text-secondary;
      font-weight: inherit;
      padding: 2rem; } }

  .clickable {
    cursor: pointer;
    transition: background .3s;

    &:hover {
      background: $color-grey-imperceptible; }

    &.active {
      background: $color-header-blue;
      color: $color-white;
      font-weight: $font-w-semi-bold; } }

  .heading {
    background: $color-header-blue;

    &>div {
      @include label;
      color: $color-white; } } }

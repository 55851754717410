@import "~theme/colors";
@import "~theme/fonts";
@import "~theme/grid/grid";
@import "~theme/scaffolding/scaffolding";

.MicroLesson {
  cursor: pointer;
  background: $color-white;
  position: relative;
  transition: background .3s;

  &__modal {
    padding: 0;

    @include screen-md-plus {
      max-width: 90rem; } }

  &__card {
    @include card;
    display: flex;
    flex-flow: column;
    height: 100%;
    padding: 0;
    max-width: 800px;
    transition: box-shadow .2s;
    overflow: hidden;
    outline: none;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);

    @include screen-xs {
      padding: 0;
      max-width: 300px; }

    &.inModal {
      border: none;
      border-radius: 0;
      box-shadow: none;

      &.inDashboard {
        width: unset; } }

    &.inDashboard {
      width: 306.67px;
      @include screen-xs {
        width: 250px; } }

    &:not(.inModal) {
      &:hover, {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1); }
      &:focus,
      &.focus-visible:focus:not(:focus-visible) {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1), 0 0 0 2px $color-focus; }

      &:focus:not(:focus-visible) {
        box-shadow: none; } }

    .Markdown {
      p, ul, li {
        @include p-2; }

      h1 {
        @include section-intro; } } }

  &__header {
    background: $color-driver-who;
    color: $color-white;
    padding: 12px 16px 8px 16px;
    position: relative;
    width: 100%;

    &Onboarding {
      background: $color-primary-700;

      &.pink {
        background: $color-secondary-600; } } }

  &__title {
    @include font(14px, 22.4px);
    font-weight: $font-w-bold;
    display: flex;
    align-items: center;
    gap: 8px;

    .material-icons {
      @include font(22px, 14px);
      color: rgba($color-white, .74); } }

  &__ratingModal {
    width: 30%;

    @include screen-md-minus {
      width: 40%; }

    @include screen-sm-minus {
      width: 60%; }

    @include screen-xs {
      width: 90%; } }

  &__rate {
    text-align: center;

    &Title {
      @include font($font-s-0, $line-height-0);
      font-weight: $font-w-semi-bold;
      margin: 2rem 0; }

    &Content {
      margin-bottom: 2rem;
      @include text-secondary; }

    &Stars {
      margin-bottom: 5rem;

      i {
        color: $color-driver-how;
        font-size: $font-s-2; } } }

  &__image {
    height: 145px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;

    @include screen-xs {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $color-white; }

    &Modal {
      height: auto;
      min-height: 20rem;
      margin: auto;
      display: block; } }

  &__content {
    @include p-2;
    padding: 16px;
    width: 100%;

    p {
      @include p-2;
      margin-bottom: 8px; }

    &Article {
      @include p-2;
      max-height: 40rem;
      overflow: auto; } }

  &__button {
    border-radius: 6px;
    font-weight: $font-w-medium;
    margin: 1rem;

    &.inDashboard {
      width: 100%; } }

  &__buttons {
    text-align: right;
    margin: 1rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between; }

  &__completed {
    pointer-events: none;
    text-align: right;

    i {
      margin-right: 1rem; } }

  &__starred {
    text-shadow: 0 0 2px $color-white; }

  &__link {
    color: $color-black;
    text-decoration: underline; }

  &__copy {
    position: relative;
    text-align: left; }

  &__copied {
    @include font($font-s-sm, $line-height-sm);
    background: $color-blue-playful;
    font-weight: $font-w-medium;
    color: $color-white;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 3px;
    opacity: 0;
    visibility: hidden;
    padding: .5rem 2rem;
    transition: all .3s;

    &.visible {
      opacity: 1;
      visibility: visible; } }

  &__video {
    position: absolute;
    width: 100%;
    height: 100%;

    .player {
      background: white; }

    &Thumbnail {
      cursor: pointer;
      border-radius: 8px;
      height: 145px; }

    &Container {
      position: relative;
      cursor: pointer;

      &.inModal {
        min-height: 40rem;
        margin: 0 -16px;
        margin-top: -16px;
        margin-bottom: 16px; }

      p {
        @include font($font-s-sm, $line-height-0);
        font-weight: $font-w-medium;
        text-align: center; } }

    &Mask {
      height: 100%;
      position: absolute;
      transition: background .5s;
      width: 100%;
      z-index: 99;

      &:hover {
        background: rgba($color-black, .05); } }

    &Arrow {
      color: $color-pink-bright;
      font-size: 16rem;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%); } }

  &__imager {
    background-size: cover;
    background-position: center;
    height: 100%; }

  &__modalExit {
    background: none;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    font-size: 14px;
    border-radius: 50%;
    transition: border .2s;

    &:hover,
    &:focus {
      border: 1px solid $color-white; } }

  &__onboarding {
    text-transform: uppercase;
    font-weight: 400; } }

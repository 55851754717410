@import "~theme/colors";
@import "~theme/type";
@import "~theme/scale";

@mixin title {
  @include font($font-s-2, $line-height-2);
  color: $color-pink;
  font-family: $font-primary-new;
  font-weight: $font-w-bold;
  margin-bottom: 2rem;

  .fa-chevron-left {
    font-size: .7em;
    margin-right: .5em; }

  @include screen-xs {
    @include font($font-s-1, $line-height-1); } }

@mixin title-black {
  @include title;
  color: $color-black; }

@mixin title-blue {
  @include title;
  font-weight: $font-w-bold;
  font-family: $font-primary-new;
  color: $color-header-blue; }

@mixin title-blue-caps {
  @include title-blue;
  @include font($font-s-1, $line-height-1);
  text-transform: uppercase;

  @include screen-xs {
    @include font($font-s-0, $line-height-0); } }

@mixin subtitle {
  @include title;
  @include font($font-s-1, $line-height-1);

  @include screen-xs {
    @include font($font-s-0, $line-height-0); } }

@mixin subtitle-black {
  @include subtitle;
  color: $color-black; }

@mixin subtitle-blue {
  @include subtitle;
  color: $color-primary; }

@mixin subtitle-caps {
  @include subtitle;
  @include font($font-s-0, $line-height-0);
  text-transform: uppercase; }

@mixin subtitle-blue-caps {
  @include subtitle-caps;
  color: $color-header-blue; }

@mixin subtitle-black-caps {
  @include subtitle-caps;
  color: $color-black; }

@mixin text-bold {
  font-weight: $font-w-semi-bold; }

@mixin text-secondary {
  @include font($font-s-sm, $line-height-sm);
  font-weight: $font-w-medium; }

@mixin text-grey {
  @include font($font-s-sm, $line-height-sm);
  color: $color-grey-dark-med;
  font-weight: $font-w-regular; }

@mixin title-description {
  @include text-grey;
  color: mix($color-black, $color-white, 70%); }

@mixin label {
  @include font($font-s-xs-sm, $line-height-sm);
  font-weight: $font-w-semi-bold;
  text-transform: uppercase; }

@mixin text-primary {
  @include font($font-s-sm, $line-height-sm);
  font-weight: $font-w-regular; }

@mixin text-xs {
  @include font($font-s-xs, $line-height-xs); }

@mixin text-underlined {
  text-decoration: underline; }

@import "~theme/scale";
@import "~theme/colors";
@import "~theme/type";
@import "~theme/fonts";
@import "~theme/scaffolding/scaffolding";

.ConsentsCheck {
  max-width: 90%;

  h1 {
    @include subtitle-blue;
    text-align: center; }

  p {
    @include text-secondary;
    a {
      color: $color-primary;
      font-weight: $font-w-semi-bold; } }

  &__btns {
    text-align: center;
    margin-top: 2rem;

    button,
    a {
      margin: 0 2rem; } }

  &__overlay {
    z-index: 20000; } }

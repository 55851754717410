@import "~theme/clearfix";
@import "~theme/responsive/responsive";
@import "~theme/scaffolding/scaffolding";
@import "~theme/colors";
@import "~theme/btn/btn";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/fonts";
@import "~theme/common-form";


.Settings {
  @include font(16px, 25.6px);
  color: $color-text-grey;

  &.page {
    background: $color-white;
    padding-bottom: 0; }

  p, li {
    @include p-new; }

  ul {
    list-style-type: disc;
    list-style-position: inside; }

  .Footer.static {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; }

  .warning {
    background: rgba($color-warning-new, .07);
    font-weight: $font-w-regular;
    padding: 8px 16px;
    margin: 5rem 0;

    i {
      color: $color-warning-new;
      margin-right: 2rem; } }

  .link {
    padding: 0; }

  li .link {
    margin-left: 1rem; }

  .card {
    min-height: 15rem;
    margin: 3rem 0;

    &-content {
      flex: 1 1; }

    + .section-head {
      margin-top: 5rem; } }

  &__restart {
    position: relative;
    .Loader {
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      background: mix(#374151, $color-white, 27%);
      color: $color-white;
      transition: all .2s; } }

  &__icon-stacked {
    position: relative;
    i:first-child {
      font-size: 17px; }
    i:last-child {
      font-size: 10px;
      position: absolute;
      left: 13px;
      top: 10px; } }

  &__placeholder {
    @include p-new;
    color: $color-grey-60; }

  &__collapsible {
    position: relative;
    display: flex;
    align-items: baseline;

    &-top {
      align-items: flex-start; }

    .caption-caps {
      float: left;
      flex: 0 0 18%;
      white-space: nowrap; }

    &Content {
      flex: 1 1;
      font-weight: $font-w-regular; }

    &Toggler {
      order: 1;
      margin-left: 3rem; } }

  &.page > .container {
    padding: 0;
    display: flex;
    min-height: calc(100vh - #{$header-h});

    > .container {
      width: 100%;
      position: relative; } }

  &__page {
    flex: 1 1;
    margin-top: 48px;
    padding-bottom: calc(15rem + 24px);
    position: relative; }

  &__nav {
    width: 256px;
    flex: 0 0 auto;
    border-right: 2px solid $color-grey-50;
    padding-top: 36px;

    @include screen-xs {
      width: 80px; }

    .kicker {
      padding: 8px 16px 8px 32px;
      padding-top: rem-size(48px);

      @include screen-xs {
        padding-left: 8px;
        padding-right: 8px; } }

    .navItem {
      @include screen-xs {
        padding: 8px;
        justify-content: center;
        * {
          margin: none; }
        *:not(:first-child) {
          display: none; } }

      &.active,
      &:focus,
      &.focus-visible:focus:not(:focus-visible) {
        color: $color-primary;
        font-weight: $font-w-bold;

        i.account {
          font-family: 'Material Icons'; } } } }

  &__landing {
    max-width: 1040px;
    margin: auto; }

  &__title {
    margin-bottom: 5rem;

    p {
      @include font(20px, 30px); } }

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 40px; }

  &__card {
    border: 1px solid $color-border;
    color: inherit;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    padding: 24px;

    &:hover,
    &:focus {
      border: 0.5px solid #4F62C8;
      box-shadow: 0px 2px 4px rgba($color-black, 0.06), 0px 4px 6px rgba($color-black, 0.1);

      i {
        color: $color-primary; } }

    h2 {
      @include font(20px, 35px);
      font-weight: $font-w-semi-bold; }

    i {
      font-size: 40px;
      margin-left: 10px; }

    span {
      @include p-new;
      color: $color-primary;
      display: block;
      margin-top: 8px; }

    &Text {
      flex: 1 1; } }

  &__section {
    &:not(:last-child) {
      margin-bottom: 5rem; }

    & + & {
      border-top: 1px solid $color-border;
      padding-top: 5rem; }

    p {
      margin: 3rem 0; } }

  &__select {
    @include select-new;
    flex: 1 1;

    &Option.react-select__option {
      display: flex;
      align-items: baseline; }

    &Option.react-select__single-value {
      min-width: unset;
      display: flex;
      align-items: baseline;
      left: 8px;
      right: 8px; }

    &Option.react-select__single-value &OptionName span {
      font-weight: $font-w-light; }

    &OptionName {
      @include font($font-s-sm, $line-height-sm);
      margin-left: 1rem; }

    &Offset {
      white-space: nowrap; } }

  &__organization {
    display: flex;
    align-items: center;
    flex-flow: row wrap;

    img {
      height: 24px;
      margin-right: 1rem; }

    p {
      margin: 0; }

    .caption-new {
      flex: 1 1 100%; } }

  &__field {
    position: relative;

    p {
      margin-top: 0; }

    &-text {
      @include input-new; }

    &-btn {
      margin-top: 3rem; }

    &-error {
      @include caption;
      color: $color-error-new;

      i {
        margin-right: 1rem; } }

    &.avatar {
      .btn-secondary {
        margin-top: 3rem; }

      p {
        margin-top: 3rem; } }

    &Row {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
      gap: 3rem; } }

  &__option {
    display: flex;

    input {
      position: absolute;
      opacity: 0; }

    p {
      margin: 0; }

    &Mark {
      flex: 0 0 16px;
      margin-top: .5rem;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid $color-grey-60;
      margin-right: 1rem;
      position: relative;
      box-shadow: inset 0 2px 4px 0 rgba($color-black, .06);
      transition: border-color .2s, background .2s;

      &.selected {
        border-color: $color-primary-500;
        background: $color-primary-500;

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          background: $color-white;
          border-radius: 50%; } } }


    * + & {
      margin-top: 3rem; }

    @include screen-xs {
      margin-left: 1px;
      margin-right: 1px; } }

  &__option.selected & {
    &__radioMark {
      color: $color-header-blue; } }

  &__optionText {
    margin-left: calc(16px + 1rem);
    margin-top: 1rem;
    input {
      @include input-new; }

    p {
      @include font($font-s-sm, $line-height-sm);
      color: $color-grey-dark;
      margin-top: 1rem; } }

  &__toast {
    padding: rem-size(8px) rem-size(16px);
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-weight: $font-w-regular;
    margin-bottom: 3rem;
    &.success {
      background: rgba($color-success-new, .07);

      i {
        color: $color-success-new;
        margin-right: 1rem; } } }

  &__form {
    &Btns {
      display: flex;
      justify-content: space-between;

      button {
        display: inline-block;

        + button {
          margin-left: 2rem; } } } }

  &__modalOverlay.Modal__overlay {
    background: $color-grey-60; }

  &__modal {
    padding: rem-size(24px);
    min-height: 40rem;
    display: flex;
    flex-flow: column;

    .section-head {
      margin: 0;
      padding-right: 10rem; }

    p {
      @include p-new;
      padding-right: 10rem;
      margin-top: 0;
      margin-bottom: 3rem; }

    &Btns {
      margin-top: auto;
      display: flex;
      justify-content: flex-end;

      button + button,
      button + a {
        margin-left: 1rem; } } }

  &__region {
    &Select {
      margin-top: 3rem; }

    .btn-primary {
      margin-top: 3rem; } }

  &__pauseBtn {
    text-align: center;
    margin-top: 2rem;

    &.warning {
      color: $color-warning; }

    h3 {
      @include font($font-s-0, $line-height-0);
      font-weight: $font-w-medium; }

    button {
      @include font($font-s-0, $line-height-0);
      text-transform: none; } }

  &__pause {
    .react-datepicker__input-container {
      input {
        border-radius: 4px; } }

    &Datepicker {
      max-width: 25rem;

      &.hasValue {
        border-color: $color-primary; }

      &Container {
        margin: 0 1rem; }

      &Input {
        position: relative;

        i {
          @include font(.9em, $line-height-1);
          position: absolute;
          color: $color-primary;
          right: 1.7rem;
          top: 0; } } }

    &Form {
      flex: 1 1;
      display: flex;
      flex-flow: column;
      gap: 18px;

      &.inline {
        flex-flow: row wrap;
        gap: 32px; }

      &.inline &Item {
        display: block;
        .react-datepicker-wrapper {
          margin-left: 0; } }

      &Item {
        display: flex;
        gap: 8px;
        span {
          flex: 1 1;
          display: block;
          font-weight: $font-w-semi-bold; }
        &--stretch {
          flex-flow: column;

          @include screen-xs {
            margin: 0; } }

        .Settings__select {
          max-width: 80rem; }

        .react-datepicker-wrapper {
          margin-left: 5rem; } } }

    &Infos {
      @include font($font-s-xs-sm, $line-height-xs);
      display: flex;
      justify-content: space-between;
      color: $color-grey-manatee;
      font-weight: $font-w-medium; }

    &Content {
      display: flex;
      flex-flow: row wrap;
      gap: 18px;
      align-items: flex-start;

      @include screen-xs {
        flex-flow: column; }

      p + p {
        margin-top: 2rem; }

      p {
        @include p-new; }

      img {
        max-width: 30rem;
        display: block;
        margin: auto;

        @include screen-xs {
          max-width: 20rem;
          margin-bottom: 3rem; } } }

    &Btns {
      flex: 1 1 100%;
      display: flex;
      justify-content: flex-end;

      &.inline {
        justify-content: flex-start;
        margin-top: 18px; }

      button.btn_primary {
        @include screen-xs {
          @include btn__size_secondary; } }

      button + button {
        margin-left: 3rem; } }

    &Error {
      color: $color-red;
      font-weight: $font-w-medium;
      text-align: center;
      margin-top: 3rem; } }

  &__togglerField {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Loader {
      background: rgba($color-white, .9);
      height: 22px; } }

  &__optional {
    position: absolute;
    font-size: 12px;
    top: 18px;
    font-style: italic; } }

@import "~theme/responsive/responsive";
@import "~theme/colors";
@import "./btn";

.btn-tertiary {
  @include btn-tertiary; }
.btn-tertiary-new {
  @include btn-tertiary-new; }
.btn-small {
  @include btn-small; }
.btn-creadcrumbs {
  @include btn-breadcrumbs; }

.btn {
  @include btn__base;

  &.btn_bold {
    @include btn_bold; }

  &.btn_block {
    display: block; }

  &.btn_centered {
    margin: auto; }

  &.btn_primary {
    @include btn__size_primary;

    &.btn_fw {
      min-width: 30rem; }

    &.btn_wide {
      min-width: 40rem;

      @include screen-xs {
        max-width: 55rem;
        width: 100%; } } }

  &.btn_secondary {
    @include btn__size_secondary;

    &.btn_fw {
      min-width: 25rem; }

    &.btn_wide {
      min-width: 32rem; } }

  &.btn_noPaddingXs {
    @include screen-xs {
      padding-left: 0;
      padding-right: 0; } }

  &.btn_compact {
    @include btn__size_compact; }

  &.btn_solid_blue {
    @include btn__style_solid($color-blue-playful); }

  &.btn_solid_blueLeadership {
    @include btn__style_solid($color-insight-leadership); }

  &.btn_solid_pink {
    @include btn__style_solid($color-pink); }

  &.btn_solid_pinkBright {
    @include btn__style_solid($color-pink-bright); }

  &.btn_solid_purple,
  &.btn_solid_purplePlayful {
    @include btn__style_solid($color-purple-playful); }

  &.btn_solid_bluePurple {
    @include btn__style_solid($color-driver-who); }

  &.btn_solid_grey {
    @include btn__style_solid($color-greyish); }

  &.btn_solid_black {
    @include btn__style_solid($color-grey-dark, true); }

  &.btn_solid_yellow {
    @include btn__style_solid($color-yellow-calm-dark); }

  &.btn_solid_green {
    @include btn__style_solid($color-driver-why); }

  &.btn_solid_red {
    @include btn__style_solid($color-red); }

  &.btn_white_blue {
    @include btn__style_white($color-blue-playful); }

  &.btn_white_bluePurple {
    @include btn__style_white($color-blue-purple-light); }

  &.btn_white_purple {
    @include btn__style_white($color-purple-passion); }

  &.btn_white_purplePlayful {
    @include btn__style_white($color-purple-playful); }

  &.btn_white_purplePlayfulDark {
    @include btn__style_white($color-purple-playful-dark); }

  &.btn_white_violet {
    @include btn__style_white($color-purple-playful-dark); }

  &.btn_white_black {
    @include btn__style_white($color-black); }

  &.btn_white_grey {
    @include btn__style_white($color-grey-albatross); }

  &.btn_white_pink {
    @include btn__style_white($color-pink); }

  &.btn_outlined_blue {
    @include btn__style_outlined($color-blue-playful); }

  &.btn_outlined_bluePurple {
    @include btn__style_outlined($color-blue-purple-light); }

  &.btn_outlined_red {
    @include btn__style_outlined($color-red); }

  &.btn_outlined_white {
    @include btn__style_outlined($color-white); }

  &.btn_outlined_green {
    @include btn__style_outlined($color-driver-why); }

  &.btn_outlined_purple,
  &.btn_outlined_purplePlayful {
    @include btn__style_outlined($color-purple-playful); }

  &.btn_outlined_pink {
    @include btn__style_outlined($color-pink); }

  &.btn_solid_violetModerate {
    @include btn__style_solid($color-violet-moderate); }

  &.btn_solid_blueModerate {
    @include btn__style_solid($color-blue-moderate); }

  &.btn_xs {
    @include btn__xs; } }

.btn-secondary {
  @include btn-secondary;
  &.destructive {
    @include btn-secondary-destructive; } }

.btn-primary {
  @include btn-primary;
  &.destructive {
    @include btn-primary-destructive; } }

.btn-primary,
.btn-secondary {
  &.large {
    min-width: 100px; } }

.btn__icon {
  @include btn__icon; }

.btn__icon_after {
  @include btn__icon_after; }

.btn-icon {
  @include btn-icon; }

.btn-empty {
  @include btn-empty; }

.btnLink {
  @include btnLink__base;
  @include btnLink__color;

  &.btnLink_block {
    display: block; }

  &.btnLink_primary {
    @include btnLink__size_primary; }

  &.btnLink_secondary {
    @include btnLink__size_secondary; }

  &.btnLink_compact {
    @include btnLink__size_compact; }

  &.btnLink_white {
    @include btnLink__color($color-white); }

  &.btnLink_purplePlayful {
    @include btnLink__color($color-purple-playful); }

  &.btnLink_bluePlayful {
    @include btnLink__color($color-blue-playful); }

  &.btnLink_pink {
    @include btnLink__color($color-pink); }

  &.btnLink_bluePurple {
    @include btnLink__color($color-header-blue); }

  &.btnLink_grey {
    @include btnLink__color($color-grey-albatross); }

  &.btnLink_underlined {
    @include btnLink__underline; }

  &.btnLink_hover {
    @include btnLink__hover; } }

.btnDownload {
  @include btnDownload; }

.btn-radio {
  @include btn-radio; }

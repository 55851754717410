@import "~theme/btn/btn";
@import "~theme/clearfix";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/img-url";
@import "~theme/scaffolding/scaffolding";


.SSO {
  img {
    width: 100%;
    max-height: 15rem;
    max-width: 30rem;
    margin: auto;
    margin-bottom: 2rem;
    display: block;
    object-fit: contain;
    object-position: center;

    &.bigger {
      max-height: 20rem;
      margin-bottom: 0; } }

  &__privacy {
    @include caption;

    .Signup__textBox {
      margin: 1rem 0; }

    a {
      @include link; } }

  &__info {
    @include font($font-s-sm, $line-height-sm);
    text-align: justify;
    margin: 4rem 0; }

  &__container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    max-width: 70rem;
    width: 100%; }

  &__inner {
    line-height: 0;
    margin-bottom: 6rem;
    text-align: center; }

  &__providers {
    margin-top: 10rem;
 }    // +clearfix

  &__provider {
    @include btn__base;
    @include btn__size($btn__height_compact, $font-s-xs, 1rem);
    width: calc((100% - 4rem) / 3);
    margin: 1rem 0;

    & + & {
      margin-left: 2rem; }

    &.fb {
      @include btn__style_solid($color-facebook); }
    &.g {
      @include btn__style_solid($color-google); }
    &.li {
      @include btn__style_solid($color-linkedin); }
    &.saml {
      @include btn__style_solid($color-okta);
      width: 100%; }

    &Icon {
      font-size: $font-s-0;
      vertical-align: middle; }

    &Title {
      display: inline-block;
      width: 13rem; }

    &Saml {
      font-size: $font-s-sm; } }

  &__status {
    @include font($font-s-0, 6rem);
    font-family: $font-primary-new;
    text-align: center; }

  &__error {
    @include font($font-s-xs-sm, $line-height-xs);
    color: $color-pink;
    font-family: $font-primary-new;
    margin-top: 3rem;
    text-align: center; } }

@import "~theme/responsive/responsive";


//TODO: rm aftrer transiton is complete
$font-primary: 'proxima-nova';
$font-primary-new: 'effra';
$font-secondary-new: 'serifa';
$font-mono: "Lucida Sans Typewriter", "Lucida Console", Monaco, "Bitstream Vera Sans Mono", monospace;

$font-w-thin: 100;
$font-w-extra-light: 200;
$font-w-light: 300;
$font-w-regular: 400;
$font-w-medium: 500;
$font-w-semi-bold: 600;
$font-w-bold: 700;
$font-w-extra-bold: 800;
$font-w-black: 900;


@mixin font($s, $h) {
  font-size: $s;
  line-height: $h; }

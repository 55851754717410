@import '~theme/responsive/responsive';
@import '~theme/colors';
@import '~theme/scale';
@import '~theme/scaffolding/scaffolding';
@import '~theme/type';

.Radio {
  &__option {
    margin: 8px 0;
    display: flex;
    * {
      cursor: pointer;
    }
    input {
      position: absolute;
      opacity: 0;
    }
    label {
      @include p-new;
    }
    &Icon {
      flex: 0 0 auto;
      font-size: 8px;
      margin-right: 8px;
      margin-top: 5px;

      &.checked {
        color: $color-primary;
      }
    }
  }
}

@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";

.NetworkError {
  h1 {
    @include title-black;
    text-align: center; }

  p {
    @include text-secondary;
    text-align: center; } }

@import "~theme/common-form";

.WorkshopForm {
  display: flex;
  align-items: center;

  input {
    @include input;
    max-width: 60rem;
    margin-right: 3rem; }

  button + button {
    margin-left: 3rem; } }

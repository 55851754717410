@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-form";

.Multiinput {
  input {
    border: none;
    background: none;
    outline: none;
    margin: .5rem;
    flex: 1 1;
    min-width: 20rem; }

  &__item {
    display: inline-block;
    margin: .5rem;
    padding: 0 1.5rem;
    background: rgba($color-border, .6);
    border: 1px solid rgba($color-border, .6);
    cursor: pointer;
    transition: all .3s;

    &:hover {
      background: rgba($color-border, 1);
      border: 1px solid rgba($color-border, 1); }

    &.error {
      border: 1px solid $color-notification-error-border;
      background: mix($color-red, $color-white, 20%);
      font-weight: $font-w-semi-bold; }

    .fa-times {
      font-size: .8em;
      margin-left: 1rem;
      opacity: .8; } }

  &__error {
    @include font($font-s-sm, $line-height-sm);
    font-weight: $font-w-semi-bold;
    background: mix($color-red, $color-white, 20%);
    border: 1px solid $color-red;
    padding: 1rem 2rem;
    position: relative;
    margin-bottom: 3rem; }

  &__input {
    @include font($font-s-sm, $line-height-sm);
    border: 1px solid $color-border;
    padding: 1rem;
    background: $color-white;
    transition: border .3s;
    display: flex;
    flex-flow: row wrap;
    max-height: 30rem;
    overflow: auto;

    &.focused {
      border: 1px solid $color-grey-heather; } } }

@import '~theme/colors';
@import '~theme/fonts';
@import '~theme/scaffolding/scaffolding';
@import '~theme/responsive/responsive';
@import '~theme/grid/grid';
@import '~theme/common-form';

.OptInBanner {
  background: $color-secondary;

  &__container {
    @include container;
    padding: 8px 3rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 16px;
    color: $color-white;

    @include screen-xs {
      padding-left: 2rem;
    }

    @include screen-min(1450px) {
      padding: 8px 0;
    }
  }

  p {
    color: inherit;
    flex: 1 1;

    @include screen-xs {
      flex: 1 1 100%;
    }
  }

  a {
    font-size: 14px;
    line-height: normal;
    background: none;
    color: inherit;
    border-color: $color-white;
  }

  button {
    color: inherit;
  }
}

.MatchStatus {
  max-width: 700px;

  h1 {
    @include title-black;
    font-weight: $font-w-medium;
  }

  h2,
  &__legend {
    font-weight: $font-w-bold;
    color: $color-black;
    display: block;
    margin: 0;
  }

  .p-new {
    margin: 12px 0;
  }

  &__current {
    display: inline-block;
    padding: 4px 16px;
    font-weight: $font-w-semi-bold;
    border-radius: 20px;
    background: rgba($color-yellow-calm-light, 0.2);
    color: mix($color-warning, $color-text-brown, 50%);

    &.open {
      color: mix($color-success-new, $color-text-brown, 80%);
      background: rgba($color-success-new, 0.15);
    }
    &.notOpen {
      color: mix($color-error-new, $color-text-brown, 50%);
      background: rgba($color-error-new, 0.15);
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 24px;

    p {
      flex: 1 1;
    }
  }

  &__select {
    @include select-new;
    flex: 1 1;

    &Option.react-select__option {
      display: flex;
      align-items: baseline;
    }
    &Option.react-select__single-value {
      min-width: unset;
      display: flex;
      align-items: baseline;
      left: 8px;
      right: 8px;
    }
    &Option.react-select__single-value &OptionName span {
      font-weight: $font-w-light;
    }
  }

  &__input {
    @include input-new;
    padding: 8px 10px;
    resize: none;
  }

  .Radio {
    label {
      display: flex;
      flex-flow: column;
      b {
        color: $color-black;
        font-weight: $font-w-semi-bold;
      }
    }
  }
}

@import '~theme/baseline/baseline';
@import "~theme/scale";
@import '~theme/common-form';
@import '~theme/style-vendor';
@import "~theme/grid/grid";
@import "~theme/coolicons/coolicons";
@import "~theme/material-icons/material-icons";
@import "~theme/twemoji-amazing";

.App {
  &__content {
    white-space: pre-line; } }

.page {
  min-height: 100vh;
  background: $color-grey-50;
  position: relative;
  padding-bottom: $Footer__h;

  @include screen-xs {
    padding-bottom: 0; }

  &>.container {
    flex: 1;
    width: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
    position: relative;

    @include screen-sm-minus {
      padding-bottom: 2.5rem;
      min-height: 100vh; } } }

.card {
  @include card; }

.relative {
  position: relative; }

@include slider;

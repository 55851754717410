@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";

.Questions {
  &__onboardingModal {
    padding: 0;
    max-width: 120rem;
    font-weight: $font-w-regular;

    p:not(:first-child) {
      margin-top: 2rem;
    }

    ul {
      list-style-type: disc;
      padding-left: 4rem;
      margin-top: 2rem;
    }

    img {
      display: block;
      margin: auto;
      max-width: 30rem;
      @include screen-xs {
        display: none;
      }
    }

    &Btn {
      text-align: center;
      margin-top: 3rem;
    }

    &Row {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }

    &Content {
      flex: 1 1;
      margin-left: 2rem;

      @include screen-xs {
        flex: 1 1 100%;
        margin: 0;
      }
    }
  }
}

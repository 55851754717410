@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";


.Search {
  height: 6rem;
  position: relative;

  &__input {
    @include font($font-s-sm, $line-height-1);
    transition: border-color .3s, color .3s;
    border-radius: 6rem;
    border: (1rem / 6) solid $color-grey-heather;
    color: $color-grey-albatross;
    height: 100%;
    outline: none;
    padding-left: 3rem;
    padding-right: 6rem;

    &:focus {
      border-color: $color-grey-albatross;
      color: $color-black; }

    @include screen-xs {
      width: 100%; } }

  &__button {
    color: $color-grey-heather;
    cursor: pointer;
    line-height: 6.5rem;
    position: absolute;
    right: 2rem; } }

@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/common-form";
@import "~theme/scaffolding/scaffolding";


.Form {
  &__field {
    position: relative;
    width: 100%;

    input,
    textarea {
      @include font($font-s-sm, $line-height-sm);
      width: 100%;
      border: 1px solid $color-border;
      background: $color-white;
      min-height: 7rem;
      padding: 0 2rem;
      outline: none;
      transition: border-color .3s;

      &:focus {
        border-color: $color-grey-heather-dark; }

      &.err {
        border-color: $color-red; }
      &.warn {
        border-color: $color-yellow-calm-dark; } }

    label {
      @include p-2; }

    &+& {
      margin-top: 3rem; }

    &+button {
      margin-top: 5rem; } }

  &__input {
    position: relative; }

  &__label {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 4px;

    &Infos {
      flex: 1 1;
      text-align: right;
      display: flex;
      flex-flow: column; } }

  &__fieldInfo {
    @include p-2;
    color: $color-grey-60;
    &.withLabel {
      padding-left: 1rem; } }

  &__fieldError {
    @include p-2;
    color: $color-red;

    &.withLabel {
      padding-left: 1rem; } }

  &__fieldWarning {
    @include font($font-s-xs-sm, $line-height-sm);
    color: $color-yellow-calm-dark;
    font-weight: $font-w-regular;
    justify-self: flex-end;

    &.withLabel {
      padding-left: 1rem; } }

  &__fieldIcon {
    position: absolute;
    color: $color-yellow-calm-dark;
    top: 1.2rem;
    right: 1.5rem; }

  &__select {
    @include select; }

  &__option {
    display: block;

    &.radio {
      text-transform: none; }

    &.report {
      display: inline-block;

      button {
        margin: 0 2rem;
        min-width: 20rem;
        width: auto;

        &:focus {
          outline-color: $color-primary; } }

      &.selected button {
        outline-color: $color-primary;
        color: $color-primary; } }

    input {
      position: absolute;
      opacity: 0;

      &:hover,
      &:focus {
        & ~ div {
          outline-color: $color-primary; } } }

    > div,
    > button {
      outline: 1px solid $color-border;
      border: none;
      background: $color-white;
      border-radius: 3px;
      cursor: pointer;
      padding: 2.5rem;
      position: relative;
      font-weight: $font-w-medium;
      margin: 0 1px; }

    &.selected {
      > div,
      > button {
        outline: 2px solid $color-header-blue;
        color: $color-header-blue;
        font-weight: $font-w-semi-bold;

        .radioMark {
          color: $color-header-blue; } } }

    * + & {
      margin-top: 3rem; }

    @include screen-xs {
      margin-left: 1px;
      margin-right: 1px; } }

  &__option.radio {
    @include font($font-s-0, $line-height-0); }


  &__option.selected & {
    &__radioMark {
      color: $color-header-blue; } }

  &__radioLabel {
    font-size: 18px;
    line-height: 1.5;
    margin-right: 6rem;
    font-weight: inherit; }

  &__radioMark {
    top: calc(50% - 2rem);
    font-size: 4rem;
    line-height: 1;
    position: absolute;
    right: 3rem;
    color: $color-border; }

  &__tooltip {
    position: absolute;
    right: 7.5rem;
    z-index: 999;

    &::before {
      content: '';
      position: absolute;
      right: -7px;
      top: 7px;
      width: 0;
      height: 0;
      border-width: 8px 0 8px 8px;
      border-color: transparent transparent transparent $color-secondary;
      border-style: solid; }

    &Content {
      @include font(13px, 1.4);
      border-radius: 6px;
      padding: 1rem 2rem;
      background-color: $color-secondary;
      color: $color-white; } } }

@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/common-form";


.Conversation {
  &__calendar {
    @include font($font-s-sm, $line-height-sm);
    position: relative;
    overflow: hidden;

    &Transition {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transition: transform .5s; }

    .bw {
      &-enter {
        transform: translate(-100%); }
      &-enter-active {
        transform: translate(0%); } }

    .bw-enter + .bw,
    .bw-enter + .fw {
      &-exit {
        transform: translate(0%); }
      &-exit-active {
        transform: translate(100%); } }

    .fw {
      &-enter {
        transform: translate(100%); }
      &-enter-active {
        transform: translate(0%); } }

    .fw-enter + .fw,
    .fw-enter + .bw {
      &-exit {
        transform: translate(0%); }
      &-exit-active {
        transform: translate(-100%); } }

    &Week {
      display: flex;
      background: $color-grey-imperceptible;
      border-radius: 5px 5px 0 0;
      width: 100%;

      &_placeholder {
        visibility: hidden; } }

    &Btn {
      background: none;
      border: none;
      outline: none;

      &:disabled {
        opacity: .5; } }

    &Day {
      flex: 1 1 calc(100% / 7);
      text-align: center;
      padding: 1rem;
      position: relative;
      transition: all .3s;
      cursor: pointer;

      &:hover {
        background: $color-grey-imperceptible; }

      &.active {
        background: $color-primary;
        color: $color-white;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px; }

      &.disabled {
        cursor: auto;
        opacity: .4;
        pointer-events: none; } }

    &Day.active &Count {
      background: $color-white;
      color: $color-primary; }

    &Count {
      @include font($font-s-xs, 3rem);
      font-weight: $font-w-semi-bold;
      color: $color-white;
      background: $color-blue-playful;
      box-shadow: 0 2px 5px 0 rgba($color-black, .2);
      padding: 0 1rem;
      border-radius: 3rem;
      display: inline-block;
      margin-top: 1rem;
      min-width: 3rem; }

    &Weekday {
      @include label; }

    &Date {
      @include font($font-s-xs, $line-height-xs);
      font-weight: $font-w-regular; }

    &Times > div:hover &Time {
      background: $color-grey-imperceptible;

      &.greyed {
        background: none; } }

    &Time {
      @include label;
      padding: 1rem;
      text-align: center;
      cursor: pointer;
      position: relative;
      transition: all .3s;
      display: inline-block;
      width: 50%;

      &:only-child {
        width: 100%; }

      &.greyed {
        color: $color-grey-heather; }

      &.active {
        background: rgba($color-primary, .2);

        .fa-check {
          position: absolute;
          right: 2rem;
          top: 2rem; } }

      &.disabled {
        display: none; } }

    &Time + &Time {
      border-left: 1px dashed $color-black; }

    &Timezones {
      @include font($font-s-sm, $line-height-0);
      font-weight: $font-w-semi-bold;
      background: $color-primary;
      color: $color-white;
      text-align: center;
      display: flex;

      &:first-child &_item {
        padding-top: 1rem; }

      &_item {
        display: inline-block;
        width: 50%;

        &.small {
          @include font($font-s-xs, $line-height-0); }

        .Avatar {
          display: inline-block;
          vertical-align: middle;
          margin-right: 2rem; } }

      &_item + &_item {
        border-left: 1px dashed $color-white; } }

    &Select {
      @include select;
      line-height: $line-height-sm;

      &__control {
        background: none !important;
        border: none !important;
        cursor: pointer !important;
        min-height: $font-s-0 !important; }

      &__value-container {
        color: $color-white !important;

        &>div {
          flex: 1 1; } }

      &__single-value {
        left: 50%;
        transform: translate(calc(-50% + 3rem), -50%) !important; }

      &__dropdown-indicator {
        padding: 0 !important;
        padding-right: 2rem !important; }

      &__dropdown-indicator,
      &__single-value,
      &__input {
        @include font($font-s-xs, $line-height-xs);
        font-weight: $font-w-semi-bold;
        color: $color-white !important; }

      &__option {
        font-size: $font-s-xs !important;
        line-height: $line-height-sm !important;
        font-weight: $font-w-semi-bold;
        &:hover {
          background: $color-grey-imperceptible !important; }

        &--is-selected {
          background: $color-primary !important;
          color: $color-white !important;

          &:hover {
            background: $color-primary !important; } } }

      &__menu-list {
        color: $color-black !important; } } } }

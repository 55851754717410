@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-table";
@import "~theme/common-form";

.NewGroup {
  p {
    @include text-secondary;
    font-weight: $font-w-light;
    margin-bottom: 2rem; }

  h2 {
    @include subtitle-black; }

  textarea {
    @include input;
    padding: 1rem 2rem;
    min-height: 20rem;
    max-width: 100rem;
    display: block;
    resize: none; }

  label {
    @include label; }

  &__title {
    @include title-blue-caps;
    margin: 0; }

  &__checkbox {
    display: flex;
    align-items: center;

    label {
      margin-left: 1rem; } }

  &__table {
    @include colored-table;

    .heading {
      transition: all .3s;

      &.greyed {
        background: $color-grey-heather-dark; }

      &>div {
        line-height: 4.5rem; } }

    .hidden {
      display: none; }

    .row {
      align-items: center;

      &.disabled {
        opacity: .5; } }

    &All {
      flex: 1 1;
      text-align: right;

      button {
        @include label;
        outline: none;
        padding: 0;
        background: none;
        border: 0;
        color: $color-white;
        text-decoration: underline; } } } }

@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-table";
@import "~theme/common-form";

.MatchingGroups {
  @include table;

  p {
    @include text-secondary;
    font-weight: $font-w-light; }

  h2 {
    @include subtitle-black; }

  tr.active {
    background: rgba($color-border, .5); }

  tr:hover &__actions {
    opacity: 1; }

  &__title {
    @include title-blue-caps;
    margin: 0; }

  &__label {
    @include label;
    margin-top: 1rem; }

  &__heading {
    &Filter {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-flow: row wrap; }

    &Btn {
      margin-left: 3rem; }

    button {
      @include screen-xs {
        margin-top: 2rem;
        width: 100%; } } }

  &__select {
    @include select;
    width: 60rem;
    display: inline-block;

    @include screen-xs {
      width: 100%; }

    &.cohort {
      @include screen-xs {
        width: 70%; } } }

  &__avatar {
    display: inline-block;
    vertical-align: middle; }

  &__subtitle {
    @include subtitle-black;
    margin-top: 5rem;
    padding-top: 3rem;
    border-top: 1px solid $color-primary; }

  &__conversationName {
    h3 {
      @include text-bold; }

    span {
      @include text-grey;
      text-transform: none; } }

  &__row {
    display: flex;
    flex-flow: row wrap;
    margin: -3rem; }

  &__workshopForm {
    display: flex;
    align-items: center;

    input {
      @include input;
      max-width: 60rem;
      margin-right: 3rem; } }

  &__actions {
    width: 20rem;
    text-align: right;
    opacity: .2;
    transition: .3s;

    @include screen-xs {
      width: unset;
      text-align: center; } }

  &__modalInfo {
    font-weight: $font-w-medium;
    text-align: center;
    margin: 2rem 0;
    border: 1px solid $color-red;
    background: rgba($color-red, .05);
    padding: 1rem 2rem; } }

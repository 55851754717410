@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";

.AssessmentChart {
  text {
    font-family: $font-primary-new;
    font-weight: $font-w-bold;
    fill: $color-pink; }

  &__value {
    font-size: 120pt; }

  &__percent {
    font-size: $font-s-3; }

  path {
    fill: mix($color-white, $color-pink, 50%);
    &.filled {
      fill: $color-pink; } } }

@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/scaffolding/scaffolding";

.TimeoutCountdown {
  min-width: 70rem;

  @include screen-xs {
    min-width: 0; }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    p {
      @include p-new; }

    i {
      font-size: 2em;
      color: $color-warning-new;
      @include screen-xs {
        font-size: 1.5em; } } }

  &__buttons {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    gap: 16px; } }

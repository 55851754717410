@import "~theme/responsive/responsive";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/fonts";
@import "~theme/type";

.Toast {
  @include font($font-s-sm, $line-height-sm);
  font-weight: $font-w-medium;
  position: fixed;
  left: 0;
  right: 0;
  padding: 3rem;
  cursor: pointer;
  transition: .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;

  &.bottom {
    bottom: 0;
    transform: translateY(100%);

    &.visible {
      transform: translateY(0); } }

  &.top {
    top: 0;
    transform: translateY(-100%);

    &.visible {
      transform: translateY(0); } }

  &.visible {
    opacity: 1;
    visibility: visible;

    &:hover {
      opacity: 1; } }

  &.noPadding {
    padding: 0; }

  &.error {
    background: $color-error;
    color: $color-white; }

  &.warning {
    background: $color-warning;
    color: $color-white; }

  &.info {
    background: $color-info;
    color: $color-white; }

  &.success {
    background: $color-success;
    color: $color-white; }

  &__close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: inherit;

    &:hover {
      background: rgba($color-white, .2); } }

  &__title {
    @include subtitle;
    display: block;
    margin: 0;
    color: $color-white;

    .far {
      margin-right: 2rem; } } }

@import "~theme/responsive/responsive";
@import "~theme/scaffolding/scaffolding";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/fonts";
@import "~theme/btn/btn";
@import "~theme/grid/grid";

.Dashboard {
  &.page {
    background: $color-white; }

  .container-1040 {
    padding-top: 32px;
    padding-bottom: 24px;
    position: relative; }

  .PeerNetwork {
    margin-bottom: 32px; }

  &__peerCoaching {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    gap: 16px;
    margin-top: 18px;
    margin-bottom: 18px;

    &Conversations {
      flex: 1 1; } }

  &__paused {
    @include p-2;
    padding: 3rem;
    background: $color-secondary-600;
    color: $color-white;
    display: flex;
    align-items: center;

    i {
      font-size: 1.5em;
      margin-right: 3rem; }

    a {
      color: inherit;
      text-decoration: underline; } }

  &__convos {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    gap: 16px;
    margin-top: 16px;
    margin-bottom: 32px;

    .SetCardNew {
      flex: 2 2;
      min-width: 300px; }
    .NameCard {
      flex: 1 1;
      min-width: 300px; }
    .NetworkGrowing {
      flex: 1 1 100%; }
    .GettingStarted {
      flex: 2 2; } }

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(55rem, 1fr));
    gap: 2rem; }

  &__card {
    display: flex; }

  &__pauseToast {
    &.Toast {
      background: $color-pink; }

    &Content {
      display: flex;
      align-items: center;
      font-weight: $font-w-medium;
      i {
        margin-right: 3rem;
        font-size: 1.5em; } } } }

.NetworkGrowing {
  img {
    max-width: 288px;
    margin: auto; }

  .section-intro {
    margin: 16px 0; }

  .p-new + .p-new {
    margin-top: 8px; } }

.GettingStarted {
  &__content {
    p {
      @include p-new; }

    p + p {
      margin-top: 8px; } }

  ul {
    margin: 8px 0; }

  li {
    @include p-2;
    margin: 4px 0;
    display: flex;

    &:before {
      content: "done";
      font-family: "Material Icons";
      margin-right: 12px; } } }

@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/scaffolding/scaffolding";

.SetCardNew {
  display: grid;
  gap: 8px;

  .ReactCollapse--collapse {
    transition: height 400ms; }

  &__header {
    display: flex;
    align-items: center;

    .section-intro {
      flex: 1 1; }

    .btn-icon {
      margin-right: 16px; } }

  &__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height .5s;

    &.opened {
      max-height: 900px; } }

  &__row {
    display: flex;
    align-items: flex-start;

    img {
      max-width: 230px; } }

  &__tip.tippy-box {
    border-radius: 6px;
    background-color: $color-secondary;
    transform: translateY(-4px);

    .tippy-content {
      padding: 1rem 2rem;
      font-weight: $font-w-regular;

      * {
        font-weight: inherit; } }

    .tippy-arrow {
      color: $color-secondary; } }

  &__progress {
    border-bottom: 1px solid $color-grey-30;
    margin-bottom: 32px;
    padding-top: 16px;

    &Dots {
      display: inline-flex;
      gap: 17px; }

    &-item {
      background: none;
      border: none;
      outline: none;
      position: relative;
      padding-bottom: 12.67px;
      flex: 0 0 27px;

      &.active:after {
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: $color-primary-500; } }

    &-item:focus:not(:focus-visible) &-dot {
      box-shadow: none; }
    &-item:focus &-dot {
      box-shadow: 0 0 0 2px $color-focus; }

    &-dot {
      @include font(12px, 14px);
      background: $color-grey-30;
      font-weight: $font-w-bold;
      color: $color-white;
      width: 27px;
      height: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &.finished {
        background: $color-grey-90; }

      &.scheduled:not(.active) {
        border: 2px solid $color-grey-90;
        color: $color-grey-90;
        background: $color-white; }

      &.active {
        background: $color-primary-500;
        color: $color-white;
        border: none; } } }

  &__details {
    display: flex;
    flex-flow: column;
    gap: 16px; }

  &__buttons {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 32px;

    .btnLink {
      text-transform: none;
      line-height: normal;
      height: auto; }

    .spacer {
      flex: 1 1; } }

  &__schedule {
    display: flex;
    align-items: center;
    gap: 5px;

    .btn-icon {
      font-size: 18px;
      width: auto;
      margin-left: 5px;
      padding: 0;

      span {
        @include caption;
        font-size: 14px;
        text-transform: none;
        color: $color-primary-500;
        margin-left: 5px; } } }

  &__toast.Toast {
    background: $color-pink;
    display: flex;
    align-items: center;

    .fa-calendar,
    .fa-calendar-times {
      font-size: 1.5em;
      margin-right: 3rem; } }

  &__toastContent {
    font-weight: $font-w-medium;
    flex: 1 1; }

  &__scheduler {
    max-width: 120rem;

    &.small {
      max-width: 80rem; }

    .label {
      @include font($font-s-sm, $line-height-sm);
      font-weight: $font-w-regular;
      opacity: .7;
      margin-bottom: 1rem;
      display: block; }

    &Description {
      @include font($font-s-sm, $line-height-sm);
      display: flex;
      align-items: center;
      padding-bottom: 3rem;

      @include screen-xs {
        display: block; }

      & + & {
        padding-bottom: 0; }

      h3 {
        @include font($font-s-1, $line-height-1);
        font-weight: $font-w-bold;
        margin-bottom: 2rem; }

      p {
        font-weight: $font-w-regular;
        opacity: .7; }

      p + p {
        margin-top: 2rem; }

      &-img {
        max-width: 20rem;
        flex: 0 0;
        margin-right: 5rem;
        display: block;

        @include screen-xs {
          max-width: 15rem;
          margin: 0;
          margin-bottom: 1rem; } } }

    &Cancellation {
      padding-right: 6rem;
      font-weight: $font-w-semi-bold; }

    &Btns {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      margin: -1rem;
      margin-top: 2rem;

      button {
        margin: 1rem; }

      button:last-child:not(:first-child) {
        margin-left: 2rem; } } } }

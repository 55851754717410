@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/scaffolding/scaffolding";


.Footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: $Footer__h;
  color: $color-grey-90;

  @include screen-xs {
    position: static; }

  &.static {
    position: static; }

  > .container {
    display: flex;
    align-items: center;
    height: 100%; }

  &__freshdesk {
    position: fixed;
    right: 1px;
    bottom: 7px;
    width: 127px;
    height: 36px;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    border-top-right-radius: 5px;
    border: none;

    &:focus {
      box-shadow: 0 0 2px 3px $color-focus; }

    @media only print {
      display: none;
      visibility: hidden; } }

  &__copyright {
    @include caption;
    font-weight: $font-w-regular;
    flex: 1 1;
    text-align: right; }

  &__links &__contact {
    @include caption;
    flex: 1 1 100%;

    a {
      display: inline-block;
      margin: 0; } }

  &__links {
    @include caption;
    flex: 1 1 100%;
    gap: 4px 24px;
    display: flex;
    flex-flow: row wrap;
    color: $color-grey-90;
    font-weight: $font-w-regular;

    a {
      color: inherit;
      font-weight: inherit;

      &:hover,
      &:focus,
      &.focus-visible:focus:not(:focus-visible) {
        color: $color-primary;
        font-weight: $font-w-semi-bold; }
      &:focus:not(:focus-visible) {
        color: $color-grey-90;
        font-weight: $font-w-regular; }

      &:before {
        display: block;
        content: attr(title);
        font-weight: $font-w-semi-bold;
        height: 0;
        overflow: hidden;
        visibility: hidden; } } } }

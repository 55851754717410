// Grid system: generation and mixins.
// Borrowed from bootstrap-sass and slightly adapted.

@import "~theme/clearfix";
@import "~theme/type";
@import "~theme/colors";
@import "~theme/responsive/responsive";


// Number of columns in the grid.
$grid-columns: 12;
// Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 10rem;

// Container sizes: maximum width of `.container` for different screen sizes.
// Small screen / tablet (`$screen-sm-min` and up)
$container-sm: 118rem + $grid-gutter-width;
// Medium screen / desktop (`$screen-md-min` and up)
$container-md: 148rem + $grid-gutter-width;
// Large screen / wide desktop (`$screen-lg-min` and up)
$container-lg: 188rem + $grid-gutter-width;

@mixin card {
  padding: 24px;
  border: 1px solid $color-grey-30;
  background: $color-white;
  border-radius: 8px;

  @include screen-xs {
    padding: 12px; } }

@mixin card-shadow {
  @include card;
  box-shadow: 0 10px 15px 0 rgba($color-black, .1); }

@mixin card-color($color) {
  @include card;
  background: $color;
  border: none; }

@mixin card-heading {
  display: flex;
  align-items: center;
  justify-content: space-between; }

@mixin divider {
  &.horizontal {
    display: block;
    width: 100%;
    height: 1px;
    background: $color-grey-30; }

  &.vertical {
    display: block;
    width: 100%;
    height: 1px;
    background: $color-grey-30; } }

@mixin tip {
  @include font(12px, 18px);
  padding: 2.5px 5px;
  letter-spacing: .02em;
  background: $color-grey-90;
  border-radius: 4px;
  color: $color-white;
  font-weight: $font-w-semi-bold; }

// Grid mixins

// Centered container element
@mixin container-fluid($gutter: $grid-gutter-width) {
  margin-right: auto;
  margin-left: auto;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
  max-width: 1400px;

  @include screen-xs {
    padding-left: ($gutter / 4);
    padding-right: ($gutter / 4); } }

@mixin container($gutter: $grid-gutter-width) {
  @include container-fluid($gutter); }

// Creates a wrapper for a series of columns
@mixin row($gutter: $grid-gutter-width) {
  @include clearfix;
  margin-left:  ($gutter / -2);
  margin-right: ($gutter / -2); }

// Reusable column mixins
@mixin col-general($gutter: $grid-gutter-width) {
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
  position: relative; }

@mixin col-float {
  float: left; }

@mixin col-width($columns) {
  width: percentage(($columns / $grid-columns)); }

@mixin col-offset($columns) {
  margin-left: percentage(($columns / $grid-columns)); }

@mixin col-push($columns) {
  left: percentage(($columns / $grid-columns)); }

@mixin col-pull($columns) {
  right: percentage(($columns / $grid-columns)); }

// Generate the extra small columns
@mixin col-xs($columns, $gutter: $grid-gutter-width) {
  @include col-general($gutter);
  @include col-width($columns);
  @include col-float; }

@mixin col-xs-offset($columns) {
  @include col-offset($columns); }

@mixin col-xs-push($columns) {
  @include col-push($columns); }

@mixin col-xs-pull($columns) {
  @include col-pull($columns); }

// Generate the small columns
@mixin col-sm($columns, $gutter: $grid-gutter-width) {
  @include col-general($gutter);
  @include screen-sm-plus {
    @include col-width($columns);
    @include col-float; } }

@mixin col-sm-offset($columns) {
  @include screen-sm-plus {
    @include col-offset($columns); } }

@mixin col-sm-push($columns) {
  @include screen-sm-plus {
    @include col-push($columns); } }

@mixin col-sm-pull($columns) {
  @include screen-sm-plus {
    @include col-pull($columns); } }

// Generate the medium columns
@mixin col-md($columns, $gutter: $grid-gutter-width) {
  @include col-general($gutter);
  @include screen-md-plus {
    @include col-width($columns);
    @include col-float; } }

@mixin col-md-offset($columns) {
  @include screen-md-plus {
    @include col-offset($columns); } }

@mixin col-md-push($columns) {
  @include screen-md-plus {
    @include col-push($columns); } }

@mixin col-md-pull($columns) {
  @include screen-md-plus {
    @include col-pull($columns); } }

// Generate the large columns
@mixin col-lg($columns, $gutter: $grid-gutter-width) {
  @include col-general($gutter);
  @include screen-lg {
    @include col-width($columns);
    @include col-float; } }

@mixin col-lg-offset($columns) {
  @include screen-lg {
    @include col-offset($columns); } }

@mixin col-lg-push($columns) {
  @include screen-lg {
    @include col-push($columns); } }

@mixin col-lg-pull($columns) {
  @include screen-lg {
    @include col-pull($columns); } }


// Grid generaion

@function col-classes($class) {
  $list: ();
  @for $i from 1 through $grid-columns {
    $list: append($list, ".col-#{$class}-#{$i}", comma); }
  @return $list; }

@mixin make-grid-columns {
  $list: ();
  @each $class in (xs, sm, md, lg) {
    $list: join($list, col-classes($class), comma); }

  #{$list} {
    @include col-general; } }

@mixin float-grid-columns($class) {
  #{col-classes($class)} {
    @include col-float; } }


@mixin calc-grid-column($index, $class, $type) {
  @if ($type == width) and ($index > 0) {
    .col-#{$class}-#{$index} {
      @include col-width($index); } }

  @if ($type == push) and ($index > 0) {
    .col-#{$class}-push-#{$index} {
      @include col-push($index); } }

  @if ($type == push) and ($index == 0) {
    .col-#{$class}-push-0 {
      left: auto; } }

  @if ($type == pull) and ($index > 0) {
    .col-#{$class}-pull-#{$index} {
      @include col-pull($index); } }

  @if ($type == pull) and ($index == 0) {
    .col-#{$class}-pull-0 {
      right: auto; } }

  @if ($type == offset) {
    .col-#{$class}-offset-#{$index} {
      @include col-offset($index); } } }


@mixin loop-grid-columns($columns, $class, $type) {
  @for $i from 0 through $columns {
    @include calc-grid-column($i, $class, $type); } }


// Create grid for specific class
@mixin make-grid($class) {
  @include float-grid-columns($class);
  @include loop-grid-columns($grid-columns, $class, width);
  @include loop-grid-columns($grid-columns, $class, pull);
  @include loop-grid-columns($grid-columns, $class, push);
  @include loop-grid-columns($grid-columns, $class, offset); }

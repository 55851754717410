@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";

.AssessmentSlider {
  &__message {
    @include font($font-s-0, $line-height-0);
    margin-bottom: 4rem;
    text-align: center;
    font-weight: $font-w-semi-bold; }

  &__sliderContainer {
    padding-left: 5rem;
    padding-right: 5rem;

    @include screen-xs {
      padding-left: 13rem;
      padding-right: 13rem; } }

  &__labelContainer {
    position: relative;
    height: 8rem;

    @include screen-sm-plus {
      height: 10rem; } }

  &__label {
    position: absolute;
    text-align: center;
    width: 0;
    bottom: 2rem; }

  &__labelText {
    font-size: $font-s-xs-sm;
    font-weight: $font-w-semi-bold;
    left: -8em;
    width: 16em;
    text-transform: uppercase;
    color: $color-white;
    position: relative;
    display: inline-block;

    @include screen-sm-plus {
      font-size: $font-s-sm; }

    &.flip {
      left: unset;
      right: -6.5em; }

    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: $color-header-blue;
      border-width: 2rem;
      margin-left: -2rem; }

    span {
      background: $color-header-blue;
      padding: 1rem 3rem;
      border-radius: 5px;
      max-width: 8rem; } }

  &__notchContainer {
    border-radius: .5rem;
    display: flex;
    align-items: center;
    margin-top: 4rem;
    background: mix($color-border, $color-header-blue, 70%);
    border-radius: 2rem;
    height: 2rem;

    @include screen-sm-plus {
      height: 3rem; } }

  &__notch {
    flex-grow: 1;
    float: left;
    height: 4rem;
    position: relative;
    overflow: hidden;
    border-left: 1px solid mix($color-border, $color-header-blue, 70%);

    &.flip {
      border-right: 1px solid mix($color-border, $color-header-blue, 70%);
      border-left: 0; }

    @include screen-sm-plus {
      height: 5rem; }

    &:nth-of-type(1) {
      border-color: transparent; } }

  &__slider {
    margin-left: -3rem;
    position: relative;
    height: 2rem;
    width: calc(100% + 6rem);

    @include screen-sm-plus {
      margin-left: -4rem;
      width: calc(100% + 8rem); } }

  &__thumb {
    background-color: rgba($color-white, .7);
    box-shadow: 0 0 20px 2px rgba($color-black, .1);
    color: transparent; // to remove weird border when active
    cursor: pointer;
    border-radius: 50%;
    height: 6rem;
    margin-top: -5rem;
    outline: none;
    position: absolute;
    width: 6rem;
    transition: all .2s;

    @include screen-sm-plus {
      width: 8rem;
      height: 8rem;
      margin-top: -6rem; }

    &:focus {
      box-shadow: 0 0 10px 2px rgba($color-black, .5);
      background-color: rgba($color-white, .9); }

    &.flip {
      transform: translateX(50%); }

    &:after {
      content: '';
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: $color-header-blue;

      @include screen-sm-plus {
        width: 6rem;
        height: 6rem; } } } }

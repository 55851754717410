@import "~theme/colors";
@import "~theme/scale";
@import "~theme/fonts";
@import "~theme/type";

@mixin input {
  @include font($font-s-sm, $line-height-sm);
  width: 100%;
  outline: 1px solid $color-border;
  background: $color-white;
  min-height: 7rem;
  padding: 0 2rem;
  border: none;
  transition: outline .3s;

  &:focus,
  &:active {
    outline: 2px solid $color-primary; }

  &.err {
    outline: 2px solid $color-red; } }

@mixin input-new {
  @include font(rem-size(16px), rem-size(24px));
  width: 100%;
  border: 1px solid $color-border-new;
  border-radius: 4px;
  background: $color-white;
  color: $color-text-grey;
  padding: rem-size(8px) rem-size(16px);
  outline: none;
  transition: border .3s;

  &:focus,
  &:active {
    border-color: $color-primary; }

  &:disabled {
    opacity: .5; }

  &.err {
    border-color: $color-error-new; } }

@mixin select-new {
  font-weight: $font-w-regular;

  &.react-select {
    @include font(rem-size(16px), rem-size(24px));
    font-weight: $font-w-regular;

    &__control {
      border: 1px solid $color-border-new !important;
      background: $color-white !important;
      min-height: 7rem !important;
      border-radius: 4px !important;

      &--is-focused {
        box-shadow: none !important;
        border-color: $color-primary !important; } }

    &__placeholder {
      color: $color-grey-60 !important; }

    &__indicator-separator {
      display: none; }

    &__indicators {
      flex: 0 0 7rem; }

    &__option {
      font-weight: inherit;
      cursor: pointer;

      &--is-selected {
        background-color: $color-primary !important;
        color: $color-white; }

      &--is-focused {
        background-color: $color-grey-30 !important; } }

    &__menu {
      z-index: 1000 !important;
      border-radius: 0 !important; }

    &__group {
      &:not(:first-child) {
        border-top: 2px solid $color-border; }

      &-heading {
        font-size: $font-s-xs-sm !important;
        line-height: $line-height-sm !important;
        font-weight: $font-w-semi-bold !important;
        text-transform: uppercase !important;
        color: $color-black !important; } }

    &__multi-value__remove {
      cursor: pointer; }

    &.err &__control {
      border-color: $color-red !important; }

    input {
      min-height: unset; } } }

@mixin select {
  @include font($font-s-sm, $line-height-sm);

  &__control {
    border: 1px solid $color-border !important;
    background: $color-white !important;
    min-height: 7rem !important;
    border-radius: 0 !important;

    &--is-focused {
      box-shadow: none !important;
      border-color: $color-grey-heather-dark !important; } }

  &__placeholder {
    color: $color-grey-90 !important; }

  &__indicator-separator {
    display: none; }

  &__indicators {
    flex: 0 0 7rem; }

  &__option {
    font-weight: inherit;
    cursor: pointer;

    &--is-selected {
      background-color: $color-header-blue !important; }

    &--is-focused {
      background-color: $color-grey-light-med !important; } }

  &__menu {
    z-index: 1000 !important;
    border-radius: 0 !important; }

  &__group {
    &:not(:first-child) {
      border-top: 2px solid $color-border; }

    &-heading {
      font-size: $font-s-xs-sm !important;
      line-height: $line-height-sm !important;
      font-weight: $font-w-semi-bold !important;
      text-transform: uppercase !important;
      color: $color-black !important; } }

  &__multi-value__remove {
    cursor: pointer; }

  &.err &__control {
    border-color: $color-red !important; }

  input {
    min-height: unset; } }

@mixin slider {
  $slider-h: 3rem;
  $slider-h-mobile: 2rem;

  .rc-slider {
    height: $slider-h;
    margin: $slider-h 0;
    margin-top: 8rem;
    padding: 1rem 0;

    @include screen-xs {
      height: $slider-h-mobile;
      margin: $slider-h-mobile 0;
      margin-top: 6rem; }

    &-track {
      background-color: rgba($color-primary, .5);
      border-radius: $slider-h;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      @include screen-xs {
        border-radius: $slider-h-mobile; } }

    &-rail {
      border-radius: $slider-h;
      background-color: $color-border;

      @include screen-xs {
        border-radius: $slider-h-mobile; } }

    &-step,
    &-track,
    &-rail {
      height: $slider-h;

      @include screen-xs {
        height: $slider-h-mobile; } }

    &-handle {
      border: 2px solid rgba($color-white, .9);
      background: $color-primary;
      width: calc(#{$slider-h} * 1.7);
      height: calc(#{$slider-h} * 1.7);
      margin-top: -6px;

      @include screen-xs {
        width: calc(#{$slider-h-mobile} * 1.7);
        height: calc(#{$slider-h-mobile} * 1.7);
        margin-top: -3px; }

      &:hover,
      &:active,
      &:focus {
        box-shadow: 0 0 5rem 1px $color-border;
        border-color: rgba($color-white, .9); } }

    &-tooltip {
      z-index: 9;
      &-arrow {
        border-top-color: $color-primary !important; }

      &-inner {
        @include label;
        @include font($font-s-xs, 5rem);
        background-color: $color-primary;
        padding: 0 2rem;
        height: 5rem;
        border-radius: 5px;

        @include screen-xs {
          line-height: 4rem;
          height: 4rem;
          padding: 0 1rem; } } } } }

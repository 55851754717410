@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";

.DeprecatedSite {
  @include font($font-s-sm, $line-height-sm);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $color-warning;
  padding: 2rem;
  color: $color-white;
  font-weight: $font-w-regular;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1000;
  transition: bottom 1s, visibility 0s 1.5s;

  @media only print {
    visibility: hidden; }

  &.hidden {
    bottom: -100%;
    visibility: hidden; }
  i {
    margin-right: 3rem;
    font-size: 1.5em; } }

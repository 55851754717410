@import "~theme/btn/btn";
@import "~theme/colors";
@import "~theme/type";
@import "~theme/fonts";

@mixin screen {
  @media only screen {
    @content; } }

@mixin driver-colors {
  &.who {
    color: $color-driver-who; }
  &.why {
    color: $color-driver-why; }
  &.how {
    color: $color-driver-how; } }

@import "../PrintPage/PrintPage";


@mixin driver-background {
  &.who {
    background: $color-driver-who; }
  &.why {
    background: $color-driver-why; }
  &.how {
    background: $color-driver-how; } }

.DriversAtGlance {
  font-size: 12pt;

  @include screen {
    background: $color-grey-heather;
    padding-bottom: .5in;
    padding-top: .5in;
    min-width: 11in; }

  &__links {
    text-align: center; }

  &__pdfLink {
    display: none;

    @include screen {
      @include btn(primary, solid, $color-pink);
      display: inline-block;
      margin-bottom: .5in;
      margin-right: .2in;
      min-width: 50rem; } }

  &__header {
    display: table;
    height: 3.5in;
    background: $color-driver-who;
    color: $color-white;
    width: 100%;

    &Container {
      display: table-cell;
      vertical-align: middle;
      text-align: center;

      &Heading {
        @include font(42pt, 48pt);
        font-weight: $font-w-bold; }

      &Subheading {
        @include font(22pt, 28pt);
        font-weight: $font-w-bold; } } }

  &__footer {
    display: block;

    &Number {
      padding-right: 5pt; }

    &.right {
      text-align: right; }

    &.right &Number {
      padding-left: 5pt; } }

  &__image {
    width: 100%; }

  &__section {
    display: flex;
    min-height: 1.8in; }

  &__icon {
    width: 2in;
    height: 2in;
    position: absolute;
    left: -10pt;
    top: 0;
    z-index: 999; }

  &__driver {
    position: relative;
    color: $color-white;
    top: 0.45in;
    max-height: 1.3in;
    margin-left: .8in;
    width: 100%;
    padding: 5pt 5pt 5pt 1.2in;
    @include driver-background;

    &Title {
      @include font(14pt, 20pt);
      font-weight: $font-w-semi-bold;
      text-transform: uppercase; }

    &Core {
      font-family: $font-secondary-new; } }

  &__description {
    text-align: center;

    &Quote {
      font-weight: $font-w-semi-bold;
      font-style: italic;
      margin: 10pt 0; }

    &Explication {
      @include font(12pt, 15pt);
      font-family: $font-secondary-new; } }

  &__columns {
    margin: 10pt 0;

    &Column {
      display: inline-block;
      width: 33.33%;
      vertical-align: text-top;
      padding-bottom: 10pt;

      & ~ & {
        padding-left: 10pt; }

      p {
        text-transform: uppercase;
        font-weight: $font-w-semi-bold; } } }

  &__listItem {
    display: block;
    text-align: left;
    line-height: 13pt;

    &:before {
      padding-right: 5pt;
      display: inline-block; }

    &_wide {
      padding-left: 10pt;
      margin: 10pt 0; }

    &_bullet {
      &:before {
        @include font(12pt, 12pt);
        content: "•"; } }

    &Content {
      @include font(10pt, 12pt); } }

  &__page {
    position: relative;

    &Keywords {
      @include driver-colors;
      @include font(12pt, 18pt);
      font-weight: $font-w-bold;
      text-align: center;
      position: absolute;
      bottom: .4in;
      padding: 5pt 10pt;
      @include driver-background; }

    &Keyword {
      color: $color-white;
      display: inline-block;

      &:not(:last-child):after {
        content: ",";
        margin-right: .1in; } } } }

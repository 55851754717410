@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-table";
@import "~theme/common-form";

.Toggler {
  display: inline-block;
  width: 48px;
  height: 22px;
  background: $color-grey-30;
  border: none;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: all .3s;
  box-shadow: inset 0px 2px 4px rgba($color-black, 0.06);

  &__icon {
    color: $color-white;
    position: absolute;
    font-size: 14px;
    top: 4px;

    &.fa-check {
      left: 8px; }

    &.fa-times {
      right: 9px; } }

  &__dot {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $color-white;
    position: absolute;
    top: 1px;
    left: 1px;
    box-shadow: 0px 1px 2px rgba($color-black, 0.06), 0px 1px 3px rgba($color-black, 0.1);
    border: 0.5px solid $color-grey-15;
    transition: all .3s; }

  &.disabled {
    opacity: .5;
    cursor: auto; }

  &.on {
    background: $color-primary-500;
    box-shadow: inset 0px 2px 4px rgba($color-black, 0.06); }

  &.on &__dot {
    left: calc(48px - 20px - 1px); } }

@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-form";

.Questions {
  &__carousel {
    display: flex;
    height: 32rem;

    @include screen-xs {
      flex-flow: column;
      height: 35rem;
    }

    &Info {
      @include title-description;
      text-align: center;
      display: block;
      margin-top: 2rem;
    }

    &Box {
      background: rgba($color-primary, .06);
      border-radius: 6px;
      height: 100%;
      flex: 1 1;
      overflow: hidden;
      position: relative;

      &.top {
        border-top-left-radius: 0
      }
      &.bottom {
        border-bottom-left-radius: 0
      }
    }

    &Content {
      height: 100%;
      display: flex;
      flex-flow: column;
      padding: 2rem;

      .Markdown {
        flex: 1 1;
        min-height: 0;
        overflow: auto;
        p {
          font-weight: $font-w-regular
        }
      }
    }

    &Transition {
      height: 100%;

      .carousel-enter-active,
      .carousel-exit-active {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: transform .4s;
        overflow: hidden;
      }

      &.next {
        .carousel-enter {
          transform: translateY(100%);
          @include screen-xs {
            transform: translateX(100%);
          }
          &.carousel-enter-active {
            transform: translate(0, 0);
          }
        }
        .carousel-exit {
          transform: translate(0, 0);
          &.carousel-exit-active {
            transform: translateY(-100%);
            @include screen-xs {
              transform: translateX(-100%);
            }
          }
        }
      }

      &.prev {
        .carousel-enter {
          transform: translateY(-100%);
          @include screen-xs {
            transform: translateX(-100%);
          }
          &.carousel-enter-active {
            transform: translate(0, 0);
          }
        }
        .carousel-exit {
          transform: translate(0, 0);
          &.carousel-exit-active {
            transform: translateY(100%);
            @include screen-xs {
              transform: translateX(100%);
            }
          }
        }
      }
    }

    &Title {
      @include font($font-s-sm, $line-height-xs);
      font-weight: $font-w-semi-bold;
      color: $color-secondary;
      margin-bottom: 1rem;

      &.pink {
        color: $color-secondary;
      }
      &.green {
        color: $color-green;
      }
    }

    &Controls {
      min-width: 12rem;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);

      @include screen-xs {
        display: flex;
        justify-content: flex-start;
      }

      &Item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 1.5rem;
        padding-top: 2rem;
        color: $color-grey-albatross;
        cursor: pointer;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-width: 0;
        background: none;

        &:focus {
          outline: 2px solid $color-focus;
        }
        &:focus:not(:focus-visible) {
          outline: none;
        }

        @include screen-xs {
          display: flex;
          flex-flow: row;
          align-items: center;
          width: auto;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          border-bottom-left-radius: 0;
        }

        &.active {
          color: $color-secondary;
          background: rgba($color-primary, .06);

          &.pink {
            color: $color-secondary;
          }
          &.green {
            color: $color-green;
          }
        }

        span {
          @include font($font-s-xs, $line-height-xs);
          font-weight: $font-w-semi-bold;

          @include screen-xs {
            margin-left: 1.5rem
          }
        }
      }
    }

    &Rig {
      display: flex;
      margin: auto 0;

      img {
        max-width: 8.5rem;
        display: block;
        margin: auto;
        @include screen-xs {
          max-width: 7rem;
        }
      }

      &Item {
        flex: 1 1;
        text-align: center;
      }

      &Label {
        @include font(2.2rem, $line-height-xs);
        text-transform: uppercase;
      }

      &Value {
        @include font($font-s-sm, $line-height-sm);
        font-weight: $font-w-regular;
        display: block;
      }
    }
  }
}

@import "~theme/clearfix";
@import "~theme/colors";
@import "~theme/type";

@mixin screen {
  @media only screen {
    @content; } }

.PrintPage {
  &__page {
    background: $color-white;
    height: 11in;
    padding: .75in 1in;
    position: relative;
    width: 8.5in;
    page-break-after: always;
    overflow: hidden;

    @include screen {
      box-shadow: 0 3pt 15pt 3pt rgba($color-black, .3);
      margin-left: auto;
      margin-right: auto;

      & + & {
        margin-top: .5in; } }

    &.normal {
      padding: .75in 1in; }

    &.sm {
      padding: .5in; }

    &.xs {
      padding: .3in; }

    &.none {
      padding: 0; }

    &_pink {
      background: lighten($color-pink-pale, 3%); }

    &Number {
      @include font(8pt, 9pt);
      color: $color-pink;
      font-family: $font-primary-new;
      font-weight: $font-w-bold;
      font-weight: $font-w-regular;
      position: absolute;
      right: 1in;
      top: .5in; }

    &_pink &Number {
      color: $color-white; }

    &Footer {
      @include font(6pt, 7pt);
      bottom: .5in;
      color: $color-pink;
      font-family: $font-primary-new;
      font-weight: $font-w-regular;
      position: absolute;
      right: 1in;
      left: 1in;

      &.normal {
        left: 1in;
        right: 1in; }

      &.sm {
        left: .5in;
        right: .5in; }

      &.xs {
        left: .3in;
        right: .3in; }

      &.none {
        left: 0;
        right: 0; } }

    &_pink &Footer {
      color: $color-white; }

    &Content {
      height: 100%;
      position: relative; }

    &Bg {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      overflow: hidden; } } }

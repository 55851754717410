@import "~theme/scale";
@import "~theme/colors";
@import "~theme/type";
@import "~theme/scaffolding/scaffolding";


.CompleteUserData {
  @keyframes slide-down {
    0% {
      transform: translateY(-200%);
      opacity: 0; }
    100% {
      transform: translateY(0);
      opacity: 1; } }

  @keyframes slide-right {
    0% {
      transform: translateX(-50%);
      opacity: 0; }
    100% {
      transform: translateX(0);
      opacity: 1; } }

  .AuthForm__field + .AuthForm__field {
    margin-top: 2rem; }

  &__inner {
    line-height: 0;
    margin-bottom: 6rem;
    text-align: center; }

  &__checkbox {
    margin-bottom: 1rem;

    label {
      @include font($font-s-xs-sm, $line-height-xs);
      font-weight: $font-w-light;
      display: inline-block;
      vertical-align: top;
      text-transform: none;

      a {
        text-decoration: underline;
        color: inherit; } }

    .Checkbox {
      margin-left: 1rem;
      font-size: 1.2em; } }

  &__text {
    @include font($font-s-xs-sm, $line-height-xs);

    &Box {
      margin: 3rem 0;
      font-weight: $font-w-regular;
      text-align: center;

      a {
        @include link;
        color: $color-primary;
        font-weight: $font-w-semi-bold; } } }

  &__error {
    @include font($font-s-xs-sm, $line-height-xs);
    font-family: $font-primary-new;
    margin-bottom: 2rem;
    color: $color-red;
    font-weight: $font-w-medium;

    a {
      @include link; } }

  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 50vh;
    transform: translateY(-50%);
    text-align: center;

    h3 {
      @include font($font-s-4, $line-height-4);
      font-weight: $font-w-medium;
      font-family: $font-secondary-new;
      animation: slide-down 1s .5s both;

      @include screen-min(1440px) {
        @include font($font-s-5, $line-height-5); } }

    h4 {
      @include font($font-s-2, $line-height-2);
      font-weight: $font-w-bold;
      animation: slide-right 1s 1s both;

      @include screen-min(1440px) {
        @include font($font-s-3, $line-height-3); } } }

  &__row {
    & > div {
      width: 49%;
      display: inline-block;

      &:first-of-type {
        margin-right: 2%; } }

    &Last {
      margin-bottom: 5rem; }

    &Full {
      display: inline-block;
      width: 100%; } } }

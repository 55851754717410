@import "~theme/btn/btn";
@import "~theme/clearfix";
@import "~theme/colors";
@import "~theme/type";
@import "~theme/img-url";

@mixin screen {
  @media only screen {
    @content; } }


@mixin font-primary {
  @include font(12pt, 16pt);
  font-family: $font-secondary-new;
  font-weight: $font-w-extra-light; }

@mixin driver-border-colors {
  &.who {
    border-color: $color-driver-who; }
  &.why {
    border-color: $color-driver-why; }
  &.how {
    border-color: $color-driver-how; } }

@mixin driver-colors {
  &.who {
    color: $color-driver-who; }
  &.why {
    color: $color-driver-why; }
  &.how {
    color: $color-driver-how; } }

.ShortReport {
  @include screen {
    background: $color-grey-heather;
    padding-bottom: .5in;
    padding-top: .5in;
    min-width: 11in; }

  &__links {
    text-align: center; }

  &__pdfLink {
    display: none;

    @include screen {
      @include btn(primary, solid, $color-pink);
      display: inline-block;
      margin-bottom: .5in;
      margin-right: .2in;
      min-width: 50rem; } }

  &__link {
    display: none;

    @include screen {
      @include btn(primary, outlined, $color-pink);
      background: $color-white;
      display: inline-block;
      margin-bottom: .5in;
      margin-left: .2in;
      min-width: 50rem; } }

  &__page {
    position: absolute;

    &_left {
      left: 0; }
    &_right {
      right: 0; }
    &_bottom {
      bottom: 0; }
    &_pink.PrintPage__page {
      background: $color-pink-pale; } }

  &__row {
    position: relative;

    &_bottom {
      bottom: 40pt;
      left: 0;
      position: absolute;
      right: 0; }

    &_margin-top {
      margin-top: 10pt; } }

  &__column {
    display: inline-block;

    &Overview {
      display: inline-block;
      vertical-align: top;
      width: 80%; }

    &Wide {
      display: inline-block;
      vertical-align: top;
      width: 65%;

      &Overview {
        width: 57%;

        &.wide {
          width: 100%; } }

      &_padding-right {
        padding-right: 33pt; } }

    &Narrow {
      display: inline-block;
      width: 35%;

      &Overview {
        padding: 0 .13in;
        width: 43%;

        p {
          @include font-primary;
          @include font(12pt, 14.5pt);
          text-align: justify; } } }

    &Three {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 3);

      &:not(:first-child) {
        padding-left: 10pt; }

      &:not(:last-child) {
        padding-right: 10pt; } }

    &_centered {
      text-align: center; } }

  &__cover {
    &Bg {
      background: no-repeat 50% 80% / 105% url($url-report-cover);
      height: 100%;
      width: 100%; }

    &Title {
      @include font(30pt, 32pt);
      font-weight: $font-w-extra-bold;
      margin: 10pt 0;
      color: $color-pink; }

    &Name {
      @include font(18pt, 32pt);
      font-weight: $font-w-extra-bold;
      margin: 10pt 0;
      color: $color-pink; }

    &Text {
      @include font(13pt, 30pt);
      font-weight: $font-w-semi-bold;
      margin: 20pt 0; }

    &Logo {
      margin: 10pt 0; } }

  &__archetypeTitle {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 45rem;
    position: relative;
    top: 45%;
    transform: translateY(-50%);
    width: 100%; }

  &__logo {
    background-size: contain;
    background-repeat: no-repeat;
    height: 60pt;
    margin-top: 30pt; }

  &__footer {
    color: $color-report-purple;
    display: block;

    &Number {
      padding-right: 5pt; }

    &.right {
      text-align: right; }

    &.right &Number {
      padding-right: 0;
      padding-left: 5pt; } }

  &__title {
    @include driver-colors;
    @include font(46pt, 52pt);
    color: $color-report-purple;
    font-weight: $font-w-extra-bold;
    margin-bottom: 25pt;

    &:last-child {
      margin-bottom: 70pt; }

    &_small {
      @include font(36pt, 40pt); }

    &_centered {
      text-align: center; }

    &_pink {
      color: $color-pink; } }

  &__subtitle {
    @include driver-colors;
    @include font(18pt, 20pt);
    color: $color-report-purple;
    font-family: $font-primary;
    font-weight: $font-w-bold;

    &_big {
      @include font(25pt, 26pt);
      padding-left: 1.2in; }

    &_black {
      color: $color-black;
      font-weight: $font-w-semi-bold; }

    &_white {
      color: $color-white;
      font-weight: $font-w-semi-bold; }

    &_pink {
      color: $color-pink; }

    &_italic {
      font-style: italic; }

    &_small {
      @include font(16pt, 20pt); }

    &_smaller {
      @include font(12pt, 20pt); }

    &_bold {
      font-weight: $font-w-bold; }

    &_uppercase {
      text-transform: uppercase; }

    &_who {
      color: $color-driver-who; }
    &_why {
      color: $color-driver-why; }
    &_how {
      color: $color-driver-how; }

    &Logo {
      height: 30pt;
      margin-bottom: -5pt;
      margin-left: 10pt; } }

  &__text {
    @include font-primary;
    text-align: justify;
    font-size: 10pt;

    & + & {
      margin-top: .22in; }

    &_small {
      @include font(10pt, 12pt); }

    &_pink {
      color: $color-pink; }

    &_bigger {
      @include font(15pt, 20pt);
      margin-top: 30pt;

      &_withBottomMargin {
        margin-bottom: 30pt; } }

    &_borderedPink {
      border-top: 2pt solid $color-pink;
      border-bottom: 2pt solid $color-pink;
      margin-top: 8pt;
      padding: 8pt 0; }

    &_bold {
      font-weight: $font-w-light; }

    &_left {
      text-align: left; }

    &_condensed {
      @include font(10pt, 14.5pt); }

    &_margin-top {
      margin-top: 30pt; } }

  &__agenda {
    position: absolute;
    bottom: 20pt;
    margin-left: 20pt;

    &Content {
      color: $color-report-purple;
      padding-left: .25in;
      border-left: 1pt solid $color-report-purple;

      h2, h3 {
        display: inline-block;
        font-family: $font-secondary-new;
        font-weight: $font-w-extra-light; } }

    &Title {
      @include font(12pt, 20pt);
      font-weight: $font-w-semi-bold;
      text-transform: uppercase;
      margin-bottom: .25in; }

    &Item {
      @include font(11pt, 13pt);
      font-weight: $font-w-semi-bold;
      margin-top: .1in; }

    &Reset {
      bottom: 20pt;
      margin-left: 0;
      width: auto;

      .ShortReport__agendaContent {
        border-bottom: 2pt solid $color-report-purple;
        border-left: 0;
        border-top: 2pt solid $color-report-purple;
        padding: .10in 0;
        padding-left: .45in; }

      .ShortReport__agendaTitle {
        margin-bottom: 0; }

      .ShortReport__agendaSubitem {
        margin-top: 0; } }

    &Reset {
      margin-left: 0;
      width: auto;

      &Content {
        border-left: 0; }

      &Title {
        margin-bottom: 0; } } }

  &__driver {
    border-top: 3px solid;

    &Icon {
      display: inline-block;
      height: 1.5in;
      vertical-align: middle;

      &_medium {
        height: 0.6in; }

      &_small {
        height: .7in; }

      &_top {
        vertical-align: top; } }

    &Name {
      display: inline-block;
      margin-left: .1in;
      vertical-align: middle; }

    &Column {
      display: inline-block;
      max-width: 71%;
      padding: 0 .13in;
      border-right: 1px solid transparent;

      &Overview {
        max-width: 83%;

        &.wide {
          border-right: none;
          max-width: 90%;
          padding-right: 0; } } }

    &_who {
      border-color: $color-driver-who; }
    &_why {
      border-color: $color-driver-why; }
    &_how {
      border-color: $color-driver-how; }

    &~& {
      margin-top: 20pt; } }

  &__line {
    border: 0;
    height: 2px;
    background: $color-report-purple;
    margin: .4in 0; }

  &__driverOverview {
    &Purpose {
      border-top: 2pt solid $color-report-purple;
      border-bottom: 2pt solid $color-report-purple;
      margin-top: 30pt;
      padding: 20pt 0;

      h3 {
        @include font(18pt, 24pt);
        font-weight: $font-w-extra-light;
        font-family: $font-secondary-new;
        display: inline-block;
        text-align: right;
        width: 60%; }

      h5 {
        @include font(12pt, 15pt);
        display: inline-block;
        font-family: $font-primary;
        font-weight: $font-w-extra-bold;
        padding-left: 20pt; }

      &Value {
        color: $color-report-purple;
        font-family: $font-primary;
        font-weight: $font-w-extra-bold;
        margin-left: 5pt; }

      &_who {
        color: $color-driver-who; }
      &_why {
        color: $color-driver-why; }
      &_how {
        color: $color-driver-how; }
      &_dominant {
        color: $color-report-purple;
        text-align: center;
        text-transform: uppercase;
        font-size: 12pt; } }

    &Logo {
      position: absolute;
      top: 40pt;
      left: 25pt;
      opacity: .1; }

    &Drivers {
      margin-top: 20pt;

      &Column {
        text-align: center;
        padding: 0 12pt;

        &_dominant {
          padding: 0 !important;
          position: relative; } }

      img {
        width: 100pt; }

      p {
        text-align: center; }

      h4 {
        font-weight: $font-w-bold; } } }

  &__arrow {
    height: 80pt;
    margin: 8pt 0;
    padding: 5pt 0 5pt 5pt;
    position: relative;

    @mixin color($color) {
      background: $color;

      &:after {
        border-left-color: $color; } }

    &:after {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-left-color: $color-white;
      border-width: 40pt;
      margin-top: -40pt; }

    .fa {
      @include font(50pt, 65pt);
      color: $color-white;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      width: 15%; }

    &_1 {
      @include color(rgba($color-pink, .1)); }
    &_2 {
      @include color(rgba($color-pink, .18)); }
    &_3 {
      @include color(rgba($color-pink, .25)); }
    &_4 {
      @include color(rgba($color-pink, .33)); }
    &_5 {
      @include color(rgba($color-pink, .42)); }
    &_6 {
      @include color(rgba($color-pink, .5)); }

    &Container {
      display: inline-block;
      padding-left: 5pt;
      max-width: 85%;
      vertical-align: middle; } }

  &__info {
    &Driver {
      margin-left: 20pt;

      &.who {
        background: $color-driver-who-pale; }
      &.why {
        background: $color-driver-why-pale; }
      &.how {
        background: $color-driver-how-pale; } } }

  &__rights {
    bottom: 10pt; }

  &__rights &__agendaTitle {
    @include font(10pt, 12pt);
    font-weight: $font-w-extra-bold;
    margin-bottom: 5pt; }

  &__rights &__agendaItem {
    @include font(8pt, 10pt); }

  &__profileInfo {
    bottom: 10pt;
    padding-right: 33pt;
    position: absolute;
    width: 65%; }

  &__archetype {
    height: 100%;
    position: relative; }

  &__quote {
    margin-bottom: 30pt;

    &Text,
    &Author {
      @include font(16pt, 21pt);
      color: $color-report-purple;
      font-family: $font-secondary-new; }

    &Text {
      p {
        margin-top: 1rem;

        &:last-of-type {
          &:after {
            content: '\201d'; } } }

      &:before {
        content: '\201c';
        float: left; } }


    &Author {
      margin-top: 10pt;

      * {
        display: inline-block; }

      &:before {
        content: '--'; } } }

  &__purposeOverview {
    p {
      @include font-primary;
      text-align: justify;
      margin-top: 2rem; }

    h1 {
      @include driver-colors;
      @include font(18pt, 20pt);
      color: $color-black;
      font-family: $font-primary;
      font-weight: $font-w-semi-bold;
      margin: 10pt 0; } }

  &__markdown {
    ol {
      list-style: decimal inside; }

    ul {
      list-style: disc inside; }

    * {
      @include font-primary;
      text-align: justify;
      font-size: 10pt; }

    p:not(:first-child) {
      margin: 2rem 0;

      & + & {
        margin-top: .22in; } } }

  &__languageDropdown {
    display: none;

    @include screen {
      display: block;
      max-width: 60rem;
      margin: auto;
      margin-bottom: 7rem; } } }

@import "~theme/responsive/responsive";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/scaffolding/scaffolding";
@import "~theme/fonts";
@import "~theme/btn/btn";
@import "~theme/grid/grid";

@mixin driver-colors {
  &.who {
    color: $color-driver-who; }
  &.why {
    color: $color-driver-why; }
  &.how {
    color: $color-driver-how; } }

.PurposeProfile {
  p {
    @include p-new;
    margin: 3rem 0; }

  .section-head {
    margin-bottom: 0;

    + p {
      margin-top: 1rem; }

    + .card {
      margin-top: 3rem; } }

  .card-shadow {
    @include card-shadow; }

  .btn-radio {
    width: 100%; }

  &.page {
    background: $color-white; }

  &__page {
    max-width: 1040px;
    margin: auto; }

  .page-title {
    margin-bottom: 5rem; }

  &__row {
    display: grid;
    gap: 3rem;
    font-weight: $font-w-regular;

    @include screen-xs {
      grid-template-columns: 1fr; }

    &.col-landing {
      @include screen-sm-plus {
        grid-template-columns: 336px 1fr;
        align-items: start; } }

    &.col-1-2 {
      @include screen-sm-plus {
        grid-template-columns: 1fr 2fr; } }
    &.col-1-1 {
      @include screen-sm-plus {
        grid-template-columns: 1fr 1fr; } }
    &.col-1-1-1 {
      @include screen-sm-plus {
        grid-template-columns: repeat(3, 1fr); } }
    &.col-1-3 {
      @include screen-sm-plus {
        grid-template-columns: 1fr 3fr; } }

    &.align-center {
      align-items: center; }

    &.color-who {
      color: $color-driver-who; }
    &.color-why {
      color: $color-driver-why; }
    &.color-how {
      color: $color-driver-how; }

    &.driver {
      font-weight: $font-w-bold;
      span:nth-child(2) {
        text-align: right; } } }

  &__content {
    .btn-primary {
      display: inline-block; } }

  &__archetype {
    max-width: rem-size(639px);
    width: 70%;
    margin: auto;
    display: block; }

  &__driver {
    border-radius: 8px;
    display: flex;
    margin-top: 2rem;
    img {
      width: 60%;
      display: block;
      margin: auto; }

    &.who {
      background: $color-driver-who-pale; }
    &.why {
      background: $color-driver-why-pale; }
    &.how {
      background: $color-driver-how-pale; }

    &.info {
      display: block;
      padding: rem-size(3px) 0; }

    &Icon {
      margin: rem-size(10px) rem-size(32px);
      display: flex;
      align-items: center;
      img {
        width: rem-size(64px);
        margin: 0;
        margin-right: 2rem; } } }

  &__heading {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 5rem;

    p {
      margin: 0; }

    .page-title {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      position: relative;

      @include screen-min(1050px) {
        left: -5rem; }

      i {
        font-size: .5em;
        width: 5rem; } }

    &Link {
      color: $color-text-grey; } }

  &__nav {
    display: flex;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: $color-border; }

    &Item {
      @include p-new;
      font-weight: $font-w-semi-bold;
      padding: 2rem 3rem;
      position: relative;
      cursor: pointer;

      &:before {
        display: block;
        content: attr(title);
        font-weight: $font-w-bold;
        overflow: hidden;
        height: 0;
        visibility: hidden; }

      &.active {
        font-weight: $font-w-bold;
        color: $color-primary;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 2px;
          background: $color-primary;
          z-index: 5; } } } }

  &__section {
    position: relative;
    overflow: hidden;
    &.card {
      padding: 0 5rem 0 10rem; }

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }

    &:not(:last-child) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }

    & + & {
      margin-top: 3rem; }

    &:before {
      content: "";
      width: 5rem;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;

      @at-root .who#{&} {
        background: $color-driver-who; }
      @at-root .why#{&} {
        background: $color-driver-why; }
      @at-root .how#{&} {
        background: $color-driver-how; } }

    &Name {
      @include font(rem-size(16px), 5rem);
      writing-mode: vertical-lr;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      color: $color-white;
      font-weight: $font-w-semi-bold;
      display: flex;
      justify-content: center;
      text-transform: uppercase; }

    &Item {
      padding: 5rem 0;

      & + & {
        border-top: 1px solid $color-border; } } }

  &__leadership {
    text-align: center;
    img {
      max-width: 50rem;
      display: block;
      margin: auto; } }

  &__organization {
    img {
      max-width: 40rem;
      display: block;
      margin: auto; }

    p {
      margin: 1.5rem 0;

      i {
        margin-right: 1rem; } } }

  &__hero {
    img {
      width: 100%; }

    a {
      display: inline-block; }

    .btn-radio + .btn-radio {
      margin-top: 1rem; }

    &List {
      max-height: 50rem;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 10px; }

      &::-webkit-scrollbar-track {
        background: rgba($color-border, .1); }

      &::-webkit-scrollbar-thumb {
        background: $color-grey-60; } } }
  // .Loader
  //   padding-top: calc(50vh - #{rem-size(160px)})
  //   align-items: flex-start

  // &__section
  //   background: $color-white
  //   border: 1px solid $color-grey-light-med
  //   position: relative
  //   padding: rem-size(50px) rem-size(50px)

  //   +screen-max(400px)
  //     border: none
  //     margin-left: -5rem
  //     margin-right: -5rem
  //     padding: 2rem

  //   & + &
  //     margin-top: 5rem

  // &__block
  //   display: flex

  //   +screen-xs
  //     flex-direction: column

  // &__compareBlock
  //   padding: 0

  // &__left
  //   justify-content: flex-start
  //   flex-basis: 50%
  //   min-height: 40rem

  //   +screen-sm-minus
  //     min-height: 25rem

  // &__right
  //   display: flex
  //   flex-basis: 50%
  //   flex-direction: column
  //   justify-content: center
  //   align-items: center
  //   padding: 0 3rem

  //   +screen-sm-minus
  //     margin-top: 3rem

  //   p
  //     margin-top: 3rem

  //   span
  //     font-weight: $font-w-bold

  // &__title
  //   min-height: 10vh // for IE 11
  //   display: flex
  //   justify-content: center
  //   align-items: center
  //   &Image
  //     width: 70%

  //   +screen-xs
  //     min-height: 20%
  //     margin-top: 0

  // &__statement
  //   color: $color-pink
  //   +font($font-s-2, $line-height-1)
  //   font-family: $font-secondary-new
  //   font-weight: $font-w-regular
  //   text-align: center
  //   margin: 5rem 0

  //   +screen-xs
  //     +font($font-s-1, $line-height-0)

  // &__powerUpDriver
  //   +font($font-s-1, $line-height-2)
  //   color: $color-grey-dark

  // &__assessmentContainer
  //   padding: 1rem 7rem

  // &__buttons
  //   display: flex
  //   flex-direction: row
  //   justify-content: center //for IE 11 cause margin 0 auto is not working with IE
  //   margin-top: 5rem

  // &__btn
  //   font-weight: $font-w-medium
  //   display: flex
  //   align-items: center

  //   &.btn_white_pink
  //     border: 3px solid $color-pink
  //     margin-left: 1rem
  //     display: flex
  //     align-items: center
  //     line-height: $line-height-xs
  //     height: 7rem

  //   &.btn_solid_pink
  //     height: 7rem
  //     line-height: $line-height-xs

  //   &.btn_compact
  //     display: flex
  //     align-items: center
  //     line-height: unset

  // &__driverOverview
  //   margin-bottom: 6rem
  //   width: 100%

  // &__row
  //   display: flex
  //   line-height: $line-height-4

  //   h3
  //     +font($font-s-1, $line-height-0)
  //     font-weight: $font-w-extra-bold
  //     text-align: left
  //     width: 70%

  //   h5
  //     font-size: $font-s-sm
  //     font-weight: $font-w-extra-bold
  //     text-align: right
  //     width: 30%

  //   &.who
  //     color: $color-driver-who
  //   &.why
  //     color: $color-driver-why
  //   &.how
  //     color: $color-driver-how

  // &__icon
  //   width: 65%
  //   min-height: 1px // for IE

  &__video {
    position: relative;
    cursor: pointer;
    position: relative;
    max-width: rem-size(440px);
    width: 100%;

    img {
      max-width: rem-size(440px);
      width: 100%;

      @include screen-sm-minus {
        width: inherit; } }

    span {
      font-size: 3em;
      color: $color-secondary;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

    &Mask {
      height: 100%;
      position: absolute;
      transition: background .5s;
      width: 100%;
      z-index: 7;

      &:hover {
        background: rgba($color-black, .1);
        transition: background .5s; } } }

  &__dimensions {
    display: flex;

    @include screen-xs {
      flex-wrap: wrap; }

    &-item {
      filter: grayscale(100%);
      opacity: .2;
      display: flex;
      align-items: center;
      flex-direction: column;

      &~& {
        margin-left: 5rem; }

      img {
        max-width: 10rem; }

      p {
        font-weight: $font-w-semi-bold;
        margin: 0; }

      &.active {
        filter: grayscale(0%);
        opacity: 1; } } }

  &__infoDimensions {
    background: $color-grey-50;
    margin: 0 (-$grid-gutter-width / 2);
    padding: $grid-gutter-width / 2;

    @include screen-xs {
      margin: 0 (-$grid-gutter-width / 4);
      padding: $grid-gutter-width / 4; } }

  // &__driver
  //   margin: 0 2rem 3rem 3rem

  //   +screen-xs
  //     margin-left: 6rem

  // &__driverSection
  //   display: flex
  //   flex-direction: column
  //   flex-basis: 50%

  // &__descriptions
  //   font-weight: $font-w-medium
  //   margin-top: 5rem

  // &__subsection
  //   border-top: 1px solid $color-grey-med
  //   padding-top: 5rem

  // &__logo
  //   margin-right: 10rem

  //   img
  //     max-width: 40rem

  // &__orgContent
  //   +screen-range(767px, 1100px)
  //     margin-left: 3rem

  //   &Subtitle
  //     +driver-colors
  //     +font(14pt, 20pt)
  //     color: $color-report-purple
  //     font-family: $font-primary
  //     font-weight: $font-w-bold

  //     &_who
  //       color: $color-driver-who
  //     &_why
  //       color: $color-driver-why
  //     &_how
  //       color: $color-driver-how

  //   span
  //     margin-right: 1rem

  // &__question
  //   margin-top: 2rem

  // &__leadershipStyle
  //   border-bottom: 1px solid $color-grey-med
  //   padding-bottom: 5rem

  // &__leadership
  //   margin-top: 5rem

  // &__powerUpItem
  //   display: flex
  //   align-items: center
  //   margin-bottom: 2rem

  // &__powerUpContent
  //   +font($font-s-sm, $line-height-sm)
  //   font-weight: $font-w-medium
  //   margin-bottom: 1rem
  //   flex-basis: 60%
  //   padding-right: 5em
  //   position: relative
  //   top: 2.5em

  // &__avatar
  //   margin-right: 1rem
  //   flex: 0 0 auto

  // &__labelContainer
  //   position: relative
  //   height: 8rem
  //   width: calc(100% - 4.15rem)
  //   margin: auto

  // &__label
  //   position: absolute
  //   text-align: center
  //   width: 0
  //   top: 5rem

  // &__labelText
  //   font-size: $font-s-xs-sm
  //   font-weight: $font-w-semi-bold
  //   margin-left: -7em
  //   width: 14em
  //   text-transform: uppercase
  //   color: $color-white
  //   position: relative

  //   &:after
  //     top: 100%
  //     left: 50%
  //     border: solid transparent
  //     content: " "
  //     height: 0
  //     width: 0
  //     position: absolute
  //     pointer-events: none
  //     border-top-color: $color-header-blue
  //     border-width: 2rem
  //     margin-left: -2rem

  //   span
  //     background: $color-header-blue
  //     padding: 1rem 3rem
  //     border-radius: 5px

  // &__notchContainer
  //   border-radius: .5rem
  //   display: flex
  //   margin-top: 4rem
  //   padding: 0 2rem

  // &__notch
  //   border-left: 1px solid mix($color-border, $color-header-blue, 70%)
  //   flex-grow: 1
  //   float: left
  //   height: 4rem
  //   position: relative
  //   overflow: hidden

  //   &:nth-of-type(1)
  //     border-left-color: transparent

  //   &:first-child:after
  //     border-top-left-radius: 2rem
  //     border-bottom-left-radius: 2rem

  //   &:last-child:after
  //     border-top-right-radius: 2rem
  //     border-bottom-right-radius: 2rem

  //   &:after
  //     content: ''
  //     height: 2rem
  //     position: absolute
  //     top: 50%
  //     transform: translateY(-50%)
  //     background: mix($color-border, $color-header-blue, 70%)
  //     width: 100%

  // &__slider
  //   position: relative
  //   flex: 1 1

  // &__handle
  //   background-color: rgba($color-white, .7)
  //   box-shadow: 0 0 20px 2px rgba($color-black, .1)
  //   color: transparent // to remove weird border when active
  //   cursor: pointer
  //   border-radius: 50%
  //   height: 4rem
  //   margin-top: -4rem
  //   outline: none
  //   position: absolute
  //   width: 4rem
  //   z-index: 1

  //   &:after
  //     content: ''
  //     width: 3rem
  //     height: 3rem
  //     border-radius: 50%
  //     position: absolute
  //     left: 50%
  //     top: 50%
  //     transform: translate(-50%, -50%)
  //     background: $color-header-blue

  // &__buttons
  //   wdisplay: flex
  //   justify-content: space-between

  // &__development
  //   display: flex
  //   flex-wrap: wrap
  //   margin: -2.5rem
  //   padding: 5rem 0

  //   h2
  //     +subtitle-blue-caps

  //     +screen-max(1400px)
  //       +font($font-s-sm, $line-height-sm)

  //   .description
  //     +text-secondary
  //     margin-bottom: 2rem

  //   .card
  //     +card
  //     padding: 6rem
  //     display: flex
  //     flex-flow: column
  //     background: $color-white
  //     border: 1px solid $color-border
  //     position: relative
  //     margin: 2.5rem
  //     padding-bottom: 5rem
  //     flex: 1 1 50rem

  //     +screen-max(400px)
  //       border: none
  //       margin-left: -5rem
  //       margin-right: -5rem
  //       padding: 2rem

  //     &MicroLessons
  //       +screen-md-minus
  //         font-size: $font-s-sm

  // &__man
  //   width: 60%
  //   max-width: 30rem
  //   margin: 5rem auto 0

  // &__search
  //   width: 100%

  // &__powerUpsItem
  //   margin-bottom: 2rem

  //   p
  //     +font($font-s-sm, $line-height-sm)
  //     font-weight: $font-w-medium

  // &__avatarContainer
  //   display: flex
  //   align-items: center

  //   p
  //     +font($font-s-xs, $line-height-xs)
  //     color: $color-grey-dark-med

  // &__progress
  //   margin-bottom: 5rem

  // &__downloadBtn
  //   margin: -.5rem

  //   button,
  //   a
  //     margin: .5rem auto
  //     max-width: 50%

  //   button
  //     padding: 0 4rem !important

  // &__reportModal
  //   display: flex
  //   align-items: center

  //   img
  //     width: 25%
  //     padding-right: 3rem

  //   h4
  //     +font($font-s-0, $line-height-1)
  //     font-family: $font-primary-new
  //     text-transform: uppercase
  //     font-weight: $font-w-semi-bold
  //     letter-spacing: 1px

  //   p
  //     +font($font-s-sm, $line-height-sm)
  //     color: $color-grey-dark-med

  // &__hero
  //   width: 50%

  // &__averages
  //   display: flex
  //   justify-content: space-around

  // &__labels
  //   & ~ &
  //     margin-left: 1rem

  // &__labelBlock
  //   +font($font-s-xs, $line-height-xs)
  //   border-bottom: 1px solid $color-grey-med
  //   background: $color-grey-light
  //   color: $color-grey-dark
  //   text-align: center
  //   font-weight: $font-w-medium
  //   padding: .5rem

  //   &_filled
  //     &.who
  //       background: $color-driver-who
  //       color: $color-white
  //     &.why
  //       background: $color-driver-why
  //       color: $color-white
  //     &.how
  //       background: $color-driver-how

  // &__driverIcons
  //   display: flex
  //   justify-content: space-around
  //   margin-top: 2rem

  // &__iconItem
  //   text-align: center

  //   img
  //     width: 10rem

  //   p
  //     +font($font-s-sm, $line-height-sm)
  //     font-weight: $font-w-semi-bold

  // &__trackProgressBtn
  //   text-align: center // IE
  //   margin-top: 5rem

  // &__modal
  //   margin: 5rem auto 0

  //   +screen-lg
  //     width: 70%

  // &__message
  //   display: flex
  //   margin-top: 2rem
  //   font-weight: $font-w-medium

  //   p
  //     +font($font-s-sm, $line-height-sm)

  // &__messageIcon
  //   color: $color-pink
  //   width: 3rem
  //   margin-right: 2rem

  // // Overlay nav
  // &__nav
  //   border-bottom: 2px solid $color-grey-med
  //   display: flex

  //   +screen-xs
  //     border: none
  //     display: block

  //   &__btn
  //     +font($font-s-xs-sm, $line-height-0)
  //     background: none
  //     color: $color-grey-heather-dark
  //     font-weight: $font-w-bold
  //     letter-spacing: .2rem
  //     margin-right: 4rem
  //     border: none
  //     position: relative
  //     transition: color .3s

  //     &:after
  //       content: ''
  //       position: absolute
  //       bottom: -2px
  //       background: none
  //       height: 2px
  //       left: 50%
  //       right: 50%
  //       transition: left .3s, right .3s

  //     &_active
  //       color: $color-header-blue

  //       &:after
  //         left: 0
  //         right: 0
  //         background: $color-header-blue

  //         +screen-xs
  //           display: none

  //     +screen-xs
  //       &:first-child
 }  //         padding-left: 0

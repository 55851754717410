@import 'node_modules/tippy.js/dist/tippy';
@import 'node_modules/draft-js/dist/Draft';
@import "~theme/scale";
@import "~theme/type";
@import "~theme/colors";

.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px; }

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb; }

.rc-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

.rc-slider-handle:focus {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
  outline: none; }

.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset; }

.rc-slider-handle:hover {
  border-color: #57c5f7; }

.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px; }

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999; }

.rc-slider-mark-text-active {
  color: #666; }

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent; }

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle; }

.rc-slider-dot-active {
  border-color: #96dbfa; }

.rc-slider-disabled {
  background-color: #e9e9e9; }

.rc-slider-disabled .rc-slider-track {
  background-color: #ccc; }

.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed; }

.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important; }

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px; }

.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px; }

.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px; }

.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%; }

.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px; }

.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px; }

.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px; }

.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px; }

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-duration: .3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused; }

.rc-slider-tooltip-zoom-down-leave {
  animation-duration: .3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused; }

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running; }

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running; }

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0); }

  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1); } }


@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1); }

  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0); } }


.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-tooltip-hidden {
  display: none; }

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0; }

.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9; }

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c; }

.react-select {
  @include select; }

$datepicker-size: 20px;

.react-datepicker__input-container {
  input {
    @include font($font-s-sm, 4.5rem);
    border: 1px solid $color-border;
    border-radius: 0;
    padding: 6px 8px;
    outline: none;
    &:focus {
      border-color: $color-primary; } } }

.react-datepicker-popper {
  z-index: 1000; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: $color-primary; }

.react-datepicker {
  font-size: $datepicker-size * 0.8;
  border-radius: 3px; }

.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 3px; }

.react-datepicker__header {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: $color-primary; }

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: $font-s-sm;
  font-weight: $font-w-semi-bold;
  color: $color-white; }

.react-datepicker__navigation {
  line-height: $datepicker-size * 1.7;
  border-width: $datepicker-size * 0.45;
  margin: 0 !important; }

.react-datepicker__navigation--previous {
  border-right-color: $color-white; }

.react-datepicker__navigation--previous:hover {
  border-right-color: $color-white; }

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: rgba($color-white, .5); }

.react-datepicker__navigation--next {
  border-left-color: $color-white; }

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  @include screen-xs {
    right: 10px; } }

.react-datepicker__month-container {
  @include screen-xs {
    width: 100%;
    float: none; } }

.react-datepicker__month {
  margin: $datepicker-size * 0.4; }

.react-datepicker__month .react-datepicker__month-text {
  width: $datepicker-size * 4; }

.react-datepicker__time-container {
  @include screen-xs {
    float: none;
    width: 100%;
    border-left: none; } }

.react-datepicker__time-container--with-today-button {
  border-radius: 3px; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  height: calc(195px + (#{$datepicker-size} * 1.7 / 2)); }

.react-datepicker__week-number {
  width: $datepicker-size * 1.7;
  line-height: $datepicker-size * 1.7;
  margin: $datepicker-size * 0.166; }

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 3px; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: $datepicker-size * 1.7;
  line-height: $datepicker-size * 1.7;
  margin: $datepicker-size * 0.166;

  @include screen-xs {
    width: $datepicker-size * 1.3;
    line-height: $datepicker-size * 1.3; } }

.react-datepicker__day-name, {
  color: $color-white; }

.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
  border-radius: 3px; }

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted {
  border-radius: 3px; }

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  border-radius: 3px;
  background-color: $color-primary;
  color: $color-white; }

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover {
  background-color: $color-primary; }


.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  border-radius: 3px;
  background-color: $color-primary; }

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
  background-color: $color-primary;
  font-weight: $font-w-semi-bold; }

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border-radius: 3px; }

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-width: 3px; }

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  border-radius: 3px; }

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: $datepicker-size * 3;
  line-height: $datepicker-size * 3; }

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: $datepicker-size * 2;
    line-height: $datepicker-size * 2; } }

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: $font-s-sm;
  font-weight: $font-w-semi-bold; }

.react-datepicker__portal .react-datepicker__navigation {
  border-width: $datepicker-size * 0.81; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  padding: 1rem 2rem;
  height: unset; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: $color-primary; }

.ReactCollapse--collapse {
  transition: height 500ms; }

@media only print {
  #freshworks-container,
  #launcher-frame {
    visibility: hidden !important; } }


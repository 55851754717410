@import "~theme/responsive/responsive";
@import "~theme/type";
@import "~theme/scale";
@import "~theme/colors";

@mixin p-new {
  @include font(rem-size(16px), rem-size(25.6px));
  font-weight: $font-w-regular;
  color: $color-grey-90; }

@mixin p-2 {
  @include font(rem-size(14px), rem-size(22px));
  font-weight: $font-w-regular;
  color: $color-grey-90;

  &.color-grey-60 {
    color: $color-grey-60; } }

@mixin p {
  @include font($font-s-0, $line-height-0);

  @include screen-max(399px) {
    @include font($font-s-sm, $line-height-sm); } }

@mixin p_sm {
  @include font($font-s-sm, $line-height-sm); }

@mixin p_lg {
  @include font($font-s-2, $line-height-2);

  @include screen-sm {
    @include font($font-s-1, $line-height-1); }

  @include screen-xs {
    @include font($font-s-0, $line-height-0); } }

@mixin p_narrow {
  margin-left: auto;
  margin-right: auto;
  max-width: 160rem;

  @include screen-sm {
    max-width: 140rem; }

  @include screen-xs {
    max-width: 90rem; } }

@mixin h1 {
  @include font($font-s-5, $line-height-5);

  @include screen-sm {
    @include font($font-s-4, $line-height-4); }

  @include screen-xs {
    @include font($font-s-2, $line-height-2); } }

@mixin h1_sm {
  @include font($font-s-4, $line-height-4);

  @include screen-sm {
    @include font($font-s-3, $line-height-3); }

  @include screen-xs {
    @include font($font-s-2, $line-height-2); } }


@mixin h2 {
  @include font($font-s-3, $line-height-3);

  @include screen-sm {
    @include font($font-s-2, $line-height-2); }

  @include screen-xs {
    @include font($font-s-1, $line-height-1); } }

@mixin h2_sm {
  @include font($font-s-2, $line-height-2); }

@mixin h2_lg {
  @include font($font-s-4, $line-height-4); }


@mixin h3 {
  @include font(rem-size(30px), rem-size(42px));
  font-weight: $font-w-regular; }

@mixin h3_sm {
  @include font($font-s-1, $line-height-1); }

@mixin h3_lg {
  @include font($font-s-3, $line-height-3); }


@mixin h4 {
  @include font($font-s-1, $line-height-1); }

@mixin h4_sm {
  @include font($font-s-0, $line-height-0); }

@mixin h4_lg {
  @include font($font-s-2, $line-height-2); }


@mixin ul {
  padding-left: 1.2em;
  position: relative;

  > li {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;

    & + li {
      margin-top: .6em; }

    &:before {
      content: "\2022";
      font-family: $font-secondary-new;
      font-size: 1.5em;
      left: 0;
      position: absolute; } } }

@mixin ul_color($color) {
  > li:before {
    color: $color; } }

@mixin ul_aligned {
  padding-left: 1em; }

@mixin ul_justified {
  padding-left: 0em; }


@mixin bullet {
  &:before {
    // +fa("\f111") // circle
    float: left;
    font-size: .3em;
    width: 2em; } }


@mixin link($color: $color-primary) {
  color: $color;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: $font-w-regular;

  &:hover,
  &:focus {
    color: darken($color, 10%); }
  &:active {
    color: darken($color, 15%); }

  .fa {
    display: inline; } }

@mixin link_dark($color: $color-grey-dark) {
  color: $color;
  cursor: pointer;

  &:hover,
  &:focus {
    color: lighten($color, 10%); }
  &:active {
    color: lighten($color, 15%); }

  .fa {
    display: inline; } }

@mixin subhead {
  @include font(rem-size(14px), rem-size(22.4px));
  font-weight: $font-w-bold;
  color: $color-primary; }

@mixin p-semi-bold {
  @include font(rem-size(14px), rem-size(21px));
  font-weight: $font-w-semi-bold;
  color: $color-grey-90; }

@mixin section-head {
  @include font(20px, 27px);
  font-weight: $font-w-semi-bold;
  margin-bottom: 16px;
  color: $color-grey-90; }

@mixin section-intro {
  @include font(20px, 30px);
  font-weight: $font-w-regular;
  color: $color-grey-90; }

@mixin page-title {
  @include font(36px, 50px);
  font-weight: $font-w-regular; }

@mixin caption-caps {
  @include font(12px, 18px);
  color: $color-grey-60;
  text-transform: uppercase;
  font-weight: $font-w-semi-bold;
  letter-spacing: 1.2px; }

@mixin caption {
  @include font(rem-size(12px), rem-size(18px));
  color: $color-grey-60;
  font-weight: $font-w-regular; }

@mixin toast {
  @include p-new;
  padding: rem-size(8px) rem-size(16px);
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: $font-w-regular;
  margin: 8px 0;
  &.success {
    background: rgba($color-success-new, .07);
    i {
      color: $color-success-new;
      margin-right: 16px; } }
  &.error {
    background: rgba($color-error-new, .07);
    i {
      color: $color-error-new;
      margin-right: 16px; } } }

@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";

.Nav {
  &__nav {
    border-top: 1px solid $color-border;
    margin: 0 5rem;
    padding: 4rem 0;

    @include screen-lg {
      max-width: 200rem;
      margin: auto; }

    &Content {
      display: flex;
      justify-content: space-between;

      &.singleBtn {
        display: block;
        text-align: center; }

      @include screen-xs {
        flex-direction: column-reverse;

        & * {
          margin: 10px 0; } } } } }

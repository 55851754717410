@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/scaffolding/scaffolding";

.Fulfillment {
  padding: 24px 0;

  .section-intro {
    margin: 16px 0; }

  .p-new + .p-2 {
    margin-top: 16px; }

  &__row {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    gap: 16px;

    > .card-shadow {
      flex: 1 1 500px; } }

  &__section {
    display: flex;
    flex-flow: column;
    flex: 1 1 500px;
    gap: 16px; }

  &__avatars {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    margin: 16px 0;

    .Avatar {
      position: relative;
      right: 3px;
      &:first-child {
        right: -3px; } }

    .section-head {
      flex: 1 1 100%;
      text-align: center;
      margin-top: 16px; } }

  &__stats {
    margin: 32px 0;

    .card {
      flex: 1 1 300px; }

    .divider.horizontal {
      margin: 16px -24px;
      width: auto;
      @include screen-xs {
        margin: 16px -12px; } }

    .card-heading.p-2 {
      margin: 4px 0;
      align-items: baseline;

      span:first-child {
        margin-right: 5px; } }

    .page-title {
      text-align: center;
      color: $color-grey-90;
      &.color-grey-60 {
        color: $color-grey-60; }
      &.color-grey-30 {
        color: $color-grey-30; }
      .x {
        @include section-intro;
        color: inherit; } } }

  &__completions {
    flex: 3 3;
    display: flex;
    gap: 4px; }

  &__commitment {
    flex: 1 1 500px;

    img {
      max-width: 180px;
      display: block;
      margin: auto; } }

  &__rig {
    display: flex;
    gap: 4px;
    margin-top: 16px;

    &.commitment {
      gap: 8px; }

    &-item {
      flex: 1 1;

      .p-2 {
        margin-top: 8px;
        text-align: center;

        &:first-child {
          margin-top: 0;
          margin-bottom: 8px; }

        &.bold {
          font-weight: $font-w-semi-bold; } } }

    &-item:first-child .card.card-color-grey-50 {
      border-radius: 8px 0px 0px 8px; }

    &-item:last-child .card.card-color-grey-50 {
      border-radius: 0px 8px 8px 0px; }

    &-item:not(:last-child):not(:first-child) .card.card-color-grey-50 {
      border-radius: 0; }

    .page-title {
      color: $color-grey-90;
      &.color-grey-60 {
        color: $color-grey-60; }
      &.color-grey-30 {
        color: $color-grey-30; }
      .x {
        @include section-intro;
        color: inherit; } }

    .card,
    .card.card-color-grey-50 {
      padding: 8px;
      min-height: 66px;
      text-align: center;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;

      img {
        width: 48px;
        height: 48px;
        object-fit: contain; } } } }

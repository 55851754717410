@import "~theme/btn/btn";
@import "~theme/clearfix";
@import "~theme/colors";
@import "~theme/type";
@import "~theme/img-url";

@mixin screen {
  @media only screen {
    @content; } }

@mixin font-primary {
  @include font(12pt, 16pt);
  font-family: $font-secondary-new;
  font-weight: $font-w-extra-light; }

@mixin driver-border-colors {
  &.who {
    border-color: $color-driver-who; }
  &.why {
    border-color: $color-driver-why; }
  &.how {
    border-color: $color-driver-how; } }

@mixin driver-colors {
  &.who {
    color: $color-driver-who; }
  &.why {
    color: $color-driver-why; }
  &.how {
    color: $color-driver-how; } }

.LongReport {
  @include screen {
    background: $color-grey-heather;
    padding-bottom: .5in;
    padding-top: .5in;
    min-width: 11in; }

  &__links {
    text-align: center; }

  &__pdfLink {
    display: none;

    @include screen {
      @include btn(primary, solid, $color-pink);
      display: inline-block;
      margin-bottom: .5in;
      margin-right: .2in;
      min-width: 50rem; } }

  &__link {
    display: none;

    @include screen {
      @include btn(primary, outlined, $color-pink);
      background: $color-white;
      display: inline-block;
      margin-bottom: .5in;
      margin-left: .2in;
      min-width: 50rem; } }

  &__page {
    position: absolute;

    &_left {
      left: 0; }
    &_right {
      right: 0; }
    &_bottom {
      bottom: 0; } }

  &__row {
    position: relative;

    &_bottom {
      bottom: 40pt;
      left: 0;
      position: absolute;
      right: 0;
      top: 150pt; } }

  &__column {
    &Wide {
      display: inline-block;
      vertical-align: top;
      width: 65%;

      &_padding-right {
        padding-right: 33pt; } }

    &Narrow {
      display: inline-block;
      width: 35%; }

    &Three {
      display: inline-block;
      vertical-align: top;
      width: 100%;

      &:not(:first-child) {
        padding-left: 10pt; }

      &:not(:last-child) {
        padding-right: 10pt; } }

    &_centered {
      text-align: center; } }

  &__cover {
    &Bg {
      background: no-repeat 50% 80% / 105% url($url-report-cover);
      height: 100%;
      width: 100%; }

    &Title {
      @include font(30pt, 32pt);
      font-weight: $font-w-extra-bold;
      margin: 10pt 0;
      color: $color-pink; }

    &Name {
      @include font(18pt, 32pt);
      font-weight: $font-w-extra-bold;
      margin: 10pt 0;
      color: $color-pink; }

    &Text {
      @include font(13pt, 30pt);
      font-weight: $font-w-semi-bold;
      margin: 20pt 0; }

    &Logo {
      margin: 10pt 0; } }

  &__archetypeTitle {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 45rem;
    position: relative;
    top: 45%;
    transform: translateY(-50%);
    width: 100%; }

  &__footer {
    color: $color-report-purple;
    display: block;

    &Number {
      padding-right: 5pt; }

    &.right {
      text-align: right; }

    &.right &Number {
      padding-right: 0;
      padding-left: 5pt; } }

  &__quote {
    margin-bottom: 30pt;

    &_big & {
      &Text,
      &Author {
        @include font(20pt, 24pt);
        font-weight: $font-w-extra-light; } }

    &Text,
    &Author {
      @include font(16pt, 21pt);
      color: $color-report-purple;
      font-family: $font-secondary-new; }

    &_huge & {
      &Text,
      &Author {
        @include font(36pt, 40pt);
        font-weight: $font-w-extra-light; } }

    &Text {
      *:last-child {
        display: inline-block; }

      &:before {
        content: '\201c';
        float: left; }

      &:after {
        content: '\201d'; } }

    &Author {
      margin-top: 10pt;

      * {
        display: inline-block; }

      &:before {
        content: '--'; } }

    &_right {
      text-align: right; }

    &_noMargin {
      margin-bottom: 0; } }

  &__listItem {
    display: block;
    margin: 1pt 0;
    text-align: left;

    &:before {
      padding-right: 5pt;
      display: inline-block; }

    &_wide {
      padding-left: 10pt;
      margin: 10pt 0; }

    &_bullet {
      &:before {
        @include font(14pt, 15pt);
        content: "•"; } }

    &_square {
      @include font(11pt, 14pt);
      padding-left: 20pt;
      margin: 0;

      &:before {
        color: $color-pink;
        content: "\25A2";
        font-size: .8em; } }

    &_dash {
      &:before {
        content: "\2013";
        padding-right: 3pt; } }

    &_number {
      counter-increment: listcounter;
      padding-left: 30pt;
      margin: 0;

      &:before {
        content: counter(listcounter) " -";
        padding-right: 5pt; }

      &:first-child {
        counter-reset: listcounter; } }

    &Content {
      display: inline-block;
      vertical-align: top;
      width: 90%; }

    &Bias {
      width: 100%; } }

  &__title {
    @include driver-colors;
    @include font(43pt, 52pt);
    color: $color-report-purple;
    font-weight: $font-w-extra-bold;
    margin-bottom: 25pt;

    &:last-child {
      margin-bottom: 70pt; }

    &_small {
      @include font(36pt, 40pt); }

    &_centered {
      text-align: center; }

    &_withWhitespace {
      margin-top: 30pt; }

    &_withBigWhitespace {
      margin-top: 50pt; }

    &Archetype {
      height: 40%;

      &Background {
        background-position: 45% 30%;
        background-repeat: no-repeat;
        background-size: 123%;
        bottom: 0;
        height: 50%;
        left: 0;
        margin: -.75in -1in;
        opacity: .1;
        position: absolute;
        right: 0;
        top: 0; }

      &Container {
        position: relative; }

      &Name {
        img {
          width: 180pt;
          vertical-align: middle; }

        h2 {
          display: inline-block;
          font-size: 48pt;
          vertical-align: middle; } } }

    &Logo {
      display: block;
      height: 80pt; } }

  &__subtitle {
    @include driver-colors;
    @include font(18pt, 20pt);
    color: $color-report-purple;
    font-family: $font-primary;
    font-weight: $font-w-bold;

    &_big {
      @include font(25pt, 26pt);
      padding-left: 1.2in; }

    &_black {
      color: $color-black;
      font-weight: $font-w-semi-bold; }

    &_white {
      color: $color-white;
      font-weight: $font-w-semi-bold; }

    &_pink {
      color: $color-pink; }

    &_italic {
      font-style: italic; }

    &_small {
      @include font(16pt, 20pt); }

    &_smaller {
      @include font(14pt, 20pt); }

    &_bold {
      font-weight: $font-w-bold; }

    &_uppercase {
      text-transform: uppercase; }

    &Logo {
      height: 30pt;
      margin-bottom: -5pt;
      margin-left: 10pt; } }

  &__text {
    @include font-primary;
    text-align: justify;

    & + & {
      margin-top: .22in; }

    &_bigger {
      @include font(15pt, 20pt);
      margin-top: 30pt;

      &_withBottomMargin {
        margin-bottom: 30pt; } }

    &_bold {
      font-weight: $font-w-light; }

    &_left {
      text-align: left; }

    &_condensed {
      @include font(12pt, 14.5pt); }

    &_firstBig {
      &:first-letter {
        font-size: 3em;
        float: left;
        padding-top: .3em;
        padding-right: .1em; } }

    &_margin-top {
      margin-top: 30pt; } }

  &__agenda {
    position: absolute;
    bottom: 20pt;
    margin-left: 20pt;

    &Content {
      color: $color-report-purple;
      padding-left: .25in;
      border-left: 1pt solid $color-report-purple;

      h2, h3 {
        display: inline-block;
        font-family: $font-secondary-new;
        font-weight: $font-w-extra-light; } }

    &Logo {
      background: url($url-logo-purple-m) left / contain no-repeat;
      bottom: 50%;
      float: left;
      height: 27pt;
      margin: auto;
      position: absolute;
      top: 50%;
      width: 27pt; }

    &Title {
      @include font(12pt, 20pt);
      font-weight: $font-w-semi-bold;
      text-transform: uppercase;
      margin-bottom: .25in; }

    &Item,
    &Subitem {
      @include font(11pt, 13pt);
      font-weight: $font-w-semi-bold;
      margin-top: .1in; }

    &Subitem {
      padding-left: 5pt;

      &Dot {
        background: $color-report-purple;
        border-radius: 5pt;
        display: inherit;
        height: 4pt;
        margin-left: 5pt;
        margin-bottom: 2pt;
        width: 4pt; } }

    &Reset {
      bottom: 20pt;
      margin-left: 0;
      width: auto;

      .LongReport__agendaContent {
        border-bottom: 2pt solid $color-report-purple;
        border-left: 0;
        border-top: 2pt solid $color-report-purple;
        padding: .10in 0;
        padding-left: .45in; }

      .LongReport__agendaTitle {
        margin-bottom: 0; }

      .LongReport__agendaSubitem {
        margin-top: 0; } }

    &Reset {
      margin-left: 0;
      width: auto;
      &Content {
        border-left: 0; }

      &Title {
        margin-bottom: 0; }

      &Subitem {
        margin-top: 0; } } }

  &__driver {
    position: relative;

    &:first-of-type {
      margin-top: 25pt; }

    &:last-child {
      margin-bottom: 25pt; }

    &_top {
      margin-top: 25pt; }

    &_bordered {
      border-top: 2px solid $color-report-purple;
      padding: 5pt 15pt 5pt 0;

      &:last-child {
        border-bottom: 1.5pt solid $color-report-purple; }

      &.dominant {
        background: rgba($color-report-purple, .3);
        border: 3pt solid $color-black; }

      &.dominant + & {
        border-top: 0; }

      &.dominant ~ & {
        &:first-child {
          border-bottom: 0; } } }

    &Content {
      display: inline-block;
      padding-left: 10pt;
      vertical-align: middle;
      width: 76%;

      &_bordered {
        @include driver-border-colors;
        border-left: 1px solid;
        vertical-align: top;
        min-height: 80pt; } }

    &Icon {
      display: inline-block;
      height: 1.5in;
      vertical-align: middle;

      &_medium {
        height: 1.4in; }

      &_small {
        height: .7in; } }

    &Name {
      @include driver-colors;
      display: inline-block;
      margin-left: .1in;
      vertical-align: middle;

      &_uppercase {
        text-transform: uppercase; } }

    &Reset {
      display: inline-block;
      text-align: center;
      width: 33%;

      .LongReport__driverName {
        width: 100%; } } }

  &__line {
    border: 0;
    height: 2px;
    background: $color-report-purple;
    margin: .4in 0;

    &_thin {
      height: 1px;
      margin: .2in 0; }

    &_thick {
      height: 5px;
      margin: .2in 0; }

    &.who {
      background: $color-driver-who; }
    &.why {
      background: $color-driver-why; }
    &.how {
      background: $color-driver-how; } }

  &__equation {
    margin-bottom: 20pt;

    &Icon {
      display: inline-block;
      margin: 0 34pt;
      vertical-align: middle;

      & + &Label {
        bottom: 5pt; } }

    &Label {
      display: block;
      font-weight: $font-w-semi-bold;
      text-align: center;
      position: relative;

      &_bottom {
        margin-bottom: 10pt; } }

    &Driver {
      display: inline-block;
      position: relative;
      vertical-align: middle;

      &_bordered {
        padding-right: 10pt;
        vertical-align: top;
        margin-top: -10pt; } } }

  &__driverPage {
    height: 100%;
    position: relative;

    &Header {
      @include driver-colors;
      height: 90pt;
      width: 100%;

      &.dominant {
        color: $color-black; }

      &_bordered {
        position: relative;
        border-bottom: 6px solid;
        border-top: 6px solid;
        margin-top: .2in; }

      &_small {
        height: 40pt;
        position: absolute;
        top: .5in;
        padding: 5pt 0; }

      &Icon {
        position: absolute;
        height: 2in;
        top: -.5in;
        left: -.35in;

        &_small {
          height: 1in;
          top: -25pt;
          left: -8pt; } }

      &Content {
        padding-left: 1.7in;

        &_small {
          padding-left: 1in; } } }

    &Dominant {
      @include font(24pt, 25pt);
      font-weight: $font-w-semi-bold;
      position: absolute;

      &.first {
        top: -35pt;
        left: 1.7in; }

      &.second {
        left: 1in;
        top: 15pt; } }

    &Title {
      @include font(22pt, 25pt);
      font-weight: $font-w-bold;
      margin: 5pt 0;

      &_uppercase {
        text-transform: uppercase; } }

    &Subtitle {
      @include driver-colors;
      @include font(16pt, 15pt);
      font-family: $font-primary;
      font-weight: $font-w-semi-bold;

      &_bold {
        font-weight: $font-w-bold; } }

    &Quote {
      @include font(10pt, 13pt);
      display: block;
      font-family: $font-secondary-new;
      font-style: italic; }

    &Content {
      @include driver-border-colors;
      border-left: 1px solid;
      padding-left: .4in;
      margin-top: .4in;
      border-color: transparent;
      position: absolute;
      bottom: 20pt;
      top: 1.5in;

      &.dominant {
        padding: 0; } }

    &List {
      @include font-primary;
      margin-top: .1in;

      &Item {
        display: block;
        margin: .1in 0;
        font-family: $font-secondary-new;

        &:before {
          @include font(14pt, 14pt);
          content: "•";
          display: inline-block;
          padding-right: 10pt; }

        &Content {
          display: inline-block;
          vertical-align: top;
          width: 90%; } } }

    &Keywords {
      @include driver-colors;
      @include font(19pt, 26pt);
      font-weight: $font-w-bold;
      margin-top: 10pt;
      text-align: center;
      position: absolute;
      bottom: 0; }

    &Keyword {
      display: inline-block;

      &:not(:last-child):after {
        content: ",";
        margin-right: .1in; } } }

  &__powerUp {
    @include driver-border-colors;
    border: 1px solid;
    margin-bottom: 12pt;
    background: $color-white;

    &Title {
      @include driver-border-colors;
      border-bottom: 1px solid;
      height: 40pt;
      padding: 9pt;

      &.why {
        background: $color-driver-why-pale; }

      &.who {
        background: $color-driver-who-pale; }

      &.how {
        background: $color-driver-how-pale; }

      &Image {
        background: no-repeat 0 50% / contain url($url-purpose-power-up);
        height: 100%; } }

    &Content {
      padding: .2in; } }

  &__dominantBg {
    height: 100%;
    padding: 20pt;

    div {
      width: 100%;
      height: 100%;
      border: 3px solid; }

    &.why div {
      background: $color-driver-why-pale; }
    &.who div {
      background: $color-driver-who-pale; }
    &.how div {
      background: $color-driver-how-pale; } }

  &__excel,
  &__struggle {
    padding: 10pt;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: 40pt;
    width: calc(50% - 5pt); }

  &__excel {
    background: rgba($color-report-purple, .3);
    left: 0;
    margin-right: 5pt; }

  &__struggle {
    background: $color-report-purple;
    color: $color-white;
    margin-left: 5pt;
    right: 0; }

  &__framework {
    width: 100%;
    position: absolute;

    &Who2 {
      top: 124pt;
      position: relative; }

    &Why {
      position: relative;
      top: -15pt; }

    &How {
      position: relative;
      top: 40pt; } }

  &__biasesFooter {
    @include font(12pt, 15pt);
    border-top: 1px solid $color-report-purple;
    bottom: 0;
    padding-top: 10pt;
    position: absolute; }

  &__driverOverview {
    &Who,
    &Why,
    &How {
      position: absolute;
      width: 100%; }

    &Who {
      height: 60%;
      top: 250pt; }

    &Why {
      height: 40%;
      top: 400pt; }

    &How {
      height: 5.1in;
      top: 206pt;
      width: 5.1in;
      left: .5in; }

    &Group,
    &Text {
      @include font(13pt, 15pt);
      font-family: $font-secondary-new;
      font-weight: 200;

      &.who {
        height: 30%;
        padding: 30pt;
        padding-left: 130pt;

        &:not(:last-child) {
          margin-bottom: 15pt; } }

      &.why {
        padding: 5pt 0;
        text-align: center;

        &:not(:last-child) {
          margin-bottom: 18pt; } }

      &.how {
        @include font(11pt, 14pt);
        display: inline-block;
        height: 50%;
        margin-top: 5pt;
        padding: 20pt;
        width: 50%;
        vertical-align: top;

        &.right {
          text-align: right; }
        &.left {
          text-align: left; } } }

    &Text {
      @include font(12pt, 15pt);
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      width: 33%;

      &.bold {
        background: $color-white;
        font-family: $font-primary;
        font-weight: $font-w-semi-bold;
        padding: 5pt 0;
        width: 25%; } }

    &You {
      &Who,
      &Why,
      &How {
        position: absolute;
        font-weight: $font-w-semi-bold; }

      &Who {
        transform: rotate(-90deg);
        left: -25pt;

        &.Individual {
          top: .7in; }

        &.Organization {
          top: 2.6in; }

        &.Society {
          top: 4.6in; } }

      &Why {
        &.Karma {
          left: -25pt;
          top: -.6in;
          transform: rotate(-90deg); }

        &.Harmony {
          right: -25pt;
          top: -.5in;
          transform: rotate(90deg); } }

      &How {
        &.Human {
          left: 10pt;
          top: 5pt;
          transform: rotate(-40deg); }

        &.Knowledge {
          right: 30pt;
          top: -12pt;
          transform: rotate(40deg); }

        &.Community {
          bottom: 0;
          left: 18pt;
          transform: rotate(-312deg); }

        &.Structure {
          bottom: 0;
          right: 0;
          transform: rotate(313deg); } } } }

  &__info {
    &Driver {
      margin-left: 20pt;

      &.who {
        background: $color-driver-who-pale; }
      &.why {
        background: $color-driver-why-pale; }
      &.how {
        background: $color-driver-how-pale; } } }

  &__rights {
    bottom: 10pt; }

  &__rights &__agendaTitle {
    @include font(10pt, 12pt);
    font-weight: $font-w-extra-bold;
    margin-bottom: 5pt; }

  &__rights &__agendaItem {
    @include font(8pt, 10pt); }

  &__poster {
    &Bg {
      background: no-repeat center / contain;
      height: 93%;
      width: 94%;
      margin: auto;
      margin-top: .5in; }

    &Label {
      @include font(12pt, 14pt);
      font-weight: $font-w-bold;
      bottom: -36pt;
      right: 0;
      position: absolute; } }

  &__profileInfo {
    bottom: 10pt;
    padding-right: 33pt;
    position: absolute;
    width: 65%; }

  &__volunteering {
    &Driver {
      margin-top: 20pt; } }

  &__report {

    &Container {
      .LongReport__driver:first-of-type {
        margin-top: 0; }

      div {
        vertical-align: text-top; } } }

  &__markdown {
    ol {
      list-style: decimal inside; }

    ul {
      list-style: disc inside; }

    * {
      @include font-primary;
      text-align: justify;
      margin: 2rem 0;

      & + & {
        margin-top: .22in; } } }

  &__companyOverview {
    display: flex; } }

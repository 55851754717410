
@import "~theme/responsive/responsive";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/fonts";


.Modal {
  &__overlay {
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    transition: background-color .2s;
    z-index: 10000;
    -webkit-overflow-scrolling: touch;
    background-color: rgba($color-blue-purple-light, 0);

    @include screen-xs {
      padding: 0; }

    &.ReactModal__Overlay {
      &--after-open {
        background-color: rgba($color-blue-purple-light, 0.6); }

      &--before-close {
        background-color: rgba($color-blue-purple-light, 0); } } }

  &__content {
    background-clip: padding-box;
    background-color: $color-white;
    border-radius: 3px;
    margin: auto;
    padding: 5rem;
    opacity: 0;
    outline: 0;
    position: relative;
    filter: blur(3px);
    transition-duration: .3s;
    transition-property: filter, opacity;
    transition-timing-function: ease-out;
    max-width: 1000px;

    @include screen-xs {
      margin: 0 16px;
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      max-height: 90vh;
      overflow-y: scroll; }

    &.no-padding {
      padding: 0; }

    &.ReactModal__Content {
      &--after-open {
        opacity: 1;
        filter: none; }

      &--before-close {
        opacity: 0;
        filter: blur(3px); } } }

  &__body {
    padding: 5rem;
    position: relative;
    width: 100%;

    @include screen-xs {
      padding: 3rem;
      font-size: 90%; } }

  &__exit {
    @include font($font-s-1, $line-height-sm);
    color: $color-grey-heather;
    padding: 0;
    position: absolute;
    top: 5rem;
    right: 5rem;
    background-color: transparent;
    z-index: 5;

    @include screen-xs {
      @include font($font-s-0, $line-height-sm);
      top: 4rem; } }

  &__header {
    @include title;
    color: $color-white;
    padding: 3rem;
    text-align: center;
    margin: 0;
    background: $color-primary;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    &.white {
      background: $color-white;
      color: $color-primary;
      padding-bottom: 0; } }

  &__header &__exit {
    color: $color-white; } }

.ReactModal {
  &__Body--open {
    overflow: hidden; } }

@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-table";
@import "~theme/common-form";

.Invite {
  .Loader {
    background: $color-grey-imperceptible; }

  p {
    @include text-secondary; }

  h2 {
    @include subtitle-black; }

  &__heading {
    display: flex;
    justify-content: space-between;

    &Btns {
      margin: 0 -1rem;

      button,
      a {
        margin: 0 1rem; } } }

  &__title {
    @include title-blue-caps;

    a {
      color: inherit;

      .fa-chevron-left {
        font-size: .7em;
        margin-right: .5em; } } }

  &__subtitle {
    @include subtitle-black;
    margin-top: 3rem; }

  &__count {
    @include label; }

  &__notification {
    border: 1px solid $color-blue-playful;
    padding: 1rem 2rem;
    background-color: lighten($color-blue-playful, 50%);
    color: darken($color-blue-playful, 20%);
    font-weight: $font-w-medium;

    .fal {
      margin-right: 1rem; }

    & + & {
      margin-top: 3rem; }

    &.error {
      background-color: lighten($color-red, 50%);
      border-color: $color-red;
      color: darken($color-red, 20%); } } }

@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-form";

.RichTextEditor {
  @include input;
  border-radius: 3px;
  padding: 0;

  ol,
  ul {
    margin: 1rem 0; }

  &__input {
    padding: 2rem;
    min-height: 12rem; }

  &__button {
    color: $color-text-brown;
    border-radius: 3px;

    &:hover,
    &:focus {
      background: $color-grey-med; }

    &.active {
      background: $color-grey-med; }

    &-separator {
      margin-left: auto; } }

  &__controls {
    padding: 0 1rem;
    border-top: 1px solid transparent;
    display: flex;
    flex-flow: row wrap;
    position: relative;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    transition: all .3s;

    &.focused {
      border-color: $color-border;
      background: $color-bg; } }

  &__controls.focused &__button {
    color: $color-text-brown; }

  &__controls &__button {
    margin: 1rem;
    border-radius: 3px;
    color: $color-grey-heather-dark;

    &:hover,
    &:focus {
      background: $color-border; }

    &.active {
      background: $color-border;
      &:hover,
      &:focus {
        background: $color-grey-heather-dark; } } }

  &__block {
    &.header-four {
      @include font($font-s-1, $line-height-1);
      margin-bottom: 1rem; }

    &.blockquote {
      @include font($font-s-sm, $line-height-sm);
      display: flex;

      &:before {
        content: '';
        display: block;
        width: 4px;
        margin-right: 1rem;
        background: $color-border; }

      > div {
        flex: 1 1; } }

    &:not(.blockquote) + .blockquote,
    &.blockquote + &:not(.blockquote) {
      margin-top: 1rem; } } }

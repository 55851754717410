@import "~theme/btn/btn";
@import "~theme/clearfix";
@import "~theme/colors";
@import "~theme/type";

@mixin screen {
  @media only screen {
    @content; } }

@mixin driver-colors {
  &.who {
    color: $color-driver-who; }
  &.why {
    color: $color-driver-why; }
  &.how {
    color: $color-driver-how; } }

@mixin driver-background {
  &.who {
    background: $color-driver-who; }
  &.why {
    background: $color-driver-why; }
  &.how {
    background: $color-driver-how; } }

.LeadershipTypeReport {
  font-size: 12pt;

  @include screen {
    background: $color-grey-heather;
    padding-bottom: .5in;
    padding-top: .5in;
    min-width: 11in; }

  &__links {
    text-align: center; }

  &__pdfLink {
    display: none;

    @include screen {
      @include btn(primary, solid, $color-pink);
      display: inline-block;
      margin-bottom: .5in;
      margin-right: .2in;
      min-width: 50rem; } }

  &__heading {
    @include driver-colors;
    font-size: 42pt;
    font-weight: $font-w-bold;
    text-transform: uppercase;
    text-align: center; }

  &__subHeading {
    font-size: 18pt;
    line-height: 22pt;
    font-weight: $font-w-bold;
    text-align: center;
    margin: 0 1.1in;

    span {
      text-transform: uppercase; } }

  &__description {
    font-weight: $font-w-bold;
    text-align: center;
    line-height: 14pt;
    margin-top: 14pt; }

  &__quote {
    @include font(16pt, 21pt);
    font-style: italic;
    text-align: center;

    &Quote {
      margin-top: 15pt; }

    &Author {
      margin-bottom: 15pt; } }

  &__columns {
    h1 {
      font-size: 20pt;
      line-height: 28pt;
      font-weight: $font-w-bold;
      text-align: center;
      text-transform: uppercase; }

    &Header {
      @include driver-background;
      color: $color-white;
      padding: 5pt; }

    &Column {
      display: inline-block;
      width: 50%;
      vertical-align: top;

      & ~ & {
        border-left: 1px solid $color-white; } } }

  &__listItem {
    @include font(12pt, 15pt);
    display: block;
    text-align: left;
    padding: 2pt 10pt;

    & ~ & {
      border-top: none; }

    &:before {
      padding-right: 5pt; }

    &_bullet {
      &:before {
        @include font(14pt, 18pt);
        content: "•"; } } }

  &__footer {
    display: block;

    &Number {
      padding-right: 5pt; }

    &.right {
      text-align: right; }

    &.right &Number {
      padding-right: 5pt; } } }

@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/scaffolding/scaffolding";

.PeerNetwork {
  display: flex;
  align-items: center;
  gap: 8px;

  &__content {
    @include p-new;
    border-bottom: .5px solid $color-grey-30;
    display: flex;
    gap: 8px;
    align-items: center;
    min-height: 80px;
    flex: 1 1;

    @include screen-xs {
      min-width: 0;
      overflow: auto; } }

  &__peers {
    flex: 1 1;
    display: flex;
    align-self: stretch;
    gap: 8px;
    position: relative;
    position: relative;
    left: calc(-5rem - 8px);
    transition: left .3s;

    &.offset {
      left: 0; }

    &List {
      overflow: hidden;
      flex: 1 1;
      display: flex;
      &Content {
        position: relative;
        transition: left .5s;
        display: flex;
        gap: 8px;
        align-self: stretch; } }

    .btn-icon {
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      transition: all .2s;

      &.visible {
        opacity: 1;
        visibility: visible; } } }

  &__avatar {
    display: flex;
    background: none;
    border: none;
    padding: 0;
    outline: none;

    .Avatar {
      margin: auto; }

    &.active {
      border-bottom: 2px solid $color-primary-500;
      padding-top: 2px; }

    &:focus,
    &.focus-visible:focus:not(:focus-visible) {
      .Avatar {
        box-shadow: 0 0 0 2px $color-focus; } }

    &Archived.material-icons-outlined {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px; } }

  &__empty {
    display: flex;
    align-items: center;

    + p {
      margin-left: 8px; }

    .Avatar {
      position: relative;
      border: .5px solid $color-grey-30;
      background: $color-white;

      &:nth-child(1) {
        top: -18px; }
      &:nth-child(2) {
        top: 25px; }
      &:nth-child(3) {
        top: -10px; } } } }

@import "~theme/colors";
@import "~theme/btn/btn";

@mixin screen {
  @media only screen {
    @content; } }

.DownloadReportButton {
  button {
    display: none;

    @include screen {
      @include btn(primary, solid, $color-pink);
      display: inline-block;
      margin-bottom: .5in;
      margin-right: .2in;
      min-width: 50rem; }

    .fa-spin {
      margin-right: 2rem; } } }

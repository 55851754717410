@import "~theme/btn/btn";
@import "~theme/clearfix";
@import "~theme/colors";
@import "~theme/type";
@import "~theme/fonts";

@mixin screen {
  @media only screen {
    @content; } }


@mixin font-primary {
  @include font(12pt, 16pt);
  font-family: $font-secondary-new;
  font-weight: $font-w-extra-light; }

@mixin driver-border-colors {
  &.who {
    border-color: $color-driver-who; }
  &.why {
    border-color: $color-driver-why; }
  &.how {
    border-color: $color-driver-how; } }

@mixin driver-colors {
  &.who {
    color: $color-driver-who; }
  &.why {
    color: $color-driver-why; }
  &.how {
    color: $color-driver-how; } }

@mixin driver-color-text-background {
  color: $color-white;
  font-weight: $font-w-regular;
  &.who {
    background: $color-driver-who; }
  &.why {
    background: $color-driver-why; }
  &.how {
    background: $color-driver-how;
    font-weight: $font-w-medium; } }

@mixin label {
  @include font(8pt, 10pt);
  font-weight: $font-w-semi-bold;
  letter-spacing: 1px;
  color: $color-black;
  background: $color-white;
  text-transform: uppercase;
  padding: .5rem 1rem;
  border-radius: 3px; }

.ColleagueGuide {
  font-size: 12pt;

  @include screen {
    background: $color-grey-heather;
    padding-bottom: .5in;
    padding-top: .5in;
    min-width: 11in; }

  &__links {
    text-align: center; }

  &__pdfLink {
    display: none;

    @include screen {
      @include btn(primary, solid, $color-pink);
      display: inline-block;
      margin-bottom: .5in;
      margin-right: .2in;
      min-width: 50rem; } }

  &__text-color {
    font-size: 22pt;
    font-weight: $font-w-semi-bold;
    text-transform: uppercase;
    text-align: center;
    margin-top: 10pt;
    position: relative; }

  &__heading {
    text-transform: uppercase;
    font-weight: $font-w-regular; }

  &__description {
    @include font(11pt, 14pt);
    font-family: $font-secondary-new;
    text-align: justify; }

  &__icons {
    position: absolute;
    bottom: 80%;
    right: 5%;
    font-size: 30pt;

    span {
      margin-left: 8pt; } }

  &__text-color {
    @include driver-colors; }

  &__heading {
    text-align: center;
    margin-bottom: 10pt; }

  &__coachee {
    text-transform: uppercase;
    font-weight: $font-w-regular;

    &:after {
      content: '';
      display: inline-block;
      border-bottom: 1px solid $color-black;
      width: 2in;
      margin-left: 5pt; } }

  &__goals {
    display: table;
    border-spacing: 0;
    height: 65pt;
    padding-right: 25pt;
    margin: 10pt 0;

    &_item,
    &_title {
      padding: 8pt;
      border: 2pt solid $color-white;
      text-align: center;
      color: $color-white; }

    &.who & {
      &_item,
      &_title {
        background: $color-driver-who; }

      &_item {
        &ContentBackground {
          background: $color-driver-who; }

        &0,
        &1,
        &2 {
          border: 4pt solid $color-driver-who; } } }

    &.why & {
      &_item,
      &_title {
        background: $color-driver-why; }

      &_item {
        &ContentBackground {
          background: $color-driver-why; }

        &0,
        &1,
        &2 {
          border: 4pt solid $color-driver-why; } } }

    &.how & {
      &_item,
      &_title {
        background: $color-driver-how; }

      &_item {
        &ContentBackground {
          background: $color-driver-how; }

        &0,
        &1,
        &2 {
          border: 4pt solid $color-driver-how; } } }

    &_title {
      font-size: 12pt;
      display: table-cell;
      width: 10%;
      vertical-align: middle;
      font-weight: $font-w-semi-bold; }

    &_item {
      font-size: 10pt;
      font-family: $font-secondary-new;
      font-weight: $font-w-regular;
      display: table-cell;
      width: 30%;
      vertical-align: middle;
      position: relative;

      border: 0;

      &Content {
        z-index: 1;

        &Background {
          width: 100%;
          height: 100%;
          border: 2.5pt solid white; } }

      &1,
      &2 {
        padding-left: 40pt; }

      &:before,
      &:after {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        z-index: 2; }

      &:before {
        border-width: 38pt;
        top: -8.5pt;
        border-left-color: $color-white;
        left: 100.3%; }

      &:after {
        border-width: 33.3pt;
        top: -4pt; }

      &.who:after {
        border-left-color: $color-driver-who; }
      &.why:after {
        border-left-color: $color-driver-why; }
      &.how:after {
        border-left-color: $color-driver-how; } } }

  &__questions {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 3px;

    th {
      font-weight: $font-w-semi-bold;
      letter-spacing: .5pt;
      padding: 5pt 7pt;

      &:first-child {
        @include driver-colors; }

      &:last-child {
        text-align: center; } }

    th,
    td {
      @include driver-border-colors;
      border-width: 1px;
      border-style: solid;
      text-align: left;

      &:last-child {
        @include driver-color-text-background; } }

    td {
      @include font(10pt, 12pt);
      font-family: $font-secondary-new;
      padding: 5pt;

      &:first-child {
        vertical-align: top; }

      &:last-child {
        width: 25%; }

      span {
        display: block;
        min-height: 80pt; } } }

  &__quote {
    text-align: center;
    font-weight: $font-w-regular;
    margin-bottom: 10pt;

    &Value {
      font-style: italic; } }

  &__action {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 3px;

    td {
      @include driver-border-colors;
      @include font(10pt, 12pt);
      padding: 5pt;
      border-width: 1px;
      border-style: solid;
      text-align: left;

      &:first-child {
        @include driver-color-text-background;
        font-weight: $font-w-semi-bold;
        text-transform: uppercase;
        font-size: 20pt;
        letter-spacing: .5pt;
        width: 10%;
        text-align: center;

        span {
          writing-mode: vertical-lr;
          -ms-writing-mode: tb-rl;
          transform: rotate(180deg); } }

      &:last-child {
        font-family: $font-secondary-new;
        vertical-align: top;

        span {
          display: block;
          min-height: 150pt; } } } }

  &__footer {
    &_left {
      @include font(7pt, 9pt);
      color: $color-black;
      font-weight: $font-w-regular;
      display: inline-block;
      vertical-align: bottom;
      width: 80%;
      padding-right: 25%; }

    img {
      width: 20%;
      display: inline-block;
      vertical-align: bottom; } } }

@import "~theme/colors";
@import "~theme/btn/btn";

@mixin screen {
  @media only screen {
    @content; } }

.PurposeSummarySample {
  @include screen {
    position: relative;
    background: $color-grey-heather;
    padding-bottom: .5in;
    padding-top: .5in;
    min-width: 11in; }

  .Loader {
    align-items: flex-start;
    padding-top: 40vh;
    bottom: 0; }

  &__links {
    text-align: center; }

  &__pdfLink {
    display: none;

    @include screen {
      @include btn(primary, solid, $color-pink);
      display: inline-block;
      margin-bottom: .5in;
      margin-right: .2in;
      min-width: 50rem; } }

  .LongReport,
  .ShortReport {
    &:first-child {
      @include screen {
        padding-top: 0; } } } }

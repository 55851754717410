@import "~theme/clearfix";
@import "~theme/responsive/responsive";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/fonts";
@import "./scaffolding";


// common elements
.p-new {
  @include p-new;

  &.bold {
    font-weight: $font-w-semi-bold; } }

.p-2 {
  @include p-2; }
.p-bold {
  @include p-semi-bold; }

.p {
  @include p;

  &_lg {
    @include p_lg; }

  &_sm {
    @include p_sm; }

  &_narrow {
    @include p_narrow; } }

.h1 {
  @include h1;

  &_sm {
    @include h1_sm; } }

.h2 {
  @include h2;

  &_lg {
    @include h2_lg; }

  &_sm {
    @include h2_sm; } }

.h2 {
  @include h2;

  &_lg {
    @include h2_lg; }

  &_sm {
    @include h2_sm; } }

.h3 {
  @include h3;

  &_sm {
    @include h3_sm; } }

.h4 {
  @include h4;

  &_sm {
    @include h4_sm; } }


.ul {
  @include ul;

  &_aligned {
    @include ul_aligned; }

  &_justified {
    @include ul_justified; }

  &_color {
    &_purple {
      @include ul_color($color-purple-playful); }
    &_pink {
      @include ul_color($color-pink); } } }


.link {
  @include link;

  &_white {
    @include link($color-white); }

  &_purple {
    @include link($color-purple-playful); }

  &_pink {
    @include link($color-pink-bright); }

  i:last-child {
    margin-left: 1rem;
    font-size: .9em; } }

.link-s2 {
  @include link;
  @include font(14px, 22px);
  font-weight: $font-w-semi-bold; }

// type stuff
.font-s-xs {
  @include font($font-s-xs, $line-height-xs); }

.font-s-sm {
  @include font($font-s-sm, $line-height-sm); }

.font-s-0 {
  @include font($font-s-0, $line-height-0); }

.font-s-1 {
  @include font($font-s-1, $line-height-1); }

.font-s-2 {
  @include font($font-s-2, $line-height-2); }

.font-s-3 {
  @include font($font-s-3, $line-height-3); }

.font-s-4 {
  @include font($font-s-4, $line-height-4); }

.font-s-5 {
  @include font($font-s-5, $line-height-5); }

.font-s-6 {
  @include font($font-s-6, $line-height-6); }

.font-s-7 {
  @include font($font-s-7, $line-height-7); }

.font-s-8 {
  @include font($font-s-8, $line-height-8); }

.font-s-9 {
  @include font($font-s-9, $line-height-9); }

.font-s-italic {
  font-style: italic; }

.font-w-light {
  font-weight: $font-w-light !important; }
.font-w-regular {
  font-weight: $font-w-regular !important; }
.font-w-medium {
  font-weight: $font-w-medium !important; }
.font-w-bold {
  font-weight: $font-w-bold !important; }
.font-w-black {
  font-weight: $font-w-black !important; }

.font-s-italic {
  font-style: italic; }

.font-s-none {
  font-style: initial; }

.font-f-primary {
  font-family: $font-primary-new; }

.font-f-secondary {
  font-family: $font-secondary-new; }

.font-primary {
  @include text-primary; }

.text-grey {
  @include text-grey; }

.text-xs {
  @include font($font-s-xs, $line-height-xs); }

.title-description {
  @include title-description; }

// margin helpers
.margin-top-1 {
  margin-top: 1rem; }

.margin-top-2 {
  margin-top: 2rem; }

.margin-top-3 {
  margin-top: 3rem; }

.margin-top-4 {
  margin-top: 4rem; }

.margin-top-5 {
  margin-top: 5rem; }

.margin-top-6 {
  margin-top: 6rem; }

.margin-top-7 {
  margin-top: 7rem; }

.margin-top-8 {
  margin-top: 8rem; }

.margin-top-9 {
  margin-top: 9rem; }

.margin-top-10 {
  margin-top: 10rem; }

.margin-top-11 {
  margin-top: 11rem; }

.margin-top-12 {
  margin-top: 12rem; }


.margin-bottom-1 {
  margin-bottom: 1rem; }

.margin-bottom-2 {
  margin-bottom: 2rem; }

.margin-bottom-3 {
  margin-bottom: 3rem; }

.margin-bottom-4 {
  margin-bottom: 4rem; }

.margin-bottom-5 {
  margin-bottom: 5rem; }

.margin-bottom-6 {
  margin-bottom: 6rem; }

.margin-bottom-7 {
  margin-bottom: 7rem; }

.margin-bottom-8 {
  margin-bottom: 8rem; }

.margin-bottom-9 {
  margin-bottom: 9rem; }

.margin-bottom-10 {
  margin-bottom: 10rem; }

.margin-bottom-11 {
  margin-bottom: 11rem; }

.margin-bottom-12 {
  margin-bottom: 12rem; }


// colors
.color-purple-purpose {
  color: $color-purple-purpose !important; }
.color-grey-albatross {
  color: $color-grey-albatross !important; }
.color-grey-soft {
  color: $color-grey-soft !important; }
.color-grey-heather {
  color: $color-grey-heather !important; }
.color-white {
  color: $color-white !important; }
.color-black {
  color: $color-black !important; }
.color-purple-passion {
  color: $color-purple-passion !important; }
.color-purple-passion-light {
  color: $color-purple-passion-light !important; }
.color-purple-passion-dark {
  color: $color-purple-passion-dark !important; }
.color-purple-playful {
  color: $color-purple-playful !important; }
.color-blue-playful {
  color: $color-blue-playful !important; }
.color-blue-playful-light {
  color: $color-blue-playful-light !important; }
.color-green-bright {
  color: $color-green-bright !important; }
.color-yellow-calm {
  color: $color-yellow-calm !important; }
.color-yellow-calm-dark {
  color: $color-yellow-calm-dark !important; }
.color-yellow-calm-light {
  color: $color-yellow-calm-light !important; }
.color-pink {
  color: $color-pink !important; }
.color-primary {
  color: $color-primary !important; }
.color-pink-bright {
  color: $color-pink-bright !important; }
.color-facebook {
  color: $color-facebook !important; }
.color-twitter {
  color: $color-twitter !important; }
.color-github {
  color: $color-github !important; }
.color-linkedin {
  color: $color-linkedin !important; }
.color-error {
  color: $color-error !important; }


.hyphens {
  hyphens: auto; }

.no-hyphens {
  hyphens: none; }

.uppercase {
  text-transform: uppercase; }

.bullet {
  @include bullet; }

.no-wrap {
  white-space: nowrap; }

.text-center {
  text-align: center; }

.text-center-xs {
  @include screen-xs {
    text-align: center; } }

.text-center-sm-minus {
  @include screen-sm-minus {
    text-align: center; } }

.text-justify {
  text-align: justify; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-underline {
  text-decoration: underline; }

.text-bold {
  @include text-bold; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

.clear {
  clear: both; }

.clearfix {
  @include clearfix; }

.grey-section {
  background: $color-grey-imperceptible;
  padding: 3rem; }

.confirm-modal {
  .title {
    @include subtitle-black; }

  p strong {
    margin: 0 1rem; }

  .btns {
    text-align: center;
    margin: -2rem;
    margin-top: 0;

    @include screen-xs {
      margin: 0; }

    button,
    a {
      display: inline-block;
      margin: 2rem;

      @include screen-xs {
        display: block;
        width: 100%;
        margin: 2rem 0; } } } }

.section-head {
  @include section-head;

  + p {
    margin-top: 20px; } }

.section-intro {
  @include section-intro; }

.kicker {
  @include font(11px, 14.3px);
  font-weight: $font-w-semi-bold;
  letter-spacing: 0.65px;
  color: $color-text-grey;
  text-transform: uppercase; }

.navItem {
  @include font(14px, 22.4px);
  letter-spacing: 0.2px;
  font-weight: $font-w-regular;
  color: $color-text-grey;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 16px 8px 32px;

  &:hover,
  &.active {
    background: rgba($color-primary, .07);
    color: $color-primary; }

  i {
    @include font(20px, 20px);
    margin-right: 10px; } }

.page-title {
  @include page-title;

  &.blue {
    color: #4F62C8; }

  &.bold {
    font-weight: $font-w-bold; } }

.caption-caps {
  @include caption-caps; }

.caption-new {
  @include caption; }

.placeholder {
  @include font(rem-size(16px), rem-size(22.6px));
  font-weight: $font-w-regular;
  color: $color-grey-60; }

.toast {
  @include toast; }

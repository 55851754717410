@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/scaffolding/scaffolding";

.Purpose {
  padding: 24px 0;

  .p-new {
    margin-bottom: 16px; }

  .p-2 {
    margin: 4px 0; }

  .page-title {
    margin-bottom: 24px; }

  .color-primary-600 {
    color: $color-primary-600; }

  .caption-new {
    font-weight: $font-w-semi-bold; }

  .card.padding-16 {
    padding: 16px; }

  .section-head {
    margin-top: 32px; }

  .card {
    .page-title {
      text-align: center;
      color: $color-grey-90;
      margin-bottom: 0;
      &.color-grey-60 {
        color: $color-grey-60; }
      .x {
        @include section-intro;
        color: inherit; } } }

  &__row {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;

    &.gap-4 {
      gap: 4px; } }

  &__section {
    flex: 1 1;

    .btn-primary.pink {
      display: inline-block;
      margin-top: 16px; } }

  &__section-2 {
    flex: 2 2; }

  &__leadership,
  &__driver,
  &__imperative {
    display: flex;
    align-items: center;
    justify-content: center;
    &.card.card-color-grey-50 {
      padding: 8px; } }

  &__driver {
    flex: 1 1;
    &.card.card-color-grey-50 {
      border-radius: 0;
      &:first-child {
        border-top-left-radius: 8px; }
      &:nth-child(3) {
        border-top-right-radius: 8px; } }
    img {
      max-height: 40px; } }

  &__leadership {
    flex: 1 1 100%;
    &.card.card-color-grey-50 {
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &:not(:last-child) {
        border-radius: 0; } }
    img {
      max-width: 272px; } }

  &__imperative {
    text-align: center;
    &.card.card-color-grey-50 {
      border-top-left-radius: 0;
      border-top-right-radius: 0; } }

  &__avatar {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    img {
      max-width: 190px; }

    .section-intro {
      text-align: center;

      &.color-primary-600 {
        font-weight: $font-w-semi-bold; }

      &:not(.color-primary-600) {
        margin: 24px 0; } } } }

@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/img-url";

$progress-unit: 1rem;
$progress-h: rem-size(80px);
$progress-w: 120rem;
$logo-h: rem-size(72px);
$logo-w: rem-size(155px);
$logo-w-mobile: rem-size(48px);
$logo-white: $url-logo-white-newer;
$logo-white-mobile: $url-logo-white-mobile;

.Progress {
  &__progress {
    background: $color-header-blue;
    min-height: $progress-h;
    position: absolute;
    width: 100%;
    z-index: 9;

    &Content {
      height: 100%;
      display: flex;
      align-items: center; } }

  &__exit {
    width: $logo-w;
    text-align: right;

    @include screen-xs {
      width: auto; }

    button {
      @include font($font-s-xs-sm, $line-height-sm);
      color: $color-white;
      font-weight: $font-w-semi-bold;
      background: none;
      border: none;
      outline: none;
      padding: 0;

      &:focus {
        text-decoration: underline; } }

    span {
      @include font($font-s-0, $line-height-sm);
      margin: 0 1rem;
      vertical-align: middle; } }

  &__logo {
    background: url($logo-white) center / contain no-repeat;
    height: $logo-h;
    width: 100%;

    @include screen-xs {
      background: url($logo-white-mobile) center / contain no-repeat;
      width: $logo-w-mobile; }

    &Container {
      width: $logo-w;

      @include screen-xs {
        width: auto;
        margin-left: 0; } } }

  &__container {
    @include font($font-s-0, $line-height-sm);
    flex: 1;
    max-width: 100%; //IE
    margin: auto 10rem;
    height: $progress-h;
    display: flex;
    align-items: center;
    color: $color-white;
    font-weight: $font-w-semi-bold;

    span {
      @include screen-xs {
        display: none; } } }

  &__progressContainer {
    background: darken($color-driver-who, 10%);
    border-radius: $progress-unit + (1rem / 3);
    padding: 0 $progress-unit;
    flex: 1;
    margin: 0 2rem; }

  &__progressValue {
    background-color: $color-white;
    border-radius: $progress-unit;
    box-sizing: content-box;
    margin-left: -$progress-unit;
    padding: $progress-unit;
    transition: width .3s; } }

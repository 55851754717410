@import "~theme/colors";

.SkipLink {
  position: absolute;
  top: -40px;
  left: 0;
  background-color: $color-grey-dark;
  font-size: 14px;
  color: $color-white;
  padding: 4px 18px;
  z-index: 999;
  text-decoration: none;
  opacity: 0;
  transition: opacity 0.2s;

  &:focus {
    top: 0;
    opacity: 1; } }

@import "~theme/responsive/responsive";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/scaffolding/scaffolding";
@import "~theme/fonts";
@import "~theme/btn/btn";
@import "~theme/grid/grid";

.NameCard {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;

  &__flow_column {
    flex-flow: column;
    align-items: center; }

  &__flow_row {
    @include screen-sm-plus {
      flex-flow: row wrap;
      align-items: flex-start;
      gap: 24px; } }

  &__flow_column &__content + &__content {
    margin-top: 3rem;

    @include screen-xs {
      margin-top: 0; } }

  &__flow_row & {
    @include screen-sm-plus {
      &__content {
        align-items: flex-start; }
      &__info {
        margin: 0; } } }

  &__content {
    display: flex;
    flex-flow: column;
    align-items: center; }

  .section-head {
    margin-bottom: 0;

    + p {
      margin: 0; } }

  &.archived {
    .link.p-new {
      filter: blur(4px); } }

  .Avatar &__archivedIcon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px; }

  &.archived & {
    &__drivers,
    &__info {
      filter: blur(4px); } }


  &__info {
    display: inline-block;
    text-align: left;
    margin: auto; }

  &__row {
    @include p-new;
    margin-top: 5px;

    &:first-child {
      margin-top: 0; }

    i {
      @include font(20px, 20px);
      margin-right: 2rem;
      vertical-align: middle; } }

  &__btns {
    display: flex;
    margin-top: 16px;

    a + a {
      margin-left: 1rem; }

    .btn-secondary {
      padding: 8px 12px; } }

  &__drivers {
    background: $color-grey-50;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    padding: 8px;
    margin: 18px 0;

    span {
      @include p-2;
      color: $color-grey-60;
      margin: 0 8px;
      &.dominant {
        font-weight: $font-w-semi-bold;
        color: $color-grey-90; } } } }

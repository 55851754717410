@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-table";
@import "~theme/common-form";

.Conversations {
  @include table;

  p {
    @include text-secondary;
    font-weight: $font-w-light; }

  h2 {
    @include subtitle-black; }

  h4 {
    @include label; }

  table {
    overflow: visible; }

  tr.active {
    background: rgba($color-border, .5); }

  tr.opened {
    background: rgba($color-primary, .05); }

  &__title {
    @include title-blue-caps;

    @include screen-xs {
      @include font($font-s-0, $line-height-0); }

    &-btnBack {
      color: inherit;
      &:hover i {
        transform: scale(1.8); }
      i {
        transition: all 0.2s; } } }

  &__link {
    margin-top: 2rem; }

  &__label {
    @include label; }

  .relative {
    width: 100%; }

  &__heading {
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: space-between;

    &Filter {
      margin-bottom: 3rem; }

    &>div {
      @include screen-xs {
        width: 100%; } } }

  &__options {
    display: flex;
    flex-direction: column;
    a, button {
      @include screen-xs {
        width: 100%; } }

    .left {
      margin-left: 2rem;
      @include screen-xs {
        margin-left: 0;
        margin-top: 1rem; } }

    @include screen-xs {
      margin-bottom: 3rem; } }

  &__table {
    width: 100%;

    &.members {
      tr {
        cursor: auto;
        &:hover {
          background: none; } }

      td {
        vertical-align: top;
        position: relative; } } }

  &__table.members tr:hover &__tooltip {
    opacity: 1;
    visibility: visible; }

  &__cell {
    &-light {
      color: $color-grey-heather; } }

  &__tooltip {
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    z-index: 9;
    overflow: auto;
    max-height: 30rem;
    width: 50rem;
    position: absolute;
    right: 0;
    background: $color-white;
    border: 1px solid $color-border;
    box-shadow: 0 3px 5px 0 rgba($color-black, .3);
    padding: 2rem 1rem;

    &:hover {
      opacity: 1;
      visibility: visible; } }

  &__select {
    @include select;
    flex: 0 0 60rem;
    margin-bottom: 2rem;

    @include screen-xs {
      flex: 1 1 100%; } }

  &__avatar {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;

    &-warning {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
      color: $color-error; } }

  &__subtitle {
    @include subtitle-black;
    margin-top: 3rem; }

  &__conversationName {
    h3 {
      @include text-bold; }

    span {
      @include text-grey;
      text-transform: none; } }

  &__row {
    display: flex;
    flex-flow: row wrap;
    margin: -3rem; }

  &__rate {
    @include label;
    margin-left: 1rem; }

  &__matchingGroup {
    &.opened {
      background: none !important; } }

  &__innerHeader.opened {
    td {
      @include font($font-s-xs, $line-height-xs);
      font-weight: $font-w-regular;
      text-transform: uppercase;
      text-align: left; } }

  &__more {
    td {
      border: 0 !important; }

    &.closed {
      display: none; } }

  &__actions {
    display: flex; }

  &__action {
    position: relative;

    &:hover &-tooltip {
      opacity: 1;
      visibility: visible; }

    &-tooltip {
      position: absolute;
      top: -100%;
      right: calc(-50% + 0.5rem);
      @include font($font-s-xs, $line-height-sm);
      width: max-content;
      padding: 0 1rem;
      background: mix(white, $color-primary, 65%);
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s; } }

  &__member {
    padding: 2rem;
    display: flex;
    align-items: flex-start;

    &:first-child {
      padding-top: 3rem; }

    &-name {
      flex: 0 0 40rem;

      .Avatar {
        flex: 0 0 auto; } }

    &-info {
      display: flex;
      flex: 1 1;
      margin-left: 2rem;

      &Item {
        padding: 0 2rem;
        flex: 0 0 calc(100% / 5); } }

    &-warning {
      color: $color-error; } }

  &__cohort {
    &-archived {
      font-size: 75%;
      background: $color-grey-pumice;
      color: $color-black;
      border-radius: 4px;
      text-align: center;
      display: block;
      width: 10rem; } }

  &__toggler {
    @include text-primary;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;

    @include screen-xs {
      justify-content: flex-start; }

    span {
      color: $color-grey-dark-med;

      &:first-child {
        text-align: right; }

      &.active {
        color: $color-primary;
        text-shadow: 0 0 1px $color-primary; } }

    &-bar {
      margin: 0 2rem;
      height: 4rem;
      width: 10rem;
      background: $color-grey-imperceptible;
      border-radius: 5rem;
      box-shadow: inset 0 0 5px 0 $color-grey-heather;
      position: relative;
      cursor: pointer; }

    &-handle {
      width: 3rem;
      height: 3rem;
      background: $color-primary;
      position: absolute;
      left: .5rem;
      top: .5rem;
      border-radius: 50%;
      transition: left .3s;

      &.right {
        left: calc(100% - 3rem - .5rem); } } } }

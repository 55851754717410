@import "~theme/btn/btn";
@import "~theme/clearfix";
@import "~theme/responsive/responsive";
@import "~theme/scaffolding/scaffolding";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/img-url";


$header-h: rem-size(60px);
$header-logo-w: rem-size(155px);
$header-logo-h: rem-size(40px);
$header-logo-w-mobile: rem-size(48px);
$header-item-h: $line-height-1;


.NavItem {
  $self: &;

  @mixin color-pink {
    @at-root #{$self}.pink & {
      @content; } }

  @mixin color-blue {
    @at-root #{$self}.blue & {
      @content; } }

  &__link {
    @include p-semi-bold;
    @include font(14px, $header-h);
    height: $header-h;
    letter-spacing: 0.2px;
    text-align: left;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 21px;
    top: -2px;
    transition: color .2s;

    &:before {
      content: '';
      background: $color-primary;
      width: 0;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: all .2s;

      @include color-pink {
        background: $color-secondary; } }

    &:focus {
      text-decoration: none;
      &:before {
        width: 100%; } }

    &:hover,
    &:focus,
    &.focus-visible:focus:not(:focus-visible) {
      color: $color-primary;
      font-weight: $font-w-bold;
      @include color-pink {
        color: $color-secondary; } }
    &:focus:not(:focus-visible) {
      color: unset;
      font-weight: $font-w-semi-bold; }

    &.active {
      color: $color-primary;
      font-weight: $font-w-bold;

      @include color-pink {
        color: $color-secondary; }

      &:before {
        width: 100%; } }

    .fa-chevron-down,
    .fa-chevron-right {
      margin-left: 5px;
      font-size: .9em; }

    i:first-child {
      margin-right: 10px; }

    > i {
      @include font(20px, normal); }

    span[title] {
      &:before {
        display: block;
        content: attr(title);
        font-weight: $font-w-bold;
        height: 0;
        overflow: hidden;
        visibility: hidden; } } } }

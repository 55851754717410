@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-table";
@import "~theme/common-form";

.Workshops {
  @include table;

  table {
    tr {
      button {
        opacity: .1;
        transition: opacity .3s; }

      &:hover button {
        opacity: 1; } } }

  &__title {
    @include title-blue-caps;
    margin: 0; }

  &__label {
    @include label;
    margin-top: 2rem; }

  &__select {
    @include select;
    flex: 0 0 60rem;

    @include screen-xs {
      flex: 1 1 100%;
      margin-bottom: 2rem; } }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
    margin-bottom: 3rem; }

  &__actions {
    width: 60rem;
    text-align: right;

    @include screen-xs {
      width: auto;
      text-align: center; }

    button {
      margin: 1rem; } } }

@import "~theme/clearfix";
@import "~theme/responsive/responsive";
@import "~theme/scaffolding/scaffolding";
@import "~theme/colors";
@import "~theme/btn/btn";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/fonts";
@import "~theme/common-form";


.Community {
  @include font(16px, 25.6px);
  color: $color-text-grey;

  &__tip.tippy-box {
    .tippy-content {
      @include font(12px, 18px);
      border-radius: 4px;
      padding: 5px 10px;
      background: $color-grey-90;
      font-weight: $font-w-semi-bold;

      * {
        font-weight: inherit; } } }

  &.page {
    background: $color-white;
    padding-bottom: 0; }

  p, li {
    @include p-new; }

  .Footer.static {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; }

  .link {
    padding: 0; }

  li .link {
    margin-left: 1rem; }

  &__icon-stacked {
    position: relative;
    i:first-child {
      font-size: 17px; }
    i:last-child {
      font-size: 10px;
      position: absolute;
      left: 13px;
      top: 10px; } }

  &__invitee {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0 16px;
    & + & {
      margin-top: 24px;
      padding-top: 16px;
      border-top: 1px solid $color-border; }

    &Error {
      @include toast;
      flex: 1 1 100%; }

    &Fields {
      flex: 1 1;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      gap: 16px;

      @include screen-xs {
        grid-template-columns: 1fr; }

      label {
        @include p-2;
        line-height: $line-height-sm;
        font-weight: $font-w-semi-bold;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        color: $color-primary;
        display: block; } }

    .btn-secondary {
      border-radius: 50%;
      padding: 0;
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
      margin-top: 24px;
      i {
        font-size: .8em; } }

    .Form__field {
      input,
      textarea {
        border-radius: 4px; }

      textarea {
        padding: 1rem 2rem;
        line-height: 3.5rem;
        resize: none;
        min-height: 10rem; }

      &:last-child {
        grid-column: 1 / span 2;
        @include screen-xs {
          grid-column: 1; } }

      + .Form__field {
        margin-top: 0; } }

    .Form__select {
      &.react-select__control {
        border-radius: 4px !important; }
      &.react-select__menu {
        border-radius: 4px !important; } } }

  &__inviteBtns {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: start;
    gap: 8px;
    margin-top: 24px;

    .btn-secondary.blue {
      @include font(12px, 14px);
      padding: 6px 12px; } }

  &__placeholder {
    @include p-new;
    color: $color-grey-60; }

  &.page > .container {
    padding: 0;
    display: grid;
    grid-template-columns: 256px 1fr;
    min-height: calc(100vh - #{$header-h});

    @include screen-xs {
      grid-template-columns: 50px 1fr; }

    > .container {
      min-width: {}
      margin: 0; } }

  &__page {
    flex: 1 1;
    margin-top: 48px;
    padding-bottom: calc(15rem + 24px);
    position: relative;
 }    // overflow: hidden

  &__nav {
    width: 256px;
    flex: 0 0 auto;
    border-right: 2px solid $color-grey-50;
    padding-top: 36px;

    @include screen-xs {
      width: 50px; }

    .kicker {
      padding: 8px 16px 8px 32px;
      padding-top: rem-size(48px);

      @include screen-xs {
        padding-left: 8px;
        padding-right: 8px; } }

    .navItem {
      @include screen-xs {
        padding: 8px;
        justify-content: center;
        * {
          margin: 0; }
        *:not(:first-child) {
          display: none; } }

      &.active,
      &:focus,
      &.focus-visible:focus:not(:focus-visible) {
        color: $color-primary;
        font-weight: $font-w-bold;

        i.account {
          font-family: 'Material Icons'; } } }

    &Title {
      display: flex;
      align-items: center;

      &Label {
        @include label;
        @include font(9px, 12px);
        padding: 2px 8px;
        margin-left: 8px;
        display: flex;
        border-radius: 4px;
        align-items: center;
        border: 1px solid $color-error-new;
        color: $color-error-new;
        background: rgba($color-error-new, .1);
        font-weight: $font-w-semi-bold; } } }

  &__title {
    margin-bottom: 5rem;

    p {
      @include font(20px, 30px); }

    h1 {
      display: inline-flex;
      gap: 8px;
      align-items: center;

      i {
        font-size: .7em;
        font-weight: 300; } } }


  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 40px; }

  &__card {
    border: 1px solid $color-border;
    color: inherit;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    padding: 24px;

    &:hover,
    &:focus {
      border: 0.5px solid #4F62C8;
      box-shadow: 0px 2px 4px rgba($color-black, 0.06), 0px 4px 6px rgba($color-black, 0.1);

      i {
        color: $color-primary; } }

    h2 {
      @include font(20px, 35px);
      font-weight: $font-w-semi-bold; }

    i {
      font-size: 40px;
      margin-left: 10px; }

    span {
      @include p-new;
      color: $color-primary;
      display: block;
      margin-top: 8px; }

    &Text {
      flex: 1 1; } }

  &__toast {
    padding: rem-size(8px) rem-size(16px);
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-weight: $font-w-regular;
    margin-bottom: 3rem;
    &.success {
      background: rgba($color-success-new, .07);

      i {
        color: $color-success-new;
        margin-right: 1rem; } } }

  &__invite {
    form .Loader {
      background: rgba($color-white, .5); }

    &Modal {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      gap: 8px;

      @include screen-xs {
        flex-flow: column; }

      img {
        width: 20%; }

      div {
        flex: 1 1;

        ul {
          margin-top: 8px;
          list-style: disc inside; } }

      .text-center {
        flex: 1 1 100%;
        button {
          margin: auto; } } } }

  &__invites {
    &List {
      display: grid;
      gap: 8px; }

    &Member {
      @include p-new;
      font-weight: $font-w-medium;
      display: grid;
      grid-template-columns: 1.5fr 200px 200px;
      align-items: center;
      gap: 16px;
      background: $color-grey-50;
      border-radius: 4px;
      padding: 8px 16px;

      @include screen-sm-minus {
        grid-template-columns: 1fr 1fr; }

      &Name {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: $font-w-semi-bold;

        @include screen-sm-minus {
          grid-column: 1 / span 2; }

        p {
          display: flex;
          align-items: center;
          gap: 16px; }

        &_bold {
          flex: 1 1; } }

      &Convs {
        text-align: right; }

      &Date {
        &.green {
          color: $color-success-new;
          font-weight: $font-w-semi-bold; } }

      &Cell {
        @include p-new;
        font-weight: $font-w-medium; } } }

  &__activity {
    &List {
      display: grid;
      gap: 32px; }

    &Group {
      display: grid;
      gap: 8px; }

    &Date {
      @include caption;
      border-bottom: 1px solid $color-border; }

    &Event {
      display: flex;
      gap: 16px;

      &:not(:first-child) {
        padding-top: 32px; }

      &:not(:last-child) {
        padding-bottom: 32px;
        border-bottom: 1px solid $color-border;

        & + p {
          margin-top: 32px; }

        & + .Loader {
          margin-top: 32px; } }

      &Date {
        @include caption;
        justify-self: end; }

      &Content {
        flex: 1 1; }

      &Type {
        @include caption;
        flex: 1 1;
        display: flex;
        align-items: center;

        span.twa {
          margin-right: 8px;
          font-size: 18px; } }

      &Name {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 4px;
        font-weight: $font-w-semi-bold; } }

    &Nav {
      display: flex;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background: $color-border; }

      &Item {
        @include p-new;
        background: none;
        border: none;
        font-weight: $font-w-semi-bold;
        padding: 2rem 3rem;
        position: relative;
        cursor: pointer;

        &:before {
          display: block;
          content: attr(title);
          font-weight: $font-w-bold;
          overflow: hidden;
          height: 0;
          visibility: hidden; }

        &.active {
          font-weight: $font-w-bold;
          color: $color-primary;
          background: none;

          &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            background: $color-primary;
            z-index: 5; } }

        .label {
          @include label;
          @include font(9px, 12px);
          vertical-align: middle;
          margin-left: 8px;
          align-items: center;
          color: $color-grey-60;
          font-weight: $font-w-semi-bold;

          @include screen-xs {
            display: block;
            margin: 0; } } } } }

  &__comments {
    display: grid;
    gap: 16px;

    &Input {
      display: grid;
      grid-template-columns: 36px 1fr;
      gap: 8px;
      position: relative;

      textarea,
      input {
        @include input-new;
        @include font(14px, 22px);
        padding: 8px 10px;
        padding-right: 12rem;
        resize: none;
        overflow: hidden; }

      &Count {
        @include caption;
        display: block;
        text-align: right; }

      &Btns {
        position: absolute;
        right: 0;
        height: 40px;
        bottom: 8px;
        display: flex;
        gap: 16px;
        padding-right: 16px;
        .btn-tertiary-new {
          @include font(14px, 18px);
          display: flex;
          align-items: center;
          border: none;
          padding: 0; }

        .Loader {
          background: none;
          right: 30px; } } }

    &Error {
      @include p-2;
      background: rgba($color-error-new, .07);
      padding: rem-size(6px) rem-size(10px);
      border-radius: 4px;
      display: flex;
      gap: 8px;
      align-items: center;
      grid-column: 2;

      i {
        color: $color-error-new; } } }

  &__comment {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    transition: opacity .2s;

    input {
      padding-right: 20rem; }

    .Avatar {
      margin-top: 8px; }

    &Content {
      @include p-2;
      flex: 1;
      padding: 6px 10px;
      background: $color-grey-50;
      border-radius: 4px;
      overflow: hidden;
      position: relative;

      .Loader {
        border-radius: 4px;
        background: rgba($color-grey-50, .6); } }

    &Name {
      font-weight: $font-w-semi-bold;
      display: flex;
      justify-content: space-between;
      align-items: center; }

    &Date {
      @include caption;
      position: relative; }

    &Content:hover &Actions,
    &Content:focus-within &Actions {
      opacity: 1;
      transform: none;
      min-width: 100%; }

    &Actions {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      top: 0;
      bottom: 0;
      right: 0;
      background: $color-grey-50;
      transform: translateX(120%);
      opacity: 0;
      transition: all .2s;
      button {
        @include caption;
        font-weight: $font-w-semi-bold;
        padding: 6px 0;
        color: $color-primary-600;

        &.delete {
          color: $color-error-new; }

        &:hover {
          text-decoration: underline; } } }

    &Input {
      display: block;
      margin-top: 4px; } }

  &__reactions + &__comments {
    border-top: 1px solid $color-border;
    padding-top: 16px; }

  &__reactions {
    margin: 8px 0;

    &Buttons {
      display: inline-flex;
      gap: 8px;
      position: relative;

      button {
        @include font(14px, 18px);
        padding: 8px 16px;
        display: flex;
        align-items: flex-start;

        &.active,
        &:hover {
          background: rgba($color-bg-blue, 1);
          color: $color-primary-600; }

        &.blurred {
          filter: blur(1px); }

        span {
          margin-right: 8px; } }

      .Loader {
        background: rgba($color-white, .7); } }

    &Members {
      display: flex;
      flex-flow: row wrap;
      gap: 16px; }

    &Members + &Buttons {
      margin-top: 16px; }

    &Avatar {
      position: relative;
      &Icon {
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translateX(25%);
        width: 22px;
        height: 22px;
        background: blue;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.Like {
          background: $color-primary-500; }
        &.Welcome {
          background: $color-secondary; }
        &.Celebrate {
          background: $color-yellow-calm; }

        span {
          font-size: 12px; } } }

    &Ellipsis {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: $color-grey-100;
      border: none;

      &:hover {
        background: $color-border; }

      span {
        color: $color-grey-90; } } }

  &__members {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    a.caption-new {
      &:hover,
      &:focus {
        text-decoration: underline; } }

    &Star.material-icons-outlined {
      color: $color-primary;
      font-size: 20px;

      &:hover,
      &:focus {
        outline: none; } }

    &Tip {
      text-align: center; }

    &Info {
      @include p-2;
      display: flex;
      padding: 8px 0;

      &Results {
        align-self: center; } }

    &Filter {
      .btn-icon {
        @include p-2;
        font-size: 14px;
        color: $color-grey-60;

        &.active {
          color: $color-secondary-300; } } }

    &Filters {
      .btn-icon,
      > i {
        font-size: 14px;
        color: $color-grey-60; }

      &Name {
        @include p-2;
        display: flex;
        gap: 8px;
        align-items: center;
        > span {
          flex: 1 1; } }

      &Group {
        @include p-2;
        border: 0 solid $color-border;
        border-bottom-width: 1px;
        padding: 8px 0;

        &:first-child {
          padding: 0; } }

      &Count {
        font-size: .9em;
        display: inline-block;
        padding: 0 6px;
        background: $color-grey-100;
        border-radius: 5px;
        margin-left: 8px; }

      &Options {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(7, 1fr);
        align-content: baseline;
        gap: 8px;
        margin-top: 8px;

        @include screen-sm-minus {
          grid-template-rows: repeat(13, 1fr); }

        @include screen-xs {
          grid-template-rows: 1fr;
          grid-auto-flow: row; } }

      &Option {
        @include p-2;
        color: $color-grey-80;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 8px;
        cursor: pointer; }

      &Title span {
        font-weight: $font-w-semi-bold; } }

    &Search {
      flex: 1 1;
      border: 0 solid $color-border;
      border-right-width: 1px;
      margin-right: 8px;
      padding-right: 8px;
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: inherit;
      font-size: inherit;

      i {
        @include p-2;
        font-size: 16px; }

      input {
        @include input-new;
        @include p-2;
        border: none;
        padding: 0;
        padding-right: 20px; }

      .btn-icon {
        position: absolute;
        width: 20px;
        height: 100%;
        right: 8px;
        top: 0;
        bottom: 0;
        left: auto;
        i {
          font-size: .95em; } } }

    table {
      width: 100%;
      margin-bottom: 8px;

      th,
      td {
        &:first-child {
          position: sticky;
          left: 0;
          z-index: 3;

          @include screen-xs {
            position: static; } } }

      thead {
        th {
          @include p-2;
          text-align: left;
          border: 0 solid $color-border;
          border-top-width: 1px;
          border-bottom-width: 1px;
          background: $color-white;
          font-weight: $font-w-bold;
          color: $color-grey-60;

          &:first-child {
            padding-left: 0;
 }            // min-width: 53rem
          &:last-child {
            padding-right: 0; } } }

      th,
      td {
        padding: 8px 16px;
        background: $color-white; }

      td {
        @include p-new;
        @include font(14px, 16px);
        height: 100%;

        &:first-child {
          width: 57rem; } }

      td {
        border-bottom: 1px solid $color-border; } }

    &Table {
      &Cell {
        &.wider {
 } }          // min-width: 40rem

      &Header {
        &.sortable {
          cursor: pointer; }

        &Sortable {
          white-space: nowrap;

          .fa-stack {
            .fa-caret-up,
            .fa-caret-down {
              color: $color-primary; }
            .fa-caret-up {
              transform: translateY(-3px); }
            .fa-caret-down {
              transform: translateY(3px); }

            &:not(.descending):not(.ascending) {
              .fa-caret-up,
              .fa-caret-down {
                color: $color-grey-30; } }

            &.descending {
              .fa-caret-up {
                color: $color-grey-30; } }
            &.ascending {
              .fa-caret-down {
                color: $color-grey-30; } } } } } }

    &List {
      min-width: 0;
      &Loader.Loader {
        background: rgba($color-white, .8); } }

    &Archetype {
      display: flex;
      align-items: center;
      font-size: inherit;
      font-weight: inherit;
      gap: 8px; }

    &Name {
      display: flex;
      align-items: center;
      gap: 16px;

      b {
        display: block;
        line-height: normal;
        font-weight: $font-w-medium;
        color: $color-black; } } }

  &__memberProfile {
    &Error {
      @include toast; }
    &Content {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 24px;
      margin-top: 24px;
      outline: none;

      @include screen-sm-minus {
        grid-template-columns: 1fr; } }

    .NameCard {
      border: none;
      padding: 0;
      margin: auto;

      .section-head {
        @include font(30px, 34px);
        font-weight: $font-w-medium;
        margin-top: 20px; } }

    &Drivers {
      display: grid;
      gap: 3rem;
      font-weight: $font-w-regular;
      grid-template-columns: repeat(3, 1fr);

      @include screen-xs {
        grid-template-columns: 1fr; } }


    &Driver {
      border-radius: 8px;
      display: flex;
      margin-top: 2rem;
      img {
        width: 60%;
        display: block;
        margin: auto; }

      &.who {
        background: $color-driver-who-pale; }
      &.why {
        background: $color-driver-why-pale; }
      &.how {
        background: $color-driver-how-pale; } }

    &Archetype {
      background: $color-white;
      margin: auto;
      position: relative;
      margin-bottom: -60px;
      max-width: 60%;
      padding: 0 8px;

      img {
        width: 100%; } }

    &Purpose {
      flex: 1 1 70rem;

      .card {
        padding-top: 60px; } }

    &Section {
      flex: 1 1 60rem; }

    &Milestones {
      @include p-new;
      gap: 2px 4px;
      display: grid;
      grid-template-columns: auto 1fr;
      max-height: 80rem;
      overflow: auto;

      &Date {
        color: $color-grey-60; }

      &Name:before {
        content: '-';
        margin-right: 4px;
        color: $color-grey-60; } } } }

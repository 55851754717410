@import "~theme/colors";
@import "~theme/fonts";


.Markdown {
  @include font($font-s-sm, $line-height-sm);

  h1,
  h2,
  h3 {
    @include text-primary;
    @include font($font-s-0, $line-height-0);
    font-weight: $font-w-semi-bold;
    margin: 0;
    color: $color-black;
    text-align: left;
    margin-top: 3rem; }

  p {
    @include text-primary;
    font-weight: $font-w-light; }

  ol,
  ul {
    @include font($font-s-sm, $line-height-sm);
    padding-left: 3rem; }

  ol {
    list-style-type: decimal; }

  ul {
    list-style-type: disc; }

  a {
    color: inherit;
    text-decoration: underline;
    font-weight: $font-w-regular; }

  p + p {
    margin-top: 2rem; }

  blockquote {
    p {
      @include font($font-s-sm, $line-height-sm);
      display: flex;

      &:before {
        content: '';
        display: block;
        width: 4px;
        margin-right: 1rem;
        background: $color-border; }

      &:first-child {
        margin-top: 1rem; }
      &:last-child {
        margin-bottom: 1rem; } }

    p + p {
      margin: 0; } } }

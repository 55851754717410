@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/scaffolding/scaffolding";
@import "~theme/common-form";
@import "~theme/btn/btn";

$header-h: rem-size(80px);

@mixin box {
  background: rgba($color-primary, .06);
  padding: 1rem 2rem;
  border-radius: 6px;
  height: 100%;

  @include screen-xs {
    padding: 1rem 2rem; } }

@mixin box-scroll($box-h: 78%) {
  overflow-y: auto; }

.Questions {
  &.page {
    background: $color-white;
    display: flex;
    flex-flow: column;
    overflow: auto;

    > .container {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      min-height: 0;
      overflow: auto;

      @include screen-xs {
        padding: 0;
        min-height: 100vh; } } }

  &__row {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;

    @include screen-xs {
      display: block;
      text-align: center; }

    &Icon {
      max-width: 20rem;
      flex: 0 0;
      margin-right: 5rem;
      display: block;

      @include screen-xs {
        max-width: 15rem;
        margin: auto; } } }

  &__column {
    &.max {
      flex: 1 1; } }

  &__tip.tippy-box {
    border-radius: 6px;
    background-color: $color-secondary;

    .tippy-content {
      padding: 1rem 2rem; }

    .tippy-arrow {
      color: $color-secondary; }

    &[data-placement^="bottom-start"] {
      .tippy-arrow {
        transform: none !important;
        left: 15% !important; } } }

  &__tooltip {
    @include font($font-s-sm, $line-height-sm);
    font-weight: $font-w-regular;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      margin-left: 2rem;
      pointer-events: auto; } }

  &__header {
    background: rgba($color-primary, .1);
    padding: 3rem 0;

    @include screen-xs {
      background: $color-white;
      box-shadow: 0 0 10px 0 rgba($color-black, .1); }

    &Overlay {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1000; }

    &Container {
      display: flex;
      justify-content: space-between;
      align-items: center; }

    &Title {
      position: relative;
      cursor: pointer;

      @include screen-xs {
        display: none; }

      button {
        border: none;
        outline: none;
        &:focus:not(:focus-visible) {
          background-image: linear-gradient(to right, rgba($color-primary, .2) 60%, rgba(white,0) 60%); }
        &:hover,
        &:focus,
        &.focus-visible:focus:not(:focus-visible) {
          background-image: linear-gradient(to right, rgba($color-primary, 1) 60%, rgba($color-primary, 1) 60%); } }

      &Text {
        @include font($font-s-1, $line-height-1);
        font-weight: $font-w-semi-bold;
        color: $color-primary;
        text-transform: uppercase;
        background-image: linear-gradient(to right, rgba($color-primary, .2) 60%, rgba(white,0) 60%);
        background-position: bottom left;
        background-size: 15px 2px;
        background-repeat: repeat-x; } }

    &Popup {
      position: absolute;
      cursor: auto;
      left: 0;
      z-index: 1001;
      top: calc(100% + 2rem);
      width: 70rem;
      opacity: 0;
      visibility: hidden;
      transition: all .3s;

      &.visible {
        opacity: 1;
        visibility: visible; }

      &:after {
        bottom: 100%;
        left: 15%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: $color-white;
        border-width: 10px;
        margin-left: -10px; } } }

  &__agenda {
    display: flex;

    @include screen-xs {
      margin: auto; }

    &Desktop {
      @include screen-xs {
        display: none; } }

    &Mobile {
      @include screen-sm-plus {
        display: none; } }

    &Item {
      &:not(:last-child):not(:first-child) &Icons {
        border-right: 1px solid rgba($color-primary, .5);
        border-left: 1px solid rgba($color-primary, .5);
        padding: 0 4rem;
        @include screen-xs {
          padding: 0 2rem; } }

      &:not(:last-child):not(:first-child) {
        margin: 0 4rem;
        @include screen-xs {
          margin: 0 2.5rem; } }

      &Icons {
        display: flex;
        justify-content: center;
        align-items: center; }

      &Label {
        @include label;
        display: block;
        text-align: center;
        color: rgba($color-primary, .5);
        margin-bottom: 1rem;

        @include screen-xs {
          display: none; } }

      &Icon {
        margin: 0 4rem;
        position: relative;

        @include screen-xs {
          margin: 0 2rem; }

        &:first-child {
          margin-left: 0; }

        &:last-child {
          margin-right: 0; }

        &Tooltip {
          position: absolute;
          top: calc(100% + 2rem);
          display: block;
          background: $color-white;
          padding: 1rem;
          border-radius: 8px;
          box-shadow: 0 0 10px 0 rgba($color-black, .1);
          color: darken($color-grey-dark-med, 15%);
          white-space: nowrap;
          font-size: 2rem;
          font-weight: $font-w-regular;
          opacity: 0;
          visibility: hidden;
          transition: all .3s;

          @include screen-xs {
            top: auto;
            bottom: calc(100% + 2rem); }

          &:after {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-bottom-color: $color-white;
            border-width: 7px;
            margin-left: -7px;

            @include screen-xs {
              border-bottom-color: transparent;
              border-top-color: $color-white;
              top: 100%;
              bottom: auto; } } }

        &:hover &Tooltip {
          opacity: 1;
          visibility: visible; } }

      &Icon {
        font-size: $font-s-xs;
        text-align: center;
        color: $color-white;
        width: 4.5rem;
        height: 4.5rem;
        border: 1px solid $color-border;
        background: $color-white;
        border-radius: 50%;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s;

        @include screen-xs {
          font-size: 1.7rem;
          width: 4rem;
          height: 4rem;

          .fa-check {
            font-weight: 500; } }

        &.first {
          background: mix($color-primary, $color-white, 20%);
          border-color: mix($color-primary, $color-white, 20%); }
        &.second {
          background: mix($color-primary, $color-white, 30%);
          border-color: mix($color-primary, $color-white, 30%);
          &.active {
            border-color: $color-secondary;
            color: $color-secondary;
            background: $color-white; }
          &.finished {
            border-color: $color-secondary;
            background: $color-secondary; } }
        &.third {
          background: mix($color-primary, $color-white, 40%);
          border-color: mix($color-primary, $color-white, 40%); }

        &.active {
          border-color: $color-green;
          color: $color-green;
          background: $color-white; }

        &.finished {
          border-color: $color-green;
          background: $color-green; } } } }

  &__exit {
    width: 100%;
    margin-top: .5rem;
    opacity: .5;
    transition: opacity .3s;

    &:hover,
    &:focus {
      opacity: 1; }

    &Divider {
      width: 100%;
      height: 1px;
      background-color: $color-grey-light-med;
      margin-top: 5rem;
      margin-bottom: 2rem; } }

  &__title {
    @include title-blue-caps;
    display: flex;
    align-items: baseline;

    @include screen-xs {
      @include font($font-s-0, $line-height-0); } }

  .Loader {
    background: $color-bg-darker;

    &.white {
      background: $color-white; } }

  .Header__children {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none; }

  &__left,
  &__right {
    padding: 3rem;

    @include screen-xs {
      padding: 2.5rem; } }

  &__right {
    flex: 1 1;
    z-index: 2;

    @include screen-xs {
      flex: 0 0 100%; } }

  &__left {
    @include screen-xs {
      flex: 0 0 92%;
      padding-right: 1rem; } }

  &__notification {
    &.Video__notification {
      border-radius: 4px;

      &.info {
        background: $color-primary; }

      &.error {
        background: $color-secondary; } }

    &Content {
      display: flex;
      flex-flow: row;
      align-items: center; } }

  &__arrow {
    @include font($font-s-xs, $line-height-xs);
    display: inline-block;
    margin-top: 3rem;
    transition: all .3s;
    position: relative;
    left: calc(100vw + 5rem);
    transform: translateX(-100%);
    color: $color-primary;
    font-weight: $font-w-semi-bold;

    &.swiped {
      left: -10rem;
      transform: none; }

    div {
      display: inline-block;
      vertical-align: middle; }

    span {
      padding: 2px 8rem 4px 2rem;
      border-top: 2px solid $color-primary;
      border-bottom: 2px solid $color-primary;
      position: relative;
      z-index: 2; }

    &.swiped span {
      padding-left: 8rem;
      padding-right: 2rem; }

    $arrow_size: 2rem;
    $border_size: 1px;
    $arrow_s_size: calc(2rem - #{$border_size});

    &Left {
      width: 0;
      height: 0;
      border-top: 2rem solid transparent;
      border-bottom: 2rem solid transparent;
      border-right: 2rem solid $color-primary;
      position: relative;

      &:before {
        content: '';
        width: 0;
        height: 0;
        border-top: $arrow_s_size solid transparent;
        border-bottom: $arrow_s_size solid transparent;
        border-right: $arrow_s_size solid $color-bg-darker;
        position: absolute;
        top: calc(-#{$arrow_size} + #{$border_size});
        right: calc(-#{$arrow_size} - #{$border_size});
        z-index: 1; } }

    &Right {
      width: 0;
      height: 0;
      border-top: 2rem solid transparent;
      border-bottom: 2rem solid transparent;
      border-left: 2rem solid $color-primary;
      position: relative;

      &:before {
        content: '';
        width: 0;
        height: 0;
        border-top: $arrow_s_size solid transparent;
        border-bottom: $arrow_s_size solid transparent;
        border-left: $arrow_s_size solid $color-bg-darker;
        position: absolute;
        top: calc(-#{$arrow_size} + #{$border_size});
        left: calc(-#{$arrow_size} - #{$border_size});
        z-index: 1; } } }

  &__name {
    @include subtitle-black;
    margin: 0;

    span {
      font-weight: $font-w-regular;
      margin-left: 1rem; } }

  &__card {
    border-radius: 3px;
    margin-top: 2rem;
    box-shadow: 0 5px 10px 0 $color-border;

    &Header {
      @include subtitle;
      background: $color-primary;
      color: $color-white;
      text-align: center;
      padding: 2rem;
      margin: 0;
      position: relative;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      @include screen-xs {
        padding-right: 20rem;
        text-align: left; }

      &> span {
        font-weight: $font-w-regular; } }

    &Header + &Question {
      padding-top: 5rem; }

    &Info {
      background: $color-bg;
      border-width: 0 1px;
      border-color: $color-border;
      border-style: solid;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      padding: 3rem;

      @include screen-xs {
        background: $color-white; } }

    &Question {
      padding: 3rem;
      border-color: $color-border;
      border-style: solid;
      border-width: 0 1px;
      border-top-width: 1px;
      background: $color-white;

      @include screen-xs {
        border-top: 0;
        padding-top: 0; }

      &:last-child {
        border-bottom-width: 1px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px; } }

    &Relative {
      position: relative;

      &Waiting {
        @include screen-sm-plus {
          min-height: 90rem;
          background: $color-white; } } } }

  &__video {
    width: 20rem;
    margin-right: 1rem;

    video {
      width: 100%; }

    @include screen-sm-plus {
      display: none; } }

  &__member {
    padding: 0 2rem;
    flex: 1 1;

    &.right {
      text-align: right; }

    .text-grey {
      @include screen-xs {
        @include font($font-s-xs, $line-height-xs); } }

    &Arrow {
      position: absolute;
      font-size: .8em;
      top: .5rem;
      margin-left: 1rem; }

    &Name {
      @include text-bold;
      color: $color-primary;
      margin-top: 2rem;
      position: relative;
      cursor: pointer;

      @include screen-xs {
        @include font($font-s-sm, $line-height-sm);
        margin-top: 0; } }

    &Drivers {
      button {
        @include text-primary;
        line-height: 2rem;
        background: none;
        text-transform: none;
        padding: 0;
        border: 0; }

      button + button {
        border-left: 1px solid $color-border;
        padding-left: 1rem;
        margin-left: 1rem; } } }

  &__avatar {
    position: absolute;
    top: 80%;
    transform: translateY(-50%);

    @include screen-xs {
      top: 20%; }

    &.left {
      left: 5rem; }
    &.right {
      right: 5rem; }

    .Avatar {
      border: 0; }

    &-offline {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50%;
      background: $color-border;
      opacity: 0;
      z-index: 9;
      transition: opacity .3s;

      &.visible {
        opacity: .6; } }

    &-talking {
      font-size: 1.5em;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      background: rgba($color-pink, .7);
      border-radius: 50%;
      border: 2px solid $color-pink;
      line-height: calc(11rem - 2px); }

    &-typing {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      background: rgba($color-primary, .7);
      border-radius: 50%;
      border: 2px solid $color-primary;
      line-height: 11rem - 1.5rem;
      transition: opacity .3s;
      opacity: 0;

      &.visible {
        opacity: 1; }

      @keyframes dot {
        0% {
          opacity: 0; }
        50% {
          opacity: 1; }
        100% {
          opacity: 0; } }

      .dot {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background: $color-white;
        display: inline-block;
        margin: 0 .5rem;
        opacity: 0;
        animation-name: dot;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;

        &:nth-child(2) {
          animation-delay: .3s; }
        &:nth-child(3) {
          animation-delay: .6s; } } } }

  &__partner {
    @include text-secondary;
    text-align: center;

    @include screen-xs {
      @include font($font-s-xs, $line-height-xs); }

    span {
      margin-left: 1rem;

      &:not(:last-child):after {
        content: ','; } } }

  &__progress {
    @include label;
    @include font($font-s-xs-sm, $font-s-sm);
    letter-spacing: 1px;
    color: $color-white;
    padding: 0 2rem;
    text-align: center;
    flex: 1 1;

    &Dot {
      width: 2rem;
      height: 2rem;
      background: $color-grey-med;
      border-radius: 50%;
      display: inline-block;
      margin: 0 1rem;
      transition: background .3s;

      @include screen-xs {
        margin: 0 .5rem; }

      &.done {
        background: $color-pink; }
      &.active {
        background: $color-primary; } } }

  &__btns {
    text-align: center;

    button {
      min-width: 20rem;
      margin: 0 2rem;

      @include screen-xs {
        margin: 2rem 0.5rem; } } }

  &__start {
    padding-top: 2rem;

    button {
      margin: 3rem; } }

  &__btnPrint.btnLink {
    background: none;
    border: none;
    display: inline-block; }

  &__transcribe {
    display: inline-block;
    font-family: $font-primary-new;
    font-weight: $font-w-regular;
    font-size: $font-s-xs;
    outline: none;
    text-align: center;
    text-transform: uppercase;
    transition: opacity .2s; }

  &__btnVideo {
    @include font($font-s-sm, $line-height-sm);
    font-weight: $font-w-semi-bold;
    color: $color-primary;
    border: none;
    background: none;
    margin: 1.5rem;
    transition: background .3s;
    outline: none;
    text-transform: uppercase;
    text-align: left;

    &:hover {
      background: $color-grey-imperceptible; }

    span {
      width: 2em;
      height: 2em;
      line-height: 1.9em;
      font-size: 1.2em;
      border: 2px solid $color-primary;
      background: $color-white;
      text-align: center;
      border-radius: 50%;
      margin-right: 2rem; }

    &.disabled:hover {
      background: none; }

    &.download {
      margin: 2rem 0;
      display: block; } }

  &__members {
    @include text-primary;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 2rem;

    @include screen-xs {
      @include text-xs; }

    &>span {
      margin: 0 .5rem; }

    &Both {
      flex: 1 1 100%; } }

  &__question {
    position: relative;

    &RIGScores {
      background: rgba($color-primary, .06);
      padding: 1rem;
      margin: 2rem 0; }

    &Row {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      & + & {
        margin-top: 2rem;

        @include screen-xs {
          margin-top: 1rem; } } }

    &DynamicImg {
      margin-right: 3rem;
      position: relative;

      @include screen-xs {
        display: none; }

      &:before {
        content: '';
        display: block;
        width: 13rem;
        height: 13rem;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 0; }

      img {
        width: 13rem;
        position: relative;
        z-index: 2; } }

    &Content {
      flex: 1 1; }
    &Dynamic {
      &Title {
        @include font($font-s-sm, $line-height-sm);
        font-weight: $font-w-semi-bold;
        color: $color-secondary;

        &.green {
          color: $color-green; }

        &.black {
          color: $color-black; }

        &.light {
          font-weight: $font-w-regular; } }

      &.Markdown {
        @include font($font-s-xs-sm, 3.5rem);
        font-weight: $font-w-regular;
        height: 100%;

        & > * {
          opacity: .7; }

        p {
          font-size: inherit;
          line-height: inherit;
          font-weight: inherit;
          @include box-scroll; }

        em {
          font-style: normal; }

        ul {
          @include box-scroll;
          li {
            font-weight: $font-w-regular; } } } }

    &OneWord {
      @include subtitle-black;
      @include font($font-s-2, normal);
      color: $color-primary;
      font-weight: $font-w-semi-bold;
      margin-bottom: 1rem;

      @include screen-xs {
        @include font($font-s-sm, $line-height-sm); }

      span {
        line-height: inherit;
        vertical-align: super;
        margin-left: .5rem;
        line-height: 3rem; } }

    &Title,
    &Description {
      @include subtitle-black;
      @include font($font-s-0, $line-height-sm);
      font-weight: $font-w-semi-bold;
      margin: 0;

      @include screen-xs {
        @include font($font-s-sm, $line-height-sm); }

      *, p {
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit; } }

    &Title {
      &-img {
        height: $line-height-0;
        display: inline-block;
        vertical-align: middle;
        margin-left: 1rem; } }

    &Number {
      @include font($font-s-0, $line-height-sm);
      font-weight: $font-w-semi-bold;
      margin-top: -1rem;
      margin-bottom: 1rem; }

    &Buttons {
      button:first-of-type {
        margin-right: 5rem; } }

    &Actions {
      p {
        @include font($font-s-0, $line-height-0);

        @include screen-xs {
          @include font($font-s-sm, $line-height-sm); } }

      &Summary {
        p {
          @include subtitle-black;
          margin-bottom: 2rem;

          @include screen-xs {
            @include font($font-s-sm, $line-height-sm); } } }

      &Goals {
        @include font(3rem, normal);
        margin-bottom: 2rem;
        list-style: inherit !important;
        padding-left: 4rem;

        @include screen-xs {
          @include font($font-s-sm, $line-height-sm); } } } }

  &__actionSubareas {
    margin: 0 -.5rem;
    &-btn {
      @include btn__base;
      @include font($font-s-sm, $line-height-sm);
      border: 1px solid $color-border;
      background: $color-white;
      text-transform: none;
      border-radius: 5px;
      margin: .5rem;
      transition: all .3s;

      &.active {
        background: $color-primary;
        color: $color-white;
        border-color: $color-primary; }

      &:focus {
        box-shadow: 0 0 0 2px $color-focus; }
      &:focus:not(:focus-visible) {
        box-shadow: none; } } }

  &__oneWordInput {
    max-width: 35rem;

    @include screen-xs {
      margin: 1rem auto 0 auto; } }

  &__field {
    margin-bottom: 3rem;
    position: relative;

    @include screen-xs {
      margin-bottom: 1rem; }

    &.small-input {
      max-width: 30rem; }

    .RichTextEditor__input {
      max-height: 20rem;
      overflow: auto;

      @include screen-lg {
        max-height: 30rem; } }

    &Rating {
      &:focus {
        outline: none; }

      &:focus &-item.fal.fa-star {
        color: $color-primary;
        font-weight: 500; }

      &-item {
        cursor: pointer;
        color: $color-primary;
        transition: color .3s;

        &.readonly {
          cursor: auto; }

        &.normal {
          @include font($font-s-1, $line-height-1); }

        &.xs {
          @include font($font-s-sm, $line-height-sm); }

        &.fal.fa-star {
          color: $color-grey-heather; } } }


    &Text {
      @include input;
      border-radius: 5px;
      resize: none;
      padding: 1rem 2rem;
      min-height: 15rem;
      transition: all .3s;

      @include screen-xs {
        @include font($font-s-sm, $line-height-sm);

        &:focus {
          min-height: 30rem; } }

      &:focus {
        border-color: $color-primary; }

      &.readonly:focus {
        border-color: $color-border; }

      &.withButton {
        padding-bottom: 9rem; }

      &-button {
        position: absolute;
        bottom: 3.5rem;
        right: 2rem; }

      &.small {
        min-height: auto; }

      &.autosize {
        overflow: hidden;
        max-height: 20rem; } }

    &Slider {
      max-width: 100rem;
      margin: auto;

      &.readonly {
        .rc-slider-handle {
          &:hover,
          &:focus,
          &:active {
            box-shadow: none;
            cursor: auto; } } } }

    &Thumbs {
      color: $color-primary;
      display: flex;
      justify-content: space-between; }

    &Radio {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
      gap: 2rem;

      &Container {
        input {
          position: absolute;
          opacity: 0; } }

      &Container input:focus + &Item {
        background: rgba($color-primary, .05);
        border-color: $color-primary; }

      &Item {
        @include font($font-s-sm, $line-height-sm);
        font-weight: $font-w-medium;
        text-align: left;
        background: $color-white;
        margin: auto;
        padding: 1.5rem;
        padding-right: calc(1.5rem + 5rem);
        border: 1px solid $color-border;
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        transition: outline .2s, background .3s;

        &:hover {
          background: rgba($color-primary, .05); }

        &.active {
          border: 1px solid $color-primary;
          color: $color-primary;
          font-weight: $font-w-semi-bold; }

        &.hidden {
          opacity: 0; }

        &.readonly {
          cursor: auto;

          &:hover {
            background: $color-white; } }

        span {
          position: absolute;
          top: calc(50% - 2rem);
          font-size: 3rem;
          line-height: 4rem;
          right: 2rem;
          transition: color .3s;

          &.fa-circle,
          &.fa-square {
            color: $color-border; } } } } }

  &__wait {
    @include text-primary;
    color: $color-pink;
    margin-top: 5rem;
    text-transform: uppercase; }

  &__error {
    @include label;
    font-weight: $font-w-semi-bold;
    color: $color-red;
    margin: 3rem 0;
    padding: 1rem;
    background: rgba($color-red, .05);
    text-align: center;

    &-info {
      color: $color-warning;
      background: rgba($color-warning, .05); } }

  &__typing {
    @include label;
    text-align: center;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 100%; }

  &__modal {
    padding: 0;
    max-width: 80rem;

    @include screen-xs {
      max-width: unset; }

    .Modal__exit {
      color: $color-white;
      z-index: 9;
      top: 3rem;
      right: 3rem; }

    &Content {
      padding: 3rem;
      text-align: center; }

    &Timer {
      font-size: $font-s-3; }

    &RIG {
      &-content {
        padding-top: 4rem;

        @include screen-xs {
          padding: 2rem; } }

      &-copy {
        display: flex;
        align-items: flex-start;

        @include screen-xs {
          flex-wrap: wrap; }

        span {
          display: block;
          width: 80rem;

          @include screen-xs {
            width: auto; } }

        img {
          height: auto;
          margin-left: -1rem;
          max-width: 22rem;
          margin-right: 2rem;

          @include screen-xs {
            max-width: 18rem;
            margin: auto; } } } }

    &Question {
      @include screen-sm-plus {
        min-width: 60%;
        max-width: 90%; }

      @include screen-lg {
        max-width: 60%;
        min-width: 50%; }

      &_title {
        font-weight: $font-w-medium;
        text-align: center;
        margin-bottom: 16px; }

      &_btn {
        text-align: center;
        margin-top: 24px; }

      &_error {
        @include toast;
        margin-top: 16px; } } }

  &__waiting {
    &Container {
      position: absolute;
      background: rgba(0, 0, 255, 0.2);
      height: 100%;
      width: 100%;
      z-index: 1;

      @include screen-xs {
        overflow: auto; } }

    &Card {
      @include screen-sm-plus {
        width: 50%;
        transform: translate(50%, 50%); } }

    &Info {
      color: $color-driver-who;
      display: block;

      p {
        font-weight: $font-w-semi-bold; }

      ul {
        list-style: initial;
        padding-left: 6rem; }

      &Role {
        text-transform: uppercase; } } }

  &__onboarding {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;

    @include screen-xs {
      display: block;
      text-align: center; }

    img {
      max-width: 20rem;
      margin-right: 5rem;
      display: block;

      @include screen-xs {
        max-width: 15rem;
        margin: auto; } }

    button {
      margin-top: 3rem; }

    &Right {
      flex: 1 1; }

    &Names {
      @include subtitle-black; }

    &Description {
      @include font($font-s-sm, $line-height-sm);
      font-weight: $font-w-regular;
      opacity: .7;
      padding-top: 1rem;
      padding-bottom: 3rem;

      &Date {
        font-weight: $font-w-bold;
        color: $color-black; } } }

  &__convo {
    flex: 1;
    display: flex;
    flex-flow: row wrap;
    margin: -2.5rem;

    @include screen-xs {
      flex-flow: column;
      max-height: unset;
      margin: 0; }

    &Ref {
      outline: none;
      display: flex;
      flex-flow: column;
      flex: 1 1; }

    &Video,
    &Sheet {
      margin: 2.5rem; }

    .Video {
      border-radius: 8px;
      overflow: hidden; }

    &Sheet {
      flex: 1 1;
      display: flex;
      flex-flow: column;

      @include screen-xs {
        margin-top: 0; }

      &Btns {
        display: flex;
        justify-content: space-between;

        @include screen-xs {
          margin-top: 1rem; }

        &.right {
          justify-content: flex-end; } }

      &Options {
        position: relative;
        color: $color-grey-heather;
        @include font($font-s-sm, $line-height-sm);

        @include screen-xs {
          @include font($font-s-xs-sm, $line-height-sm); }

        & > div {
          display: inline-block;
          width: 50%;
          text-align: center;
          border-bottom: 1px solid $color-grey-light-med;
          padding: 2rem;
          text-transform: uppercase;
          font-weight: $font-w-regular;

          @include screen-xs {
            padding: 1rem; } }

        &Divider {
          width: 1px;
          height: 60%;
          background: gray;
          position: absolute;
          top: 20%;
          background-color: $color-grey-light-med; } }

      &Active {
        color: $color-black;
        font-weight: $font-w-bold !important;
        position: relative;

        &.green:after {
          background: $color-green; }

        &:after {
          content: '';
          height: 4px;
          background: $color-pink;
          display: block;
          position: absolute;
          width: 100%;
          bottom: -1px;
          left: 0; } }

      &Option {
        padding-top: 3rem;
        flex: 1 1;

        @include screen-xs {
          padding-top: 2rem; }

        &Divider {
          width: 100%;
          height: 1px;
          background-color: $color-grey-light-med;
          margin: 4rem 0;

          @include screen-xs {
            margin: 2rem 0; } } }

      &Asking {
        margin-top: 2rem;

        @include screen-xs {
          margin-top: 0; }

        &Tip {
          @include font($font-s-sm, $line-height-xs);
          font-weight: $font-w-regular;
          margin: 1rem 0;
          display: flex;
          align-items: baseline;

          @include screen-xs {
            margin: 2rem 0; }

          > span {
            margin-right: 1rem;
            white-space: nowrap; }

          .Markdown,
          .Markdown * {
            font-size: inherit;
            font-weight: inherit; }

          .Markdown {
            margin-top: 1rem; }

          &.color {
            color: $color-secondary; }

          .icon {
            font-size: 86%;
            color: $color-white;
            background: $color-secondary;
            border-radius: 50%;
            padding: 5px;
            margin-right: 1rem; }

          &Info {
            background: $color-secondary;
            padding: 1rem;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            display: flex;

            img {
              padding-right: 2px;
              margin: auto;
              width: 2.4rem;

              @include screen-xs {
                width: 2rem; } }

            &Container {
              width: 4rem;
              height: 4rem;
              margin-right: 1rem;

              @include screen-xs {
                width: 4rem;
                height: 4rem;
                margin-right: 1rem; } } } } }

      &Answer {
        &Description {
          padding: 3rem;
          color: $color-primary;
          font-weight: $font-w-bold;

          &.less {
            p {
              /* autoprefixer: ignore next */
              -webkit-box-orient: vertical;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 10; } } }

        &Button {
          text-align: center;

          button {
            background: none;
            border: 1px solid $color-grey-heather;
            color: $color-grey-heather; } }

        &Tip {
          text-align: center;
          font-size: $font-s-sm;
          color: $color-grey-heather; } }

      &Finish {
        padding: 5rem; } }

    &Video {
      flex: 1 1;
      position: relative;

      @include screen-xs {
        flex: 0 0; }

      &>div {
        height: 100%; }

      &-blank {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        background: $color-border;
        border-radius: 1rem; } } }

  &__toggler {
    @include text-primary;
    color: $color-primary;
    display: flex;
    align-items: center;
    cursor: pointer;

    @include screen-xs {
      justify-content: flex-start; }

    span {
      display: inline-block;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background: rgba($color-primary, .2);
      color: $color-primary;
      text-align: center;
      line-height: 4.1rem;
      padding-left: .5rem;
      font-size: $font-s-0;
      margin-left: 2rem; }

    &-bar {
      margin: 0 5rem 0 2rem;
      height: 4rem;
      width: 10rem;
      background: $color-grey-dark-med;
      border-radius: 5rem;
      box-shadow: inset 0 0 5px 0 $color-grey-heather;
      position: relative;
      cursor: pointer;
      display: inline-block;
      vertical-align: -30%;

      &:before {
        content: 'OFF';
        color: $color-white;
        font-weight: $font-w-semi-bold;
        right: 1rem;
        position: absolute; }

      &.right {
        background: $color-primary;
        &:before {
          content: 'ON';
          color: $color-white;
          font-weight: $font-w-semi-bold;
          left: 1.5rem;
          position: absolute; } } }

    &-handle {
      width: 3rem;
      height: 3rem;
      background: $color-white;
      position: absolute;
      left: .5rem;
      top: .5rem;
      border-radius: 50%;
      transition: left .3s;

      &.right {
        left: calc(100% - 3rem - .5rem); } } }

  .Toast {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    &__title {
      flex: 1 1 100%; } }

  &__scheduleNext {
    .btn-icon.check {
      font-size: .8em;
      height: 16px;
      width: 16px;
      border: 1px solid $color-grey-60;
      box-shadow: 0px 2px 4px 0px rgba($color-black, .06) inset;
      border-radius: 4px;
      margin-right: 8px;
      &:not(.readonly) {
        &:hover, &:focus {
          border-color: $color-grey-90;
          background: none; }
        &:focus:not(:focus-visible) {
          border-color: $color-grey-60;
          background: none; } }
      &.checked {
        border-color: $color-success-new;
        background: $color-success-new;
        color: $color-white;
        &:not(.readonly) {
          &:hover, &:focus {
            border-color: mix($color-success-new, $color-black, 70%);
            background: mix($color-success-new, $color-black, 70%); }
          &:focus:not(:focus-visible) {
            border-color: $color-success-new;
            background: $color-success-new; } } }

      &.readonly {
        cursor: auto; } }

    &Conv {
      margin: 8px 0;
      padding: 8px;
      border-radius: 6px;
      min-height: 48px;
      display: flex;
      align-items: center;

      &.checked {
        background: mix($color-success-new, $color-white, 20%);
        h2, button, span, .Questions__scheduler {
          color: mix($color-success-new, $color-black, 60%); }
        .Questions__scheduler {
          font-weight: $font-w-medium; } }
      .Questions__scheduler {
        margin-top: 0px;
        margin-bottom: 24px;

        .react-select {
          color: $color-black; } } }

    &Header {
      margin-bottom: 16px;

      h2 {
        margin-bottom: 8px; }
      h2 + p {
        margin-top: 0; } }

    &Title + &Conv {
      margin-top: 16px; }

    &Title {
      display: flex;
      align-items: center;
      flex: 1 1;

      &.timezone .btn-icon {
        margin-right: 8px; }

      h2 {
        flex: 1 1;
        margin: 0; }

      .Questions__scheduler {
        max-width: 60rem;
        margin: 0; } }

    &Date {
      display: flex;
      align-items: center;
      min-width: 35rem;

      .btn-icon:not(.check) {
        font-size: 1.2em;
        width: auto;
        border-radius: 4px;
        margin-left: 8px;
        span {
          @include caption;
          opacity: 0;
          text-transform: none;
          color: inherit;
          margin-left: 4px;
          transition: opacity .2s; }
        &:hover, &:focus {
          span {
            opacity: 1; } } } }

    &Scheduler {
      display: flex;
      flex-flow: column;

      .btnLink {
        margin-left: auto; } }

    &Error {
      @include toast; }

    .Loader.white {
      background: rgba($color-white, .8);
      pointer-events: auto; } }

  &__scheduler {
    @include font($font-s-sm, $line-height-sm);
    margin-top: 3rem;
    flex: 1 1 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    &Icon {
      color: $color-primary;
      margin-right: 2rem;
      width: 3rem; }

    &Datepicker {
      max-width: 23rem;
      outline: none;
      color: $color-black;

      &.hasValue {
        border-color: $color-primary; }

      &Container {
        margin: 0 1rem; }

      &Input {
        position: relative;

        i {
          @include font(.9em, $line-height-1);
          position: absolute;
          color: $color-primary;
          right: 1.7rem;
          top: 0; } } }

    &Select {
      width: 15rem;
      margin: 1rem 1rem;

      &.wide {
        min-width: 43rem;
        width: auto;
        flex: 1 1;
        margin-right: 0;

        @include screen-xs {
          margin-left: 0;
          min-width: 30rem; } }

      &.hasValue {
        .react-select__control {
          border-color: $color-primary !important; } }

      .react-select {
        &__indicator {
          padding-left: 0;
          padding-right: 0;
          color: $color-primary; }

        &__indicators {
          flex: 0 0 4rem; }

        &__menu {
          bottom: 100%;
          top: unset !important; } } }

    &Option.react-select__option {
      display: flex;
      align-items: baseline; }

    &Option.react-select__single-value {
      min-width: unset;
      display: flex;
      align-items: baseline;
      left: 8px;
      right: 8px; }

    &Option.react-select__single-value &OptionName span {
      font-weight: $font-w-light; }

    &OptionName {
      @include font($font-s-sm, $line-height-sm);
      margin-left: 1rem; }

    &Offset {
      white-space: nowrap; } } }

.modal {
  margin: 5% auto;
  width: 30%;

  @include screen-md-minus {
    width: 40%; }

  @include screen-sm-minus {
    width: 60%; }

  @include screen-xs {
    width: 90%; } }

.rate {
  text-align: center;

  &Title {
    @include font($font-s-0, $line-height-0);
    font-weight: $font-w-semi-bold;
    margin: 2rem 0; }

  &Content {
    margin-bottom: 2rem;
    @include text-secondary; }

  &Stars {
    margin-bottom: 5rem;

    i {
      color: $color-driver-how;
      font-size: $font-s-2; } } }

.ActionIconColors {
  --fa-primary-color: rgb(82 99 174);
  --fa-secondary-color: rgb(82 99 174); }

.RIGQuestion {
  &__question {
    &:not(:first-child) {
      margin: 2rem 0; } }

  &__title {
    font-weight: $font-w-medium;

    @include screen-xs {
      @include font($font-s-sm, $line-height-sm); } }

  &__answers {
    display: flex;
    justify-content: space-between; }

  &__answer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    padding-bottom: 1rem;
    border: 1px solid transparent;
    position: relative;

    @mixin m-active-answer {
      .rig-img-color {
        visibility: visible;
        opacity: 1; }
      span {
        color: $color-primary;
        font-weight: $font-w-semi-bold;
        border: 1px solid rgba($color-primary, .5); } }

    &:hover {
      @include m-active-answer; }

    .rig-img-color {
      position: absolute;
      top: 0;
      visibility: hidden;
      opacity: 0;
      transition: all .2s ease-out; }

    img {
      width: 8rem;
      min-height: 1px;
      margin-bottom: 1rem;

      @include screen-xs {
        width: 5rem; } }

    span {
      @include font($font-s-sm, $line-height-sm);
      width: 18rem;
      text-align: center;
      border: 1px solid transparent;
      transition: all .2s ease-out;

      @include screen-xs {
        width: 10rem;
        @include font($font-s-xs, $line-height-xs); } }

    &.active {
      @include m-active-answer; } } }

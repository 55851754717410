@import "~theme/btn/btn";
@import "~theme/clearfix";
@import "~theme/responsive/responsive";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";


.HeaderDropdown {
  display: block;
  position: relative;

  &.over {
    position: static; }

  &__menu {
    position: absolute;
    background: $color-white;
    z-index: 10001;
    min-width: 40rem;
    opacity: 0;
    visibility: hidden;
    border: 1px solid $color-border;
    border-radius: 8px;
    transition: all .2s;

    &.below_left {
      top: 100%;
      right: 0; }
    &.below_right {
      top: 100%;
      left: 0; }

    &.above_left {
      bottom: 100%;
      right: 0; }
    &.above_right {
      bottom: 100%;
      left: 0; }

    &.right_top {
      left: 100%;
      top: 0; }
    &.left_top {
      right: 100%;
      top: 0; }

    &.right_bottom {
      left: 100%;
      bottom: 0; }
    &.left_bottom {
      right: 100%;
      bottom: 0; }

    &.over {
      left: -1px;
      right: -1px;
      top: -1px;
      min-width: auto;
      min-height: 50rem; }

    &.visible {
      opacity: 1;
      visibility: visible; }

    .NavItem__link {
      line-height: normal;
      height: auto;
      font-weight: $font-w-regular;
      padding: 15px 21px;
      top: unset;

      &:before {
        content: none; }

      &:hover,
      &:focus,
      &.focus-visible:focus:not(:focus-visible) {
        background: rgba($color-primary, .05);
        color: $color-primary;
        font-weight: $font-w-bold; }
      &:focus:not(:focus-visible) {
        background: none;
        color: unset;
        font-weight: $font-w-regular; } }

    &Back.btnLink {
      @include font(11px, 14px);
      font-weight: $font-w-semi-bold;
      color: #37415199;
      text-transform: uppercase;
      padding: 15px 21px;

      &:focus {
        text-decoration: none; }

      i {
        margin-right: 5px; } } }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    display: none;

    &.visible {
      display: block; } } }

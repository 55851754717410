@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-form";

.DeviceSelector {
  padding: 3rem;
  background: $color-bg-blueish;
  border-radius: 5px;
  position: absolute;
  top: 14rem;
  left: 2rem;
  min-width: 60rem;
  max-width: 100%;

  @include screen-xs {
    top: 2rem;
    left: 2rem;
    right: 2rem;
    min-width: auto; }

  &__blur {
    margin-top: 3rem;
    display: flex;
    align-items: baseline;
    position: relative;
    min-height: 4rem;

    .Loader {
      background: rgba($color-bg-blueish, .8);
      left: -4px; } }

  &__container {
    position: absolute;
    z-index: 999;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;

    &.visible {
      visibility: visible;
      opacity: 1; } }

  label {
    @include label;
    display: flex;
    align-items: center;
    gap: 8px; }

  &__close {
    position: absolute;
    right: 1rem;
    top: 1rem; }

  &__select {
    @include select;
    margin-top: 1rem;
    min-width: 40rem;

    @include screen-xs {
      min-width: 30rem; }

    + label {
      margin-top: 3rem; }

    &Value {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 6px; }

    &.native {
      position: relative;
      select {
        @include select;
        width: 100%;
        padding-left: 1rem;
        min-height: 7rem;
        border-radius: 0;
        border: 1px solid $color-border; } } } }

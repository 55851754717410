@import "~theme/colors";
@import "~theme/type";


.Avatar {
  display: block;
  position: relative;
  border-radius: 50%;
  border: 2px solid $color-grey-60;
  justify-content: center;
  letter-spacing: 0.02em;
  flex: 0 0 auto;
  color: $color-grey-60;

  &.hasAvatar {
    border: none; }
  &.active.hasAvatar {
    box-shadow: 0 0 0 1px $color-white, 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1); }
  &.active {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1); }
  &.bordered {
    box-shadow: 0 0 0 1px $color-white; }

  &._sz_32 {
    @include font(11px, 16.5px);
    height: 32px;
    width: 32px;
    border-width: 2px; }
  &._sz_36 {
    @include font(12px, 17px);
    height: 36px;
    width: 36px;
    border-width: 2px; }
  &._sz_48 {
    @include font(14px, 18px);
    height: 48px;
    width: 48px;
    border-width: 3px; }
  &._sz_64 {
    @include font(20px, 1.5);
    height: 64px;
    width: 64px;
    border-width: 3px; }
  &._sz_96 {
    @include font(30px, 1.5);
    height: 96px;
    width: 96px;
    border-width: 4px; }

  &._sz_xxs {
    font-size: 2rem;
    height: 4rem;
    line-height: 3rem;
    width: 4rem; }

  &._sz_xs {
    @include font(11px, 16.5px);
    height: 32px;
    width: 32px; }

  &._sz_sm {
    @include font(20px, 1.5);
    height: 64px;
    width: 64px; }

  &._sz_md {
    font-size: 4rem;
    height: 11rem;
    line-height: 10rem;
    width: 11rem; }

  &._sz_md-lg {
    font-size: 6rem;
    height: 15rem;
    line-height: 13.5rem;
    width: 15rem;

    @include screen-xs {
      font-size: 4rem;
      height: 10rem;
      line-height: 9rem;
      width: 10rem; } }

  &._sz_lg {
    @include font(30px, 1.5);
    height: 96px;
    width: 96px; }

  &._sz_lg-xl {
    @include font(7rem, 1.5);
    height: 15rem;
    width: 15rem;

    @include screen-xs {
      font-size: 5rem;
      height: 15rem;
      line-height: 14rem;
      width: 15rem; } }

  &._sz_xl {
    font-size: 15rem;
    height: 28rem;
    line-height: 27rem;
    width: 28rem; }

  &._sz_md-lg,
  &._sz_lg,
  &._sz_xl {
    border-width: 2px; }

  &__photo {
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    display: block;
    height: 100%;
    width: 100%; }

  &__initials {
    border-radius: 50%;
    display: block;
    width: 100%;
    height: 100%;
    font-family: $font-primary-new;
    font-weight: $font-w-bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center; }

  &.who {
    background-color: $color-white;
    border-color: $color-driver-who;
    color: $color-driver-who; }
  &.why {
    border-color: $color-driver-why;
    background-color: $color-white;
    color: $color-driver-why; }
  &.how {
    border-color: $color-driver-how;
    background-color: $color-white;
    color: $color-driver-how; } }


.AvatarUpload {
  position: relative;
  display: inline-block;
  margin-bottom: 1rem;
  outline: none;
  cursor: pointer;

  &__camera {
    background: $color-grey-60;
    color: rgba($color-white, .74);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;

    span {
      @include font(26px, 24px); } }

  &:focus:not(:focus-visible) &Camera {
    visibility: hidden;
    opacity: 0; }

  &:hover &__camera,
  &:focus &__camera,
  &.focus-visible:focus:not(:focus-visible) &__camera {
    visibility: visible;
    opacity: 1; } }


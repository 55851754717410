@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/common-form";
@import "~theme/scaffolding/scaffolding";


.AuthForm {
  &__field {
    position: relative;
    width: 100%;

    .Checkbox {
      &.err {
        color: $color-secondary; }
      &.disabled {
        cursor: auto;
        opacity: .5; } }

    input,
    textarea {
      &:not([type="checkbox"]) {
        @include font($font-s-sm, $line-height-sm);
        width: 100%;
        border: none;
        border-bottom: 1px solid $color-border;
        background: $color-white;
        min-height: 6rem;
        padding: 0;
        outline: none;
        transition: border-color .3s;

        &:focus {
          border-color: $color-secondary; }
        &.warn {
          border-color: $color-yellow-calm-dark; } } }

    label {
      @include font($font-s-xs-sm, $line-height-xs);
      text-transform: uppercase;
      font-weight: $font-w-medium; }

    &+button {
      margin-top: 5rem; } }

  &__input {
    position: relative;

    &.checkbox {
      display: inline-block;

      label {
        display: flex;
        justify-content: space-between; } } }

  &__hint {
    @include font($font-s-xs, 7rem);
    color: $color-grey-dark;
    font-family: $font-primary-new;
    font-weight: $font-w-bold;
    opacity: .7;
    padding: 0 1em;
    position: absolute;
    text-transform: uppercase;
    transition: opacity .3s;
    right: 0;
    z-index: 9999;

    &:hover {
      opacity: 1; } }

  &__label {
    display: flex;
    align-items: flex-end;
    width: 100%; }

  &__infos {
    line-height: $line-height-sm;
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 9; }

  &__fieldError {
    @include font($font-s-xs-sm, $line-height-xs);
    display: block;
    color: $color-secondary;
    opacity: 0;
    margin-top: 3rem;
    transition: .3s all;

    &.visible {
      opacity: 1;
      margin-top: 0; } }

  &__fieldWarning {
    @include font($font-s-xs-sm, $line-height-xs);
    display: block;
    color: $color-yellow-calm-dark;

    &.withLabel {
      padding-left: 1rem; } }

  &__fieldIcon {
    position: absolute;
    color: $color-yellow-calm-dark;
    top: 1.2rem;
    right: 1.5rem; }

  &__select {
    @include select; }

  &__tooltip {
    &Info {
      @include link($color-grey-heather-dark);
      position: absolute;
      right: 1rem;
      z-index: 2;
      top: 1.2rem; }

    &Info:hover + &Content {
      opacity: 1;
      z-index: 10; }

    &Content {
      @include font($font-s-xs-sm, $line-height-xs);
      opacity: 0;
      transition: all .3s;
      background: $color-white;
      border: 1px solid $color-border;
      position: absolute;
      bottom: calc(100% + 2rem);
      right: -12rem;
      // left: 50%
      padding: 2rem;
      z-index: -1;
      min-width: 40rem;

      &:after,
      &:before {
        top: 100%;
        left: calc(100% - 2.5rem - 12rem);
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none; }

      &:after {
        border-top-color: $color-white;
        border-width: calc(2rem - 1px);
        margin-left: calc(-2rem + 1px); }

      &:before {
        border-top-color: $color-border;
        border-width: 2rem;
        margin-left: -2rem; }

      ul {
        list-style-type: disc;
        list-style-position: outside;
        padding-left: 1.5em;

        ul {
          list-style-type: circle; } } } }

  &__btnContainer {
    text-align: center;

    button {
      width: 100%; } }

  &__validationBullets {
    @include font($font-s-xs-sm, $line-height-sm);
    color: $color-grey-dark-med;

    i {
      @include font($font-s-xs-sm, $line-height-sm);
      color: $color-border;
      margin-right: 1rem;

      &.fa-check-circle {
        color: $color-success; }
      &.fa-exclamation-circle {
        color: $color-red; } }

    &-item {
      display: flex;
      align-items: center;
      font-weight: $font-w-medium; } } }

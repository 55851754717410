@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-table";
@import "~theme/common-form";

.MatchingGroup {
  p {
    @include text-secondary;
    font-weight: $font-w-light;
    margin-bottom: 2rem; }

  h2 {
    @include subtitle-black; }

  h3 {
    @include subtitle-black-caps;
    margin: 0; }

  h4 {
    @include subtitle-black-caps;
    text-transform: none; }

  h4 &__colors_item {
    margin-left: 2rem; }

  &__mixedToast {
    &Title {
      @include subtitle;
      margin: 0;
      color: $color-white; }
    &Error {
      padding: 3rem;
      background: $color-error;
      color: $color-white; }
    &Success {
      padding: 3rem;
      background: $color-success;
      color: $color-white; }
    &Partners {
      color: $color-white;
      display: inline-flex;
      align-items: center;
      border-bottom: 1px solid transparent;
      padding: 1rem 0;
      transition: border-color .3s;
      &:hover {
        border-bottom: 1px solid $color-white; } }

    &Partner {
      display: flex;
      align-items: center;

      .Avatar {
        margin-right: 1rem; } }

    &Partner + &Partner {
      margin-right: 1rem;
      &:before {
        content: '&';
        padding: 0 2rem; } } }

  &__toggler {
    @include font($font-s-xs-sm, $line-height-sm);

    h3 {
      @include font($font-s-sm, $line-height-sm); }

    &Row {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include screen-xs {
        justify-content: flex-start;
        margin-bottom: 2rem; }

      .Toggler {
        margin: 0 1.5rem; } } }

  &__heading {
    display: flex;
    justify-content: space-between;

    @include screen-xs {
      display: block; } }

  &__title {
    @include title-blue-caps;
    margin: 0;

    &All {
      flex: 1 1;
      text-align: right;
      opacity: 0;
      visibility: hidden;
      transition: all .3s;

      button {
        @include label;
        outline: none;
        padding: 0;
        background: none;
        border: 0;
        color: $color-white;
        text-decoration: underline; } } }

  &__row {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    margin: -2.5rem;

    &>* {
      margin: 2.5rem; } }

  &__search {
    display: flex;
    height: 6rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    flex: 1 1 100%; }


  &__ConvGroup {
    background: $color-white;
    margin-bottom: 2rem !important; }

  &__Box {
    background: $color-white;

    &-color {
      &0 {
        border: 1px solid $color-green-bright; }
      &1 {
        border: 1px solid $color-red; }
      &2 {
        border: 1px solid $color-primary; }
      &3 {
        border: 1px solid $color-yellow-calm; }
      &4 {
        border: 1px solid $color-pink; } }

    &Header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 2rem 2rem 0 2rem; }

    &HeaderColor {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 2rem;

      &-color {
        &0 {
          background: $color-green-bright; }
        &1 {
          background: $color-red; }
        &2 {
          background: $color-primary; }
        &3 {
          background: $color-yellow-calm; }
        &4 {
          background: $color-pink; } } } }

  &__card {
    background: $color-white;
    border: 1px solid $color-border;
    padding: 3rem;

    &.grey {
      border: none;
      background: $color-grey-imperceptible;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between; }

    &.greyHeader {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      border: none;
      background: $color-grey-imperceptible; }


    &Header {
      display: flex;
      justify-content: space-between !important;
      align-items: baseline;

      &-Actions {}
      justify-content: flex-end;


      &-grey {
        @include label;
        margin-left: 2rem;
        font-weight: $font-w-medium; }

      &-color {
        &0 {
          background: $color-green-bright; }
        &1 {
          background: $color-red; }
        &2 {
          background: $color-primary; }
        &3 {
          background: $color-yellow-calm; }
        &4 {
          background: $color-pink; } } }

    &ActionHeader {
      display: flex;
      justify-content: space-between;
      align-items: baseline; } }

  &__avatar {
    flex: 0 0 auto; }

  &__matching {
    flex: 1 1 50%;

    &Members {
      flex: 1 1 40%; }

    &Info {
      @include text-secondary;
      @include text-grey; }

    &Row {
      display: flex;
      align-items: center;
      padding: 1rem;
      margin: 1rem;
      cursor: pointer;
      transition: background .3s, border .3s;

      &:hover {
        background: rgba($color-border, .5); }

      &.active {
        outline: 2px solid $color-header-blue;
        font-weight: $font-w-semi-bold; }

      &.matched:not(.active) {
        opacity: .5; } }

    &Name {
      @include font($font-s-sm, $line-height-sm);
      margin-left: 1rem; }

    &Btns {
      margin: -1rem;
      margin-top: 2rem;

      button {
        margin: 1rem; } } }

  &__match {
    display: flex;
    align-items: center;
    flex: 1 1;

    &_removeBtn {
      text-align: right;

      button {
        background: none;
        border: none; } }

    &_started {
      @include label;
      color: $color-success;
      min-width: 13rem;
      padding: 0 1rem; }

    &List {
      max-height: 80rem;
      overflow: auto;
      overflow-x: hidden; }

    &Row {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: 2rem 3rem;
      margin: 0 -3rem;
      transition: background .3s, border .3s;

      &:hover,
      &.active {
        background: rgba($color-blue-playful, .1); }

      .and {
        @include text-primary;
        margin: 0 2rem; }

      .Checkbox {
        margin-right: 2rem; } }

    &Sort {
      margin-left: 2rem;
      @include text-secondary;
      @include text-grey; } }

  &__select {
    @include select;
    margin-top: 3rem; }

  &__toast {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    &Actions {
      margin: 0 -1rem;

      button {
        margin: 0 1rem;

        @include screen-xs {
          margin: 1rem 0;
          width: 100%; } } } }

  &__modal {
    h2 {
      @include subtitle-black; }

    button {
      margin: 0 1rem;

      @include screen-xs {
        margin: 1rem 0;
        width: 100%; } }

    &Schedule {
      p {
        display: inline-block; }

      &Zone {
        padding-left: 8rem; } }

    .react-datepicker-wrapper,
    .react-datepicker__input-container, {
      @include screen-xs {
        width: 100%; } } }

  &__datepicker {
    @include input;
    margin-left: 5rem;

    @include screen-xs {
      margin-left: 0;
      margin-top: 2rem;
      width: 100%; } }

  &__colors {
    margin-left: 2rem;

    &_item {
      display: inline-block;
      vertical-align: baseline;
      width: 2rem;
      height: 2rem;
      background: $color-grey-imperceptible;
      border-radius: 50%;
      margin: 0 .5rem;

      &.color {
        &0 {
          background: $color-green-bright; }
        &1 {
          background: $color-red; }
        &2 {
          background: $color-primary; }
        &3 {
          background: $color-yellow-calm; }
        &4 {
          background: $color-pink; } } } }

  &__duplicate {
    display: flex;

    &Member {
      &:not(:first-child) {
        margin: 0 1rem;
        &:before {
          content: '& '; } } }

    &Cohort {
      margin-left: 1rem;
      &:not(:last-child) {
        &:after {
          content: ','; } } } } }

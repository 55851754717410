@import "~theme/scaffolding/scaffolding";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/fonts";


.NetworkGraph {
  position: relative;
  &__tooltip {
    @include caption;
    text-align: center;
    position: absolute;
    z-index: 3;
    pointer-events: none;
    min-width: max-content;
    padding: 5px 10px;
    letter-spacing: .02em;
    background: $color-grey-90;
    border-radius: 4px;
    color: $color-white;
    font-weight: $font-w-semi-bold;
    opacity: 0;
    visibility: hidden;

    span {
      display: block; } }


  &__connection {
    stroke: $color-border;
    stroke-width: 2; }
  &__avatar {
    stroke: $color-border;
    stroke-width: 2;

    &:focus,
    &:hover {
      outline: none; }

    &:focus &Outline,
    &:hover &Outline {
      stroke-width: 2;
      stroke: $color-focus;
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, .7)); }

    &Outline {
      stroke-width: 0; }

    &.partner {
      cursor: pointer; }

    &.hasAvatar {
      stroke-width: 0; }

    &.who &Circle {
      stroke: $color-driver-who; }
    &.who &Initials {
      fill: $color-driver-who; }
    &.why &Circle {
      stroke: $color-driver-why; }
    &.why &Initials {
      fill: $color-driver-why; }
    &.how &Circle {
      stroke: $color-driver-how; }
    &.how &Initials {
      fill: $color-driver-how; }

    &.me &Initials {
      @include font(30px, normal); }
    &.partner &Initials {
      @include font(20px, normal); }
    &Initials {
      fill: $color-grey-60;
      stroke: none;
      font-weight: 600; } } }

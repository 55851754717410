@import "~theme/btn/btn";
@import "~theme/clearfix";
@import "~theme/responsive/responsive";
@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/img-url";

.Header {
  @include clearfix;
  height: $header-h;
  background: $color-white;
  border-bottom: 4px solid $color-grey-50;
  width: 100%;
  position: relative;
  z-index: 9;

  &.transparent {
    border-color: transparent; }

  &:after {
    content: "";
    width: 100%;
    height: 4px;
    background: $color-grey-50;

    @at-root .transparent#{&} {
      background: none; } }

  &__notification {
    $size: 16px;
    $dot-size: 2px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -8px;
    margin-left: 3px;

    &:after {
      content: '';
      position: absolute;
      top: -$dot-size;
      right: -$dot-size;
      width: $dot-size;
      height: $dot-size;
      border: $dot-size * 1.5 solid $color-error-new;
      background: $color-white;
      border-radius: 50%;
      outline: 1px solid $color-white; }

    .fa-comment-lines {
      font-size: $size;
      color: $color-grey-60; } }

  &__label {
    @include label;
    @include font(9px, 12px);
    padding: 2px 8px;
    margin-left: 8px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    border: 1px solid $color-error-new;
    color: $color-error-new;
    background: rgba($color-error-new, .1);
    font-weight: $font-w-semi-bold;

    @include screen-xs {
      right: 21px; } }

  &__content {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 3rem;
    max-width: 1440px;
    margin: auto;

    @include screen-xs {
      padding-left: 2rem; }

    &[dir="rtl"] {
      flex-direction: row-reverse; } }

  &__burger {
    @include screen-sm-plus {
      display: none; }

    > button {
      min-width: 30px;
      padding: 0;
      text-align: center; } }

  &__logo {
    width: $header-logo-w !important;
    height: 100%;
    padding-top: 5px;

    @include screen-xs {
      width: $header-logo-w-mobile !important; }

    &.mobile {
      display: block;
      @include screen-sm-plus {
        display: none; } }

    &.desktop {
      display: none;
      @include screen-sm-plus {
        display: block; } } }

  &__nav {
    @include font(20px, 30px);
    color: $color-text-grey;
    font-weight: $font-w-medium;
    flex: 1 1;
    display: flex;

    @include screen-xs {
      display: none;
      position: absolute;

      &.visible {
        display: block; } } }

  &__mobileSeparator {
    flex: 1 1;
    @include screen-sm-plus {
      display: none; } } }

@import "~theme/btn/btn";
@import "~theme/clearfix";
@import "~theme/grid/grid";
@import "~theme/scaffolding/scaffolding";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/img-url";


.AuthLayout {
  &.page {
    background: $color-white; }

  &__container {
    @include container;
    padding: 0;
    background: $color-white;
    color: $color-black;
    display: flex;
    min-height: 100vh;

    @include screen-xs {
      padding-top: $Header__h; } }

  &__layout {
    padding-top: 0; }

  &__header {
    background: none;
    z-index: 99;
    position: fixed; }

  &__content {
    flex: 1 1;
    padding: 5rem 0;
    min-height: 100vh;
    display: flex;
    overflow-y: auto;
    z-index: 80;

    @include screen-sm-minus {
      min-height: unset;
      padding: 0; }

    &Aligned {
      margin: auto;
      width: 100%;
      max-width: 90rem;
      padding: 0 5rem; } }

  &__contents {
    max-width: 70rem;
    margin: auto;
    width: 100%; }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 60%;
    background: url($url-auth-background) left top / cover no-repeat;

    @include screen-sm-minus {
      display: none; }

    @include screen-min(1800px) {
      width: 55%; }

    &Placeholder {
      flex: 1.5 1.5;

      @include screen-sm-minus {
        display: none; } }

    &Overlay {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba($color-purple-passion, .5);
      color: $color-white; } }

  &__heading {
    @include font($font-s-2, $line-height-2);
    font-family: $font-secondary-new;
    text-align: center;
    margin-bottom: 2rem; }

  &__subheading {
    @include font($font-s-xs, $line-height-xs);
    color: $color-grey-heather-dark;
    font-family: $font-primary-new;
    font-weight: $font-w-black;
    margin-bottom: 2rem;
    text-transform: uppercase; }

  &__hint {
    @include font($font-s-sm, $line-height-sm);
    margin-top: 2rem;
    text-align: center;
    font-weight: $font-w-semi-bold;

    * {
      padding: 0 1rem; }

    a {
      color: $color-header-blue;
      font-weight: $font-w-semi-bold; } }

  &__msg {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    max-width: 110rem;
    width: 100%;

    p {
      @include font($font-s-0, $line-height-sm);
      font-family: $font-primary-new;

      @include screen-xs {
        @include font($font-s-sm, $line-height-xs); }

      & + p {
        margin-top: 3rem; } } }

  &__result {
    @include p;
    margin-top: 5rem;
    text-align: center; }

  &__link {
    &_default {
      @include link; }
    &_white {
      @include link($color-white); }
    &_pink {
      @include link($color-pink-bright);
      font-weight: $font-w-medium; }
    &_dark {
      @include link_dark; } } }

@import "~theme/btn/btn";
@import "~theme/clearfix";
@import "~theme/colors";
@import "~theme/type";
@import "~theme/img-url";

@mixin screen {
  @media only screen {
    @content; } }


.SelfAssessmentReport {
  @include screen {
    background: $color-grey-heather;
    padding-bottom: .5in;
    padding-top: .5in;
    min-width: 11in; }

  ol {
    list-style: decimal inside;

    li {
      max-width: 20pt;
      text-align: center;
      margin-bottom: 15pt; } }

  .margin-top-5 {
    margin-top: 5pt; }

  .margin-top-25 {
    margin-top: 25pt; }

  &__links {
    text-align: center; }

  &__pdfLink {
    display: none;

    @include screen {
      @include btn(primary, solid, $color-pink);
      display: inline-block;
      margin-bottom: .5in;
      margin-right: .2in;
      min-width: 50rem; } }

  &__link {
    display: none;

    @include screen {
      @include btn(primary, outlined, $color-pink);
      background: $color-white;
      display: inline-block;
      margin-bottom: .5in;
      margin-left: .2in;
      min-width: 50rem; } }

  &__page {
    position: absolute;

    &_left {
      left: 0; }
    &_right {
      right: 0; }
    &_bottom {
      bottom: 0; }
    &_pink.PrintPage__page {
      background: $color-pink-pale; } }

  &__row {
    position: relative; }

  &__column {
    display: inline-block;
    vertical-align: middle; }

  &__cover {
    &Bg {
      background: no-repeat 50% 80% / 105% url($url-report-cover);
      height: 100%;
      width: 100%; }

    &Title {
      @include font(30pt, 32pt);
      font-weight: $font-w-extra-bold;
      margin: 10pt 0;
      color: $color-pink; }

    &Name {
      @include font(18pt, 32pt);
      font-weight: $font-w-extra-bold;
      margin: 10pt 0;
      color: $color-pink; }

    &Text {
      @include font(13pt, 30pt);
      font-weight: $font-w-semi-bold;
      margin: 20pt 0; }

    &Logo {
      margin: 10pt 0; } }

  &__archetypeTitle {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 45rem;
    position: relative;
    top: 45%;
    transform: translateY(-50%);
    width: 100%; }

  &__logo {
    background-size: contain;
    background-repeat: no-repeat;
    height: 60pt;
    margin-top: 30pt; }

  &__footer {
    color: $color-report-purple;
    display: block;

    &Number {
      padding-right: 5pt; }

    &.right {
      text-align: right; }

    &.right &Number {
      padding-right: 0;
      padding-left: 5pt; } }

  &__title {
    @include font(36pt, 40pt);
    color: $color-pink;
    font-weight: $font-w-extra-bold;
    margin-bottom: 25pt; }

  &__subtitle {
    @include font(14pt, 20pt);
    font-family: $font-primary;
    font-weight: $font-w-bold;
    color: $color-pink;
    text-transform: uppercase;

    &.who {
      color: $color-driver-who; }
    &.why {
      color: $color-driver-why; }
    &.how {
      color: $color-driver-how; }

    p {
      color: $color-black;
      text-transform: none;
      font-style: italic; } }

  &__text {
    @include font(14pt, 18pt);
    font-family: $font-secondary-new;
    font-weight: $font-w-light;
    text-align: justify;

    & + & {
      margin-top: .22in; }

    &_borderedPink {
      border-top: 2pt solid $color-pink;
      border-bottom: 2pt solid $color-pink;
      margin-top: 8pt;
      padding: 8pt;
      background: rgba($color-white, .5); }

    &_noMargin {
      margin: 0; } }

  &__driver {
    border-top: 3px solid;
    padding-top: 5pt;

    &Icon {
      display: inline-block;
      vertical-align: middle;
      height: 0.6in;
      margin-right: 5pt; }

    &_who {
      border-color: $color-driver-who; }
    &_why {
      border-color: $color-driver-why; }
    &_how {
      border-color: $color-driver-how; }

    &~& {
      margin-top: 20pt; }

    &Heading {
      margin-bottom: 10pt;
      margin-top: 5pt; } }

  &__powerUp & {
    &__text {
      font-family: $font-primary;
      text-align: left; }

    &__column {
      max-width: 80%; } }

  &__powerUp {
    padding: 5pt 0;

    &_score {
      @include font(14pt, 16pt);
      font-weight: $font-w-semi-bold;
      text-align: right;
      padding: 2pt 5pt;
      float: right;
      max-width: 20%;
      display: inline-block;
      vertical-align: middle;

      &.who {
        color: $color-driver-who; }
      &.why {
        color: $color-driver-why; }
      &.how {
        color: $color-driver-how; } }

    &_more {
      @include font(10pt, 12pt);
      color: $color-grey-albatross;
      display: inline-block;
      vertical-align: middle; } }

  &__avatar {
    width: 30pt;
    height: 30pt;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    text-align: center;
    background: $color-grey-blue;
    margin-top: 5pt;
    margin-right: 5pt;

    &Photo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover; }

    &Initials {
      @include font(12pt, 30pt);
      color: $color-grey-albatross;
      font-family: $font-primary-new;
      font-weight: $font-w-regular; } } }

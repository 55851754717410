@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/grid/grid";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-table";
@import "~theme/common-form";

.Workshop {
  p {
    @include text-secondary; }

  h2 {
    @include subtitle-black; }

  h3 {
    @include subtitle-black-caps;
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: baseline;

    span {
      @include label; } }

  h4 {
    @include subtitle-black-caps;
    text-transform: none; }

  .Avatar {
    display: inline-block;
    vertical-align: middle;
    margin-right: 2rem; }

  &__search {
    height: 6rem;
    flex: 1 1 100%;
    display: flex;
    max-width: 70rem;

    @include screen-xs {
      display: flex;
      justify-content: center; }

    input {
      @include font($font-s-sm, $line-height-6);
      color: $color-grey-albatross;
      border: (1rem / 6) solid $color-grey-heather;
      border-radius: 0;
      outline: none;
      height: 100%;
      width: 100%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      transition: border-color .3s, color .3s;

      &:focus {
        border-color: $color-grey-albatross;
        color: $color-black; } } }

  &__searchContainer {
    flex: 1 1;

    @include screen-xs {
      margin-bottom: 3rem; } }

  &__searchBtns {
    a,
    button {
      width: 100%; }

    a + button {
      margin-top: 2rem; } }

  &__columnheading {
    display: flex;
    justify-content: space-between; }

  &__heading {
    display: flex;
    justify-content: space-between; }

  &__title {
    @include title-blue-caps;

    a {
      color: inherit;

      .fa-chevron-left {
        font-size: .7em;
        margin-right: .5em; } } }

  &__select {
    @include select;
    max-width: 100%;

    @include screen-xs {
      max-width: 100%; } }

  &__subtitle {
    @include subtitle-black;
    margin-top: 3rem; }

  &__row {
    display: flex;
    justify-content: space-between;

    @include screen-xs {
      display: block; } }

  &__matching {
    display: flex;
    justify-content: space-between;
    margin: -2rem;
    margin-top: 2rem;

    @include screen-xs {
      display: block; } }

  &__column {
    flex: 1 1;
    margin: 2rem; }

  &__progress {
    margin-top: 4rem;
    margin-bottom: 3rem;
    height: 3rem;
    display: flex;

    &Part {
      position: relative;
      cursor: pointer;
      transition: opacity .3s;

      &.notActive {
        opacity: .3; }

      &.active.notActive {
        opacity: 1; }

      &.assessed {
        background: $color-success; }
      &.notAssessed {
        background: $color-yellow-calm-dark; }
      &.notRegistered {
        background: $color-red; } }

    &Label {
      @include label;
      @include font($font-s-xs, $line-height-xs);
      position: absolute;
      left: 0;
      white-space: nowrap;

      span {
        margin-left: 1rem;
        display: block; }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background: $color-grey-med; }

      &.top {
        bottom: 100%;
        padding-bottom: .5rem; }
      &.bottom {
        top: 100%;
        padding-top: .5rem; } } }

  &__list {
    padding: 0 3rem;
    max-height: 80rem;
    overflow: auto; }

  &__list + &__listHeading {
    border-top: 1px solid $color-border; }

  &__listHeading {
    @include subtitle-black-caps;
    @include font($font-s-sm, $line-height-sm);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-border;
    padding: 3rem;
    margin: 0; }

  &__listItem {
    @include font($font-s-sm, $line-height-sm);
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;

    &Actions {
      font-weight: $font-w-medium;
      display: flex;

      button.error, button.action {
        margin-left: 2rem; }

      .success {
        color: $color-success; }
      .warning {
        color: $color-yellow-calm-dark; }
      .error {
        color: $color-red; }
      .invited {
        @include label;
        color: $color-purple-passion; }
      .action {
        color: $color-grey-dark-med;
        padding: 2px;
        margin-right: -.5rem; } }

    &Action {
      position: relative;
      display: inline-block;
      padding: 1rem;

      &:hover &Tooltip {
        opacity: 1;
        visibility: visible; }

      &Tooltip {
        @include font($font-s-xs-sm, $line-height-sm);
        font-weight: $font-w-medium;
        text-transform: uppercase;
        position: absolute;
        width: max-content;
        padding: 1rem 2rem;
        top: -.5rem;
        right: calc(100% + 1rem);
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s; }

      &.success &Tooltip {
        background: mix($color-success, $color-white, 5%); }
      &.warning &Tooltip {
        background: mix($color-yellow-calm-dark, $color-white, 5%); }
      &.error &Tooltip {
        background: mix($color-red, $color-white, 5%); }
      &.action &Tooltip {
        background: mix($color-grey-blue, $color-white, 5%);
        border: 1px solid $color-grey-blue;
        padding: .75rem 1.75rem;
        font-weight: 400; } } }

  &__avatar {
    margin-right: 1rem; }

  &__note {
    @include label;
    margin-top: 1rem;
    font-weight: $font-w-medium;
    font-size: $font-s-xs; } }

@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-table";
@import "~theme/grid/grid";

.Analytics {
  p {
    @include text-secondary;
    font-weight: $font-w-light; }

  .row {
    display: flex;
    flex-flow: row wrap;
    justify-content: center; }

  .row-group {
    padding: 5rem; }

  .card {
    padding: 3rem;
    margin-top: 3rem; }

  &__title {
    @include title-blue-caps;
    margin: 0; }

  &__subtitle {
    @include subtitle-black;
    margin-bottom: 0;

    span {
      @include subtitle-black-caps; } }

  &__chart {
    width: auto !important;
    display: block;
    margin: auto;

    @include screen-xs {
      width: 100% !important; }

    text {
      @include label;
      font-weight: 400;
      text-anchor: middle; }

    &Label {
      @include label;
      text-align: center;
      margin-top: 2rem; } }

  &__svgContentResponsive * {
    transition: all .5s; }

  &__rect {
    stroke: $color-grey-med;

    &-completed {
      fill: $color-green-bright; }
    &-started {
      fill: $color-yellow-calm-light; }

    &-negative {
      fill: $color-red; }
    &-positive {
      fill: $color-green-bright; } }

  &__axis {
    line {
      stroke: $color-border; } } }

@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/scaffolding/scaffolding";
@import '~theme/grid/grid';

.TranslationError {
  display: flex;
  background: $color-secondary;
  border-bottom: 1px solid $color-border;

  &__container {
    @include container;
    padding: 8px 3rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 16px;
    color: $color-white;

    @include screen-xs {
      padding-left: 2rem; }

    @include screen-min(1450px) {
      padding: 8px 0; } }

  div {
    flex: 1 1; }

  p {
    @include p-2;
    color: inherit; }

  button {
    font-size: 14px;
    line-height: normal;
    background: none;
    color: inherit;
    border-color: $color-white; }

  &__mail {
    color: $color-white;
    text-decoration: underline; } }

@import "~theme/scale";
@import "~theme/colors";
@import "~theme/type";
@import "~theme/fonts";
@import "~theme/common-form";
@import "~theme/scaffolding/scaffolding";

.DemographicQuestionsCheck {
  max-width: 70%;
  min-width: 70%;

  @include screen-min(1440px) {
    max-width: 50%;
    min-width: 50%; }

  @include screen-sm-minus {
    max-width: 90%; }

  @include screen-xs {
    max-width: 100%;
    margin: 0; }

  h1 {
    @include subtitle-blue;
    text-align: center; }

  img {
    max-width: 30rem;
    display: block;
    margin: auto;

    @include screen-xs {
      max-width: 20rem; } }

  &__onboarding {
    display: flex;
    align-items: flex-start;

    @include screen-xs {
      display: block; }

    p {
      margin: 2rem 0;

      &.smaller {
        @include text-secondary;
        @include text-grey; } }

    &__text {
      @include screen-sm-plus {
        margin-left: 3rem; } } }

  &__btns {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem; }

  &__overlay {
    z-index: 20000; }

  .Assessment__item + &__btns {
    margin-top: 5rem; } }

.DemographicQuestionsUpdateModal {
  .Loader {
    background: rgba($color-white, .5); }

  .Form__field + .Form__field {
    border-top: 1px solid $color-border;
    padding-top: 24px; }

  .Form__field {
    display: flex;
    align-items: baseline;
    margin: 24px 0;

    @include screen-xs {
      display: block; }

    input {
      @include input-new; } }

  .Form__label {
    flex: 0 0 18%;

    label {
      @include caption-caps; } }

  .Form__input {
    flex: 1 1; }

  .Form__select {
    @include select-new;

    &.react-select__control {
      border: none !important; }

    &.react-select__value-container {
      padding: 0; } }

  &__error {
    @include toast; } }

@import "~theme/responsive/responsive";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";

.Subnav {
  &__nav {
    background: $color-white;
    border-bottom: 1px solid $color-grey-light-med;
    display: flex;
    padding: 4rem 5rem;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    @include screen-max(400px) {
      padding: 2rem 3rem; }

    &Items {
      display: flex;
      height: 6rem;
      width: 100%;

      @include screen-xs {
        padding: 0; } }

    &Item {
      @include font($font-s-xs, $line-height-0);
      color: $color-grey-heather-dark;
      font-weight: $font-w-bold;
      letter-spacing: .2rem;
      text-transform: uppercase;
      border-bottom: 2px solid transparent;

      @include screen-md-minus {
        border-width: 4px; }

      &~& {
        margin-left: 4rem; }

      &:focus {
        border-color: rgba($color-pink, .5); }

      &.active {
        border-color: $color-pink;
        color: $color-pink; } } } }

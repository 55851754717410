@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-table";
@import "~theme/common-form";

$step-size: 5.5rem;
$screen-xxs: 420px;

.Conversation {
  &.page {
    background: $color-bg-darker; }

  @mixin textarea {
    @include font($font-s-sm, $line-height-sm);
    border: 1px solid $color-border;
    border-radius: 5px;
    resize: none;
    outline: none;
    padding: 1rem 1.5rem;
    transition: border-color .3s;

    &:focus {
      border-color: $color-primary; } }

  p {
    @include text-secondary;
    font-weight: $font-w-light; }

  .Loader {
    border-radius: 3px; }

  .Questions__field:last-child {
    margin-bottom: 0; }

  .max-width {
    max-width: 70%;
    margin: auto;
    margin-bottom: 2rem;

    @include screen-xs {
      max-width: unset; } }

  &__printout {
    color: inherit;
    margin: 0 1rem;
    text-decoration: underline; }

  &__tip {
    @include font($font-s-sm, $line-height-sm);
    color: $color-secondary;
    font-weight: $font-w-regular;
    margin-top: 1rem;
    display: flex;
    align-items: baseline;

    @include screen-xs {
      @include font($font-s-xs, $line-height-xs); }

    span {
      margin-right: 1rem; }

    &Icon {
      width: 5.5rem;
      height: 5.5rem;
      margin-right: 2rem;

      @include screen-xs {
        width: 4rem;
        height: 4rem;
        margin-right: 1rem; }

      &Bg {
        background: $color-secondary;
        padding: 1rem;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        display: flex;

        img {
          margin: auto;
          width: 2.7rem;

          @include screen-xs {
            width: 2rem; } } } } }

  &__text-pink {
    @include text-primary;
    color: $color-pink;

    .fa-user-friends {
      margin-right: 1rem; } }

  &__text-grey {
    @include text-primary;
    color: $color-grey-dark-med;

    .fa-user-friends {
      margin-right: 1rem; } }

  &__title {
    @include title-blue-caps;
    margin: 0;

    &Modal {
      text-align: center; } }

  &__info {
    div {
      text-align: center; }

    &Action {
      @include text-secondary;
      color: $color-primary;
      font-weight: $font-w-semi-bold;
      display: inline-block;
      margin-right: 3rem;

      span {
        margin-right: 1rem; } }

    button {
      margin-left: 3rem; }

    &Actions {
      margin: -2rem;

      button,
      a {
        margin: 2rem; } } }

  &__subtitle {
    @include subtitle-black;
    @include font($font-s-0, $line-height-0);
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;

    &Action,
    &Schedule {
      @include text-secondary;
      color: $color-primary;

      span {
        margin-right: 1rem; } }

    &-img {
      height: $line-height-0;
      display: inline-block;
      vertical-align: middle;
      margin-left: 1rem; } }

  &__trademark {
    @include font($font-s-xs, inherit);
    vertical-align: super;
    margin-left: .5rem;
    line-height: 3rem; }

  &__heading {
    margin-bottom: 3rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    *:first-child {
      flex: 1 1; } }

  &__avatars {
    display: flex;
    align-items: center;

    @include screen-xs {
      flex: 1 1 100%;
      margin-top: 2rem; }

    &Item {
      .Avatar {
        margin: 0 2rem;

        @include screen-xs {
          margin-left: 0; } }

      .Avatar,
      &Text {
        display: inline-block;
        vertical-align: middle; }

      &Text {
        @include text-secondary;
        line-height: 3rem; } } }

  &__select {
    @include select; }

  &__cards {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    margin: -2.5rem; }

  &__left {
    flex: 1 1 45rem; }

  &__right {
    flex: 1 1 60%;
    max-width: 100%; }

  &__right &__card {
    &Title {
      font-weight: $font-w-medium; }

    &Heading {
      border-radius: 3px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      text-transform: none;
      padding: 2rem 3rem; } }

  &__goals {
    margin: 2.5rem;
    position: relative;

    &Card.ConversationGoals {
      border-radius: 3px; } }

  &__card {
    border-radius: 3px;
    box-shadow: 0 3px 9px 0 rgba($color-black, .1);
    background: $color-white;
    flex: 0 0 calc((100% / 3) - 6rem);
    position: relative;
    margin: 2.5rem;

    @include screen-xs {
      margin: 2.5rem 0; }

    ol {
      list-style: disc outside;
      @include font($font-s-sm, $line-height-sm);
      margin-bottom: 0;
      padding-left:  3rem; }

    &Divider {
      border-bottom: 1px solid $color-border;
      margin-bottom: 5rem; }

    &Img {
      width: 25rem;
      height: 25rem;
      background-size: cover; }

    &Title {
      @include subtitle;
      line-height: 1;
      color: $color-white;
      margin: 0;

      &.mobile {
        display: inline;
        vertical-align: -35%; } }

    &Description {
      margin: auto;
      width: 100%;

      .Conversation__subtitle {
        @include font($font-s-sm, $line-height-sm); }

      p {
        margin: 0; }

      ol {
        @include font($font-s-sm, $line-height-sm);
        list-style: decimal inside;
        margin-bottom: 3rem; }

      ul {
        list-style: none;

        li {
          @include font($font-s-sm, $line-height-sm);
          font-weight: $font-w-regular;
          display: flex;
          align-items: baseline;

          &:before {
            content: '\2022';
            color: $color-primary;
            font-size: 1.5em;
            margin-right: 1rem; } } }

      a {
        color: inherit;
        text-decoration: underline;
        margin-left: 1rem; }

      &.fullscreen {
        margin: 0;

        div {
          height: 100%; } } }

    &Content {
      @include font($font-s-sm, $line-height-sm);
      padding: 1rem 2rem;
      position: relative;

      &.notes {
        padding: 2rem 3rem; }

      &Buttons {
        margin: -1rem;
        text-align: right;

        button {
          margin: 1rem; } } }

    &Btn {
      margin-top: 3rem;
      text-align: center;

      button + button {
        margin-left: 2rem; }

      p {
        margin-bottom: 1rem;
        margin-top: 3rem; } }

    &Subheading {
      @include label;
      @include font($font-s-sm, $line-height-sm);
      color: $color-primary;
      background: rgba($color-primary, .1);
      padding: 1rem 3rem;
      border-radius: 4px;
      margin-top: .5rem;

      &-count {
        opacity: .5;
        display: inline-block;
        width: 2rem;
        margin-right: 2rem;
        margin-left: -1rem; }

      &.active {
        background: $color-primary;
        color: $color-white; } }

    &Heading {
      @include label;
      @include font($font-s-sm, $line-height-sm);
      position: relative;
      padding: 1rem 2rem;
      border-radius: 4px;
      background: $color-white;
      color: $color-black;

      &.blue {
        background: $color-primary;
        color: $color-white;
        @include screen-xs {
          display: none; } }

      &-set {
        @include label;
        color: $color-white;

        &.lowercase {
          @include text-primary;
          text-transform: none;
          font-weight: $font-w-regular; }

        &.tip span {
          font-weight: $font-w-bold; } }

      &-avatar {
        border: 0;
        position: absolute;
        top: 100%;
        transform: translateY(-50%);
        z-index: 3;

        &.left {
          left: 3rem; }

        &.right {
          right: 3rem; } }

      &.flex {
        display: flex;
        justify-content: space-between;
        align-items: center; }

      &-title {
        flex: 1 1; }

      &-expand {
        @include font(4rem, 4rem);
        width: 6rem;
        height: 6rem;
        margin-left: 2rem;
        color: $color-primary;
        border-radius: 3px;

        &:hover,
        &:focus {
          background: rgba($color-border, .5); } } }

    &.questions {
      max-width: 60rem; } }

  &__reschedule {
    border: 0;
    background: none; }

  &__conversation {
    &Error {
      @include text-primary;
      color: $color-red;
      background: rgba($color-red, .05);
      padding: 1rem 2rem;
      margin-bottom: 3rem;

      button {
        background: none;
        border: none;
        padding: 0;
        text-decoration: underline; } }

    &Description {
      @include subtitle-black;
      @include font($font-s-1, $line-height-1);
      margin-top: 7rem;

      &Reschedule {
        text-align: center;
        color: $color-pink; }

      &Goals {
        margin-bottom: 2rem; }

      span {
        margin-left: 1rem;
        font-weight: $font-w-semi-bold;

        &:not(:last-child):after {
          content: ','; } }

      ul {
        margin: 2rem 0; }

      li a {
        @include text-primary;
        color: $color-black;
        text-decoration: underline; }

      &Start {
        margin-bottom: 2rem;
        font-size: $font-s-sm; } }

    &Accept {
      display: flex;
      justify-content: center;
      margin: -1rem;

      button {
        margin: 1rem;
        flex: 1 1;
        max-width: 30rem; } }

    &Resume {
      padding: 8rem 0 4rem;

      & > div:first-of-type {
        padding: 0 5rem 5rem; }

      h1 {
        font-size: $font-s-0;
        font-weight: $font-w-bold; }

      p {
        font-weight: $font-w-medium; } } }

  &__results {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    border-top: 1px solid $color-border;

    h4 {
      @include subtitle-blue;
      @include font($font-s-3, $line-height-3);
      margin: 0;
      padding-right: 2rem;

      @include screen-xs {
        @include font($font-s-2, $line-height-2); }

      span {
        font-size: .5em;
        line-height: .5em; } }

    .font-primary {
      flex: 1 1 70rem; } }

  &__progress {
    display: flex;
    margin: auto;
    max-width: 150rem;
    margin-bottom: 3rem;
    padding: 0 5rem;

    @include screen-xs {
      padding: 0; }

    &Step {
      display: flex;

      &:not(:first-child) {
        flex: 1 1; }

      &Label {
        @include label;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%); }

      &Bar {
        flex: 1 1;
        background: $color-grey-med;
        height: 2px;
        position: relative;
        top: 2.5rem;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 100%;
          top: 0;
          bottom: 0;
          background: $color-blue-playful;
          transition: right .3s; }

        &-dot {
          position: absolute;
          left: calc(50% - 2rem);
          top: -.7rem;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          background: inherit; } }

      &Circle {
        position: relative;
        width: $step-size;
        height: $step-size;
        border-radius: 50%;
        border: 2px solid $color-grey-med;
        transition: border-color .3s, background .3s;

        span {
          @include label;
          line-height: calc(#{$step-size} - 4px);
          display: block;
          text-align: center;
          color: $color-grey-heather-dark; }

        &_text {
          @include label;
          line-height: 3.5rem;
          text-align: center;
          position: relative;
          left: 2.5rem;
          padding-top: 1rem;
          width: 0;

          @include screen-xs {
            display: none; }

          span {
            display: block;
            width: 20rem;
            transform: translateX(-50%); } } }

      &.active & {
        &Circle {
          border-color: $color-blue-playful;

          span {
            color: $color-blue-playful;
            font-weight: $font-w-bold; } }

        &Bar:after {
          right: 0; } }

      &.finished & {
        &Circle {
          border-color: $color-blue-playful;
          background: $color-blue-playful;

          span {
            color: $color-white; } }

        &Bar:after {
          right: 0; } }

      &.finished &,
      &.active & {
        &Bar-dot {
          background: $color-blue-playful; } } } }

  &__offline {
    text-align: center;
    max-width: 100rem;

    img {
      width: 17rem; }

    h2 {
      @include font($font-s-1, $line-height-1);
      font-weight: $font-w-semi-bold;
      margin-top: 2rem;

      @include screen-xs {
        @include font($font-s-0, $line-height-0); }

      &.subtitle {
        @include font($font-s-0, $line-height-0);
        font-weight: $font-w-medium;

        @include screen-xs {
          @include font($font-s-sm, $line-height-sm); } } }

    a {
      @include font($font-s-sm, $line-height-sm);
      font-weight: $font-w-medium;
      margin: 4rem 0; }

    p {
      @include font($font-s-sm, $line-height-sm);
      font-weight: $font-w-regular;
      opacity: .7;
      max-width: 80rem;
      margin: auto;
      margin-bottom: 4rem;

      &.error {
        opacity: 1;
        color: $color-error; } } }


  &__modal {
    &Btns {
      margin: -1rem;
      margin-top: 2rem;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;

      button {
        margin: 1rem;
        min-width: 40rem;

        @include screen-xs {
          flex: 1 1; } } }

    &Info {
      margin: 2rem 0;
      padding: 1rem 2rem;

      ul {
        padding-left: 4rem;
        list-style: initial; }

      p:first-of-type {
        font-weight: $font-w-medium; } }

    &Agreement {
      margin-left: 3rem; }

    &Schedule {
      padding: 0;

      @include screen-xs {
        overflow: auto; }

      &Header {
        text-align: left;
        font-size: $font-s-1; }

      i {
        color: $color-driver-who; } }

    &Content {
      overflow: auto; } }

  &__question {
    border-top: 1px solid $color-border;

    &Action {
      .Questions__fieldRadio {
        grid-template-columns: repeat(3, 1fr);
        margin: 0; }

      .fa-square {
        font-weight: 300; } }

    &Confirmation {
      display: flex;
      padding: 2rem;

      &-info {
        margin-right: 2rem; }

      &-icon {
        display: flex;
        font-size: 8rem;
        color: $color-grey-heather;

        &.active {
          color: $color-primary; } } }

    &Content {
      @include font($font-s-sm, $line-height-sm);
      font-weight: $font-w-light;
      padding: 3rem;

      &.actions {
        padding-top: 0;

        &.previous {
          padding-bottom: 0; } } }

    &Title {
      @include font($font-s-0, $line-height-sm);
      font-weight: $font-w-semi-bold;
      padding: 3rem;
      transition: box-shadow .3s;

      &.actions {
        padding-bottom: 0; }

      &Description {
        @include font($font-s-sm, $line-height-sm);
        font-weight: $font-w-light;

        .Markdown {
          font-size: inherit;
          font-weight: inherit;
          line-height: inherit;

          *,
          p {
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit; } } } }

    &Arrow {
      display: flex;
      color: $color-primary;

      span.fal {
        @include font($font-s-3, $line-height-2);
        font-weight: 300;
        margin: auto; } }

    &OneWord {
      display: grid;
      grid-template-rows: 1fr auto;
      gap: 2rem;

      .Questions__field {
        margin: 0;

        &Text.small {
          padding: 2rem; } }

      &-title {
        font-weight: $font-w-semi-bold; } }

    &Row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 3rem;

      &.col-2 {
        display: flex;
        align-items: flex-start;
        justify-content: space-between; } }

    &Answers {
      display: flex;
      flex-flow: row wrap;

      &Button {
        text-align: center;
        margin-bottom: 3rem; } }

    &Member {
      @include text-secondary;
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      @include screen-xs {
        @include font($font-s-xs-sm, $line-height-xs); }

      &Asks {
        color: $color-primary;
        margin: 0 1rem;
        font-weight: $font-w-semi-bold; }

      .Avatar {
        margin-right: 1rem;
        flex: 0 0 auto;

        &:last-child {
          margin-left: 1rem;
          margin-right: 0; } } }

    &Answer + &Answer {
      border-left: 1px dashed $color-border;

      @include screen-xs {
        border-left: 0; } }

    &Answer {
      padding-top: 0;
      flex: 1 1;
      padding: 3rem;
      padding-top: 0;

      &-field {
        @include input;
        border-radius: 3px;
        padding: 2rem; }

      .rc-slider {
        margin-top: 0; }

      .Markdown {
        ol,
        ul {
          margin: 1rem 0; }
        h4 {
          @include font($font-s-1, $line-height-1);
          margin-bottom: 1rem; }

        blockquote {
          @include font($font-s-sm, $line-height-sm); } }

      .Questions__field {
        margin: 0;

        &Thumbs {
          @include screen-xs {
            font-size: $font-s-sm; } }

        &Radio {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;

          &Item {
            margin: 0;
            padding-left: 0;
            outline: none;

            &:hover {
              background: none; }

            &:not(.active) {
              color: $color-grey-dark-med; } } } } }

    &Details {
      color: $color-primary;
      margin-top: 2rem;
      width: 100%;

      button {
        background-color: transparent;
        border: none;
        padding: 0;
        margin-right: 1rem;
        outline: none;
        text-transform: uppercase; }

      i {
        font-size: $font-s-xs; } } }

  &__pickDate {
    margin: auto;
    max-width: 100%;
    overflow-x: auto;
    padding-bottom: 3rem;

    table {
      margin: auto; }

    td + td {
      border-left: 1px dashed $color-black; }

    td {
      @include text-primary;
      vertical-align: top;
      padding: 0;

      button,
      span {
        background: none;
        border: none;
        display: block;
        padding: 1rem;
        width: 100%; }

      button {
        cursor: pointer;
        outline: none;
        transition: background .3s;

        &:hover {
          background: rgba($color-primary, .2); } } }

    th + th {
      border-left: 1px dashed $color-white; }

    th {
      @include font($font-s-xs, $line-height-xs);
      background: $color-primary;
      color: $color-white;
      border-color: $color-white;
      padding: 1rem;
      min-width: 20rem;

      &:first-child {
        border-top-left-radius: 5px; }
      &:last-child {
        border-top-right-radius: 5px; }

      span {
        @include label; } } }

  &__vimeoError {
    padding: 5rem;

    span {
      position: absolute;
      right: 3rem;
      top: 3rem; } }

  &__feedback {
    @include screen-sm-plus {
      max-width: 80%; }

    @include screen-md-plus {
      max-width: 60%; }

    @include screen-lg {
      max-width: 50%; } }

  &__feedback {
    textarea {
      @include input;
      padding: 1rem 2rem;
      min-height: 20rem;
      resize: none;
      margin-top: 2rem; }

    &Btn {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        margin: 0 2rem; } }

    &Radio {
      margin-bottom: 4rem;
      padding-left: 4rem; }

    &RadioItem {
      margin-top: 2rem;
      display: flex;
      align-items: baseline;
      cursor: pointer;
      transition: color .3s;
      background: none;
      border: none;
      outline: none;

      &:hover,
      &:focus {
        &:not(.selected) {
          .icon {
            color: $color-primary;
            font-weight: 500; } } }

      &.selected {
        color: $color-primary;
        font-weight: $font-w-semi-bold;

        .icon {
          color: inherit;
          transition: none; } }

      .icon {
        margin-right: 2rem;
        color: $color-grey-light-med;
        transition: color .3s; } } }

  &__qualityModal {
    text-align: center;
    max-width: 150rem;

    button {
      margin-top: 16px; }

    &Input {
      @include input-new; }

    &Radio {
      @include font(16px, 25.6px);
      font-weight: $font-w-regular;
      color: $color-grey-90;
      display: block;
      margin: 4px 0;
      cursor: pointer;

      input {
        position: absolute;
        opacity: 0;

        &:hover,
        &:focus,
        &.focus-visible:focus:not(:focus-visible) {
          & ~ span {
            color: $color-primary; } }

        & ~ span:before {
          font-family: 'Material Icons Outlined';
          content: '\e835'; }

        &:checked ~ span {
          color: $color-primary;
          font-weight: $font-w-semi-bold;
          &:before {
            font-family: 'Material Icons';
            content: '\e834'; } } }

      > span {
        display: flex;
        align-items: center;
        gap: 8px; } }

    &Title {
      @include screen-xs {
        @include font(22px, 30px);
        font-weight: $font-w-semi-bold; } }

    &Body {
      @include screen-xs {
        padding: 1rem; } }

    &Label {
      @include label;
      color: $color-primary;
      font-weight: $font-w-regular;
      margin-bottom: 40px;

      &.small {
        margin-top: 24px;
        margin-bottom: 0; } }

    &Action {
      @include screen-xs {
        button {
          margin-top: 0; } } }

    &Feedback {
      margin-top: 4rem;
      text-align: start;
      textarea {
        @include input;
        padding: 1rem 2rem; } }

    &Options {
      display: flex;
      justify-content: space-between;
      outline: none;

      @include screen-xs {
        display: block;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto; } }

    &Option {
      @include font($font-s-sm, $line-height-0);
      font-weight: $font-w-regular;
      min-width: 18rem;
      cursor: pointer;
      position: relative;

      @include screen-xs {
        display: flex;
        align-items: center;
        text-align: left; }

      @mixin m-selected-option {
        .color {
          visibility: visible;
          opacity: 1; }
        span {
          color: $color-primary;
          font-weight: $font-w-semi-bold;
          border: 1px solid rgba($color-primary, .5); } }

      span {
        padding: 0 .5rem;
        border: 1px solid transparent;
        transition: all .2s ease-out; }

      .img-emoji {
        margin: auto;
        margin-bottom: 2rem;
        display: block;
        width: 8rem;
        min-height: 1px;

        @include screen-xs {
          margin-left: 0;
          margin-right: 2rem; }

        &.color {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          visibility: hidden;
          opacity: 0;
          transition: all .2s ease-out; } }

      &:hover {
        @include m-selected-option; }

      &.selected {
        @include m-selected-option; } } }

  &__confetti {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 1045;

    @include screen-xs {
      display: none;

      &.mobile {
        display: block;
        left: 0; } }

    > div {
      top: 50%;
      left: 50%; } } }

.Markdown {
  &.less {
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; } }

@import "~theme/responsive/responsive";
@import "~theme/scaffolding/scaffolding";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/fonts";
@import "~theme/btn/btn";
@import "~theme/grid/grid";

.ProfileCard {
  min-width: 330px;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;

  @include screen-sm-plus {
    max-width: 330px; }

  @include screen-xs {
    flex: 1 1; }

  .divider {
    margin: 18px 0; }

  &__user {
    display: flex;
    flex-flow: column;
    width: 100%;
    align-items: center;

    &Avatar {
      margin-bottom: 16px; } }

  &__matching {
    width: 100%;
    text-align: center;

    .caption-caps {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 8px 0; }

    i {
      font-size: 1.2em;
      font-weight: 300;
      margin-left: 4px; }

    a {
      display: inline-block; }

    &Response {
      @include p-new;
      display: inline-block;
      padding: 4px 16px;
      min-width: 150px;
      font-weight: $font-w-semi-bold;
      border-radius: 20px;
      background: rgba($color-yellow-calm-light, .2);
      color: mix($color-warning, $color-text-brown, 50%);

      &.open {
        color: mix($color-success-new, $color-text-brown, 80%);
        background: rgba($color-success-new, .15); }
      &.notOpen {
        color: mix($color-error-new, $color-text-brown, 50%);
        background: rgba($color-error-new, .15); } } }

  &__count {
    display: flex;
    gap: 12px;
    text-align: center;

    @include screen-xs {
      display: none; }

    span {
      display: block;
      margin: 0; }

    .caption-caps {
      color: $color-grey-60;
      display: block;

      i {
        display: block;
        font-size: 1.2em;
        font-weight: 300; } }

    &-item {
      &:focus,
      &.focus-visible:focus:not(:focus-visible) {
        outline: none;
        box-shadow: 0 0 0 2px $color-focus;
        border-radius: 4px; } } }

  &__partner {
    display: flex;
    flex-flow: column;

    &Title {
      text-align: center; }

    &Details {
      display: flex;
      align-items: center;
      gap: 8px; }

    &Name {
      display: flex;
      flex-flow: column;

      .section-head {
        margin: 0; } }

    &Info {
      text-align: center;

      p {
        @include p-new; } } }

  &__link {
    @include p-new; } }

@import "~theme/responsive/responsive";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";


.Checkbox {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  border: 1px solid $color-border;
  vertical-align: middle;

  &.checked {
    background: $color-primary;
    border-color: $color-primary; }

  &:hover {
    border-color: $color-primary;
    outline: 1px solid $color-primary; }

  &.checked &__icon {
    color: $color-white; }

  &:focus-within {
    outline: 2px solid $color-primary;
    outline-offset: 2px; }

  input {
    position: absolute;
    opacity: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    cursor: pointer; }

  input.disabled ~ & {
    &__icon {
      opacity: .5;
      cursor: not-allowed; } }

  &__icon {
    font-size: 8px;
    position: relative;
    margin-top: 1px; } }

@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-form";

.Competencies {
  max-width: 120rem;

  @include screen-xs {
    max-width: unset; }

  h2 {
    @include subtitle-black;
    margin: 0; }

  p {
    @include text-primary;
    margin-bottom: 3rem;

    @include screen-xs {
      margin-bottom: 1rem;
      font-size: 75%; } }

  &__set {
    h3 {
      @include subtitle-blue-caps;
      margin: 0;

      span {
        margin-left: 2rem;
        color: $color-pink; }

      @include screen-xs {
        font-size: 75%; } } }

  &__set + &__set {
    margin-top: 3rem; }

  &__competency {
    border-bottom: 1px solid $color-border;
    padding: 2rem 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    &:last-child {
      border-bottom: 0; }

    h4 {
      font-weight: $font-w-semi-bold; }

    p {
      font-weight: $font-w-light;
      margin: 0; }

    &Name {
      flex: 1 1;

      @include screen-xs {
        flex: 1 1 100%;
        font-size: 75%; } } }

  &__rating {
    @include screen-xs {
      margin-top: 1rem; }

    &:focus {
      outline: none; }

    &:focus &-item.fal.fa-star {
      color: $color-primary;
      font-weight: 500; }

    &-item {
      cursor: pointer;
      color: $color-primary;
      transition: color .3s;

      &.readonly {
        cursor: auto; }

      &.normal {
        @include font($font-s-1, $line-height-1); }

      &.xs {
        @include font($font-s-sm, $line-height-sm); }

      &.fal.fa-star {
        color: $color-grey-heather; } } }

  &__warning,
  &__error {
    @include text-primary;
    padding: 1rem;
    margin-bottom: 3rem; }

  &__warning {
    border: 1px solid $color-warning;
    color: $color-warning;
    background: rgba($color-warning, 0.05); }

  &__error {
    border: 1px solid $color-red;
    color: darken($color-red, 10%);
    background: rgba($color-red, 0.05); }

  &__screenReaderMessage {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0; } }

@import "~theme/colors";

.Loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  background: $color-grey-light;
  color: $color-header-blue;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;

  &.static {
    position: static; }

  &.bg {
    background: $color-grey-light; }

  &.white {
    background: $color-white; }

  &.visible {
    opacity: 1;
    visibility: visible; }

  &.center {
    align-items: center; }

  &.top {
    align-items: flex-start;
    padding-top: 20rem; } }

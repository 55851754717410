@import "~theme/colors";
@import "~theme/common-form";

@mixin screen {
  @media only screen {
    @content; } }

.LanguageDropdown {
  @include select;

  &.underlined & {
    &__control {
      border-width: 0 0 1px 0 !important;

      &--is-focused {
        border-color: $color-pink !important; } }

    &__value-container {
      padding-left: 0; } } }

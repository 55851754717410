// large screen / wide desktop
$screen-lg-min: 1200px;

// medium screen / desktop
$screen-md-max: $screen-lg-min - 1;
$screen-md-min: 992px;

// small screen / tablet
$screen-sm-max: $screen-md-min - 1;
$screen-sm-min: 768px;

// extra small screen / phone
$screen-xs-max: $screen-sm-min - 1;


@mixin screen-range($min, $max) {
  @media only screen and (min-width: $min) and (max-width: $max) {
    @content; } }

@mixin screen-min($min) {
  @media only screen and (min-width: $min) {
    @content; } }

@mixin screen-max($max) {
  @media only screen and (max-width: $max) {
    @content; } }


@mixin screen-xs {
  @include screen-max($screen-xs-max) {
    @content; } }

@mixin screen-sm {
  @include screen-range($screen-sm-min, $screen-sm-max) {
    @content; } }

// small and larger
@mixin screen-sm-plus {
  @include screen-min($screen-sm-min) {
    @content; } }

// small and smaller
@mixin screen-sm-minus {
  @include screen-max($screen-sm-max) {
    @content; } }

@mixin screen-md {
  @include screen-range($screen-md-min, $screen-md-max) {
    @content; } }

// medium and larger
@mixin screen-md-plus {
  @include screen-min($screen-md-min) {
    @content; } }

// medium and smaller
@mixin screen-md-minus {
  @include screen-max($screen-md-max) {
    @content; } }

@mixin screen-lg {
  @include screen-min($screen-lg-min) {
    @content; } }

@mixin screen-sm-md {
  @include screen-range($screen-sm-min, $screen-md-max) {
    @content; } }

@mixin responsive-visibility {
  display: block !important;

  @at-root {
    table#{&} {
      display: table; }
    tr#{&} {
      display: table-row !important; }
    th#{&},
    td#{&} {
      display: table-cell !important; } } }

@mixin responsive-invisibility {
  display: none !important; }

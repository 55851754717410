@import "./responsive";


.visible-xs,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  @include responsive-invisibility; }


.visible-xs {
  @include screen-xs {
    @include responsive-visibility; } }

.visible-xs-block {
  @include screen-xs {
    display: block !important; } }

.visible-xs-inline {
  @include screen-xs {
    display: inline !important; } }

.visible-xs-inline-block {
  @include screen-xs {
    display: inline-block !important; } }

.visible-xs-flex {
  @include screen-xs {
    display: flex !important; } }

.visible-sm {
  @include screen-sm {
    @include responsive-visibility; } }

.visible-sm-plus {
  @include screen-sm-plus {
    @include responsive-visibility; } }

.visible-sm-minus {
  @include screen-sm-minus {
    @include responsive-visibility; } }

.visible-sm-block {
  @include screen-sm {
    display: block !important; } }

.visible-sm-inline {
  @include screen-sm {
    display: inline !important; } }

.visible-sm-inline-block {
  @include screen-sm {
    display: inline-block !important; } }

.visible-md {
  @include screen-md {
    @include responsive-visibility; } }

.visible-md-plus {
  @include screen-md-plus {
    @include responsive-visibility; } }

.visible-md-minus {
  @include screen-md-minus {
    @include responsive-visibility; } }

.visible-md-block {
  @include screen-md {
    display: block !important; } }

.visible-md-inline {
  @include screen-md {
    display: inline !important; } }

.visible-md-inline-block {
  @include screen-md {
    display: inline-block !important; } }

.visible-lg {
  @include screen-lg {
    @include responsive-visibility; } }

.visible-lg-block {
  @include screen-lg {
    display: block !important; } }

.visible-lg-inline {
  @include screen-lg {
    display: inline !important; } }

.visible-lg-inline-block {
  @include screen-lg {
    display: inline-block !important; } }


.hidden-xs {
  @include screen-xs {
    @include responsive-invisibility; } }

.hidden-sm {
  @include screen-sm {
    @include responsive-invisibility; } }

.hidden-sm-plus {
  @include screen-sm-plus {
    @include responsive-invisibility; } }

.hidden-sm-minus {
  @include screen-sm-minus {
    @include responsive-invisibility; } }

.hidden-md {
  @include screen-md {
    @include responsive-invisibility; } }

.hidden-md-plus {
  @include screen-md-plus {
    @include responsive-invisibility; } }

.hidden-md-minus {
  @include screen-md-minus {
    @include responsive-invisibility; } }

.hidden-lg {
  @include screen-lg {
    @include responsive-invisibility; } }

@import "~theme/btn/btn";
@import "~theme/clearfix";
@import "~theme/responsive/responsive";
@import "~theme/scaffolding/scaffolding";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/type";
@import "~theme/coolicons/coolicons";


.User {
  display: flex;
  align-items: center;
  gap: 4px;

  .Avatar {
    cursor: pointer;

    &.active {
      box-shadow: 0 0 0 2px #8491D9; } }

  &__avatarNav {
    outline: none;
    border: none;
    background: none;
    padding: 0;
    &:focus:not(:focus-visible) {
      .Avatar {
        box-shadow: 0 0 0 2px #8491D9; } }
    &:hover,
    &:focus,
    &.focus-visible:focus:not(:focus-visible) {
      .Avatar {
        box-shadow: 0 0 0 2px #8491D9; } } }

  .HeaderDropdown {
    margin-left: 12px;

    .NavItem__link:hover {
      .ci-home_heart {
        @extend .ci-home_heart-1 !optional; }
      .material-icons-outlined {
        font-family: 'Material Icons'; }
      .fa-user-circle {
        font-weight: 900; }
      .ci-settings {
        @extend .ci-settings_filled !optional; } } }

  h4 {
    @include font(11px, 14px);
    font-weight: $font-w-semi-bold;
    color: #37415199;
    text-transform: uppercase;
    padding: 15px 21px;
    padding-bottom: 5px;

    &:not(:first-child) {
      border-top: 1px solid $color-border; } }

  .HeaderDropdown__menu {
    top: calc(100% + 5px); }

  &__icon {
    color: $color-grey-90;
    background: none;
    border: none;
    outline: none;
    font-size: 20px;
    display: block;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;

    &.opened,
    &:hover {
      background: $color-primary-50;
      color: $color-primary; }
    &:focus:not(:focus-visible) {
      background: none;
      color: $color-grey-90; }
    &:focus,
    &.focus-visible:focus:not(:focus-visible) {
      background: $color-primary-50;
      color: $color-primary; }

    &Tooltip {
      @include font(12px, 18px);
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 10px);
      padding: 5px 10px;
      letter-spacing: .02em;
      background: $color-grey-90;
      border-radius: 4px;
      color: $color-white;
      font-weight: $font-w-semi-bold;
      pointer-events: none;
      white-space: nowrap;
      z-index: 10000;
      opacity: 0;
      visibility: hidden;
      transition: all .2s;

      * {
        color: inherit; }

      &.left {
        left: auto;
        right: 0;
        transform: translate(0, 10px); }

      span {
        color: inherit;
        display: block;
        opacity: .8;
        font-weight: $font-w-medium; } }

    &:hover &Tooltip {
      opacity: 1;
      visibility: visible; } }

  &__dropdown {
    text-align: center;
    padding: 21px 10px;
    padding-bottom: 5px;
    min-width: 45rem;

    h3,
    h5 {
      @include font(12px, 18px);
      font-weight: $font-w-regular; }

    h3 {
      color: $color-grey-90; }

    h5 {
      color: $color-grey-60; } }

  &__divider {
    display: block;
    height: 1px;
    background: $color-border; }

  &__avatar {
    position: relative;
    display: inline-block;
    margin-bottom: 1rem;
    outline: none;

    &Camera {
      background: $color-grey-60;
      color: rgba($color-white, .74);
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      transition: all .2s;

      span {
        @include font(26px, 24px); } }

    &:focus:not(:focus-visible) &Camera {
      visibility: hidden;
      opacity: 0; }

    &:hover &Camera,
    &:focus &Camera,
    &.focus-visible:focus:not(:focus-visible) &Camera {
      visibility: visible;
      opacity: 1; } } }

/*
 * Font size, line height and the scale
 *
 * Modular scale is used to calculate the steps of font size and line height range.
 *
 * Base font size is 20px.
 * Modular scale factor is ~1.272 (sqrt of 1.618 aka golden ratio).
 * Steps are rounded to the whole pixels.
 *
 * Base line height is 30px.
 * Smaller modular scale factor of ~1.23 is used,
 * so, the line height grows slower than the font size.
 * Steps are rounded to the nearest multiple of 6px.
 *
 * So, here're the calculated font sizes and line heights:
 * 12  / 18  // xs
 * 16  / 24  // sm
 * 20  / 30  // 0 *base*
 * 25  / 36  // 1
 * 32  / 48  // 2
 * 41  / 54  // 3
 * 52  / 66  // 4
 * 67  / 84  // 5
 * 85  / 102 // 6
 * 108 / 128 // 7
 * 137 / 152 // 8
 * 174 / 192 // 9
 */

$size-unit: 6px;
//$size-unit-lg: 7px
//$size-unit-xl: 8px

@function rem-size($px-size) {
  @return ($px-size / $size-unit) * 1rem; }

$font-s-xs: rem-size(12px);
$font-s-xs-sm: rem-size(14px);
$font-s-sm: rem-size(16px);
$font-s-0: rem-size(20px);
$font-s-1: rem-size(25px);
$font-s-2: rem-size(32px);
$font-s-3: rem-size(41px);
$font-s-4: rem-size(52px);
$font-s-5: rem-size(67px);
$font-s-6: rem-size(85px);
$font-s-7: rem-size(108px);
$font-s-8: rem-size(137px);
$font-s-9: rem-size(174px);

$font-s-primary: 3rem;
$font-s-secondary: 2.4rem;
$font-s-task-title: 2.3rem;
$font-s-task-details: 2.6rem;
$font-s-archetype-title: 5.66rem;
$font-s-leadership-style: 5.6rem;
$font-s-rig-reflections: 4.3rem;
$font-s-rating: 3.6rem;

$line-height-xs: rem-size(18px);
$line-height-sm: rem-size(24px);
$line-height-0: rem-size(30px);
$line-height-1: rem-size(36px);
$line-height-2: rem-size(48px);
$line-height-3: rem-size(54px);
$line-height-4: rem-size(66px);
$line-height-5: rem-size(84px);
$line-height-6: rem-size(102px);
$line-height-7: rem-size(128px);
$line-height-8: rem-size(152px);
$line-height-9: rem-size(192px);

$line-height-primary: 4rem;
$line-height-secondary: 3.4rem;
$line-height-task-details: 4rem;
$line-height-archetype-title: 7rem;
$line-height-leadership-style: 7.5rem;
$line-height-rig-reflections: 5.3rem;

$Header__h: rem-size(80px);

$Footer__h: rem-size(90px);
$Footer__hMobile: rem-size(300px);
$Footer__logoW: rem-size(155px);
$Footer__logoH: rem-size(48px);
$Footer__bCorpW: rem-size(45px);

$FooterCompressed__h: rem-size(50px);
$FooterCompressed__hMobile: rem-size(200px);
$FooterCompressed__logoW: rem-size(155px);
$FooterCompressed__logoH: rem-size(30px);

$header-h: rem-size(60px);
$header-logo-w: rem-size(131px);
$header-logo-w-mobile: rem-size(100px);
$header-item-h: $line-height-1;

@import "~theme/fonts";
@import "~theme/colors";
@import "~theme/scale";
@import "~theme/scaffolding/scaffolding";
@import "~theme/type";
@import "~theme/responsive/responsive";
@import "~theme/common-table";
@import "~theme/common-form";

.InviteColleague {
  max-width: 80%;
  .Form__field {
    margin: 0;
    flex: 1 1;
    label {
      @include p-2;
      line-height: $line-height-sm;
      font-weight: $font-w-semi-bold;
      letter-spacing: 0.2px;
      color: $color-primary;
      display: block; } }

  input {
    @include input-new; }

  .Form__select {
    @include select-new; }

  &__error {
    @include p-2;
    padding: rem-size(8px) rem-size(16px);
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-weight: $font-w-regular;
    background: rgba($color-error-new, .07);
    color: $color-error;
    margin: 8px 0;

    i {
      color: $color-error-new;
      margin-right: 1rem; } }

  &__invitee {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 16px 0;

    .btn-secondary {
      border-radius: 50%;
      padding: 0;
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
      margin-bottom: 10px;
      align-self: flex-end;
      i {
        font-size: .8em; } }

    .Form__field {
      input {
        border-radius: 4px; }

      + .Form__field {
        margin-top: 0; } }

    .Form__select {
      &.react-select__control {
        border-radius: 4px !important; }
      &.react-select__menu {
        border-radius: 4px !important; } } }

  &__btns {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: start;
    gap: 8px;
    margin-top: 24px;

    .btn-secondary.blue {
      @include font(12px, 14px);
      padding: 6px 12px; } }

  &__finish {
    margin-top: 16px;
    button {
      margin: auto; } } }



@import "~theme/scale";
@import "~theme/type";
@import "~theme/colors";
@import "~theme/scaffolding/scaffolding";

.Signin {
  form {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    max-width: 70rem;
    width: 100%; }

  h1 {
    @include font($font-s-2, $line-height-2);
    font-family: $font-secondary-new;
    text-align: center;
    padding-top: 10rem; }

  .AuthForm__field {
    margin-bottom: 5rem; }

  &__container {
    min-height: 90vh; }

  &__error {
    @include font($font-s-sm, $line-height-sm);
    margin: 2rem 0;
    text-align: center;
    color: $color-red;
    font-weight: $font-w-regular; }

  &__separator {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;

    &:before,
    &:after {
      content: '';
      flex: 1 1;
      height: 1px;
      background: $color-primary; }

    p {
      margin: 0;
      padding: 0 4rem; } }

  &__sso {
    margin-top: 5rem;

    a {
      width: 100%;
      background: $color-grey-med; }

    span {
      margin-right: 1rem; } }

  &__expired {
    @include p-new;
    text-align: center;
    margin-bottom: 2rem;

    p {
      font-weight: $font-w-regular; }

    i {
      font-size: 1.6em; } } }

// BRANDING

// Primary Colors
$color-purple-purpose: #5b59cf;
$color-purple-purpose-dark: #44b;
$color-grey-albatross: #535960;

// white / grey / black
$color-white: #fff;
$color-grey-light: #fbfbfb;
$color-bg: #fbfbfb;
$color-bg-darker: #f7f7f8;
$color-grey-soft: #f7f7f8;
$color-grey-imperceptible: #f5f5f5;
$color-grey-light-med: #e1e1e1;
$color-grey-blue: #DEE3EA;
$color-border: $color-grey-light-med;
$color-bg-blueish: #F3F8FB;
$color-grey-med: #ddd;
$color-grey-heather-dark: #b7b7b7;
$color-grey-heather: #a6aaa9;
$color-grey-dark-med: #7b7b7b;
$color-grey-dark: #333;
$color-grey-manatee: #979799;
$color-grey-pumice: #c9cac9;
$color-black: #000;
$color-bg-blue: #F3F8FB;
$color-bg-blue-purple: #F7F8FF;
$color-text-brown: #494141;

$color-greyish: rgba($color-grey-heather, .7);
$color-text-grey: #374151;
$color-border-new: rgba(#374151, .27);
$color-grey-90: mix(#374151, $color-white, 87%);
$color-grey-80: rgba(55, 65, 81, 0.8);
$color-grey-60: rgba(55, 65, 81, 0.6);
$color-grey-30: rgba(55, 65, 81, 0.27);
$color-grey-15: rgba(55, 65, 81, 0.15);
$color-grey-100: #F3F4F6;
$color-grey-50: #F9FAFB;
$color-primary-50: #F6F7FC;
$color-focus: #84FFFF;
$color-primary-700: #2F3B78;
$color-primary-600: #4758B4;
$color-primary-500: #4F62C8;
$color-primary-400: #8491D9;
$color-secondary-600: #C333A0;
$color-secondary-500: #D939B2;
$color-secondary-400: #e45ac2;
$color-secondary-300: #ec4899;
$color-secondary-50: #FDF5FB;
$color-warning-new: #E1CD00;
$color-error-new: #EB5757;
$color-success-new: #009946;

//Colors
$color-purple-passion: #9e56a2;
$color-purple-passion-light: #c762a4;
$color-purple-passion-dark: #7b5aa4;

$color-purple-playful: #5b59cf;
$color-purple-playful-dark: #5742cb;
$color-purple-playful-darker: #4f62c8;
$color-checkbox-circle: #3e4f8f;

$color-magenta: #945198;
$color-magenta-pale: #e1cde1;

$color-violet-moderate: #6b36b6;
$color-violet-grayish-violet: #f0e9f7;

$color-blue-playful: #00adef;
$color-blue-playful-light: #58cbf4;
$color-blue-pale: #a5dbe4;

$color-blue-purple: #3348b3;
$color-blue-purple-light: #4f62c8;

$color-blue-moderate: #4558c1;
$color-blue-grayish-light: #e9ebf8;

$color-green-bright: #79c144;
$color-green-playful: #00bba3;
$color-green-pale: #d0e8d8;
$color-green-dark: #42a265;
$color-green: #009A33;
$color-green-onboarding: #00853c;

$color-yellow-calm: #e1cd00;
$color-yellow-calm-dark: #ef9f19;
$color-yellow-calm-light: #fecb38;
$color-yellow-pale: #f7f1bf;
$color-yellow-note: #fbac4a;

$color-pink-bright: #d939b2;
$color-pink-dark: #964f9b;
$color-pink-pale: #f0cddd;
$color-pink-redish: #e22d91;
$color-pink: #c4279e;

$color-red-danger: #ebccd1;
$color-red: #d13b00;
$color-error: #A50104;
$color-warning: #E56D39;
$color-info: #00adef;
$color-success: #009946;
// $color-error: #B72C3E

$color-cyan: #43ccd4;
$color-cyan-light: #a6dce5;

// Social Media Colors
$color-facebook: #3b5998;
$color-twitter: #00aced;
$color-github: #4183c4;
$color-google: #bf2b18;
$color-linkedin: #0077b5;
$color-okta: #127EBF;

$color-blue-ultra-light: #78b8e5;
$color-blue-light: #00aeef;
$color-blue-med: $color-blue-playful;
$color-blue-dark: #1c75bc;

$color-driver-who: #5263ae;
$color-driver-why: #26902c;
$color-driver-how: #dac000;
$color-driver-inactive: #e6e7e8;
$color-pattern-empty: #394349;
$color-pattern-border: #ced0d0;

$color-report-purple: #774b9e;
$color-report-pink: #cc26ff;

$color-rig-relationship: #9e56a2;
$color-rig-impact: #47c2d8;
$color-rig-growth: #00bba3;

$color-insight-leadership: #99dcea;

$color-driver-who-pale: rgba($color-driver-who, .15);
$color-driver-how-pale: rgba($color-driver-how, .15);
$color-driver-why-pale: rgba($color-driver-why, .15);

$color-header-blue: $color-driver-who;
$color-primary: $color-driver-who;
$color-secondary: $color-pink-bright;

$color-footer-grey: #28282e;
$color-footer-grey-dark: #232328;
$color-footer-grey-text: #d9dbde;

$color-notification-info: #f1f8fe;
$color-notification-info-border: #5894fa;
$color-notification-error: #fdf1f1;
$color-notification-error-border: #f35353;

$color-conversation-hover: #d8ddf9;
